import "./styles.css";

import { Button, Grid, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";

import { ConfigurationContext } from "../../infrastructure/ConfigurationContext";
import ExitToApp from "@material-ui/icons/ExitToApp";
import { UserContext } from "../../infrastructure/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth0 } from "@auth0/auth0-react";

// import Button from "react-bootstrap/Button";
const useStyles = makeStyles({
  logOut: {
    background: "red",
    borderRadius: 3,
    border: 0,
    color: "white",
    "&:hover": {
      background: "red",
    },
  },
});
const Auth0Login = () => {
  const activeConfig = useContext(ConfigurationContext);
  const classes = useStyles();
  const { loginWithRedirect, isAuthenticated, logout } = useAuth0();
  const userData = useContext(UserContext);
  const [microSite, setMicroSite] = useState(
    activeConfig.configData.micrositeUrl
  );

  if (isAuthenticated) {
    return (
      <Grid container direction="row" justify="flex-end" alignItems="center">
        {userData && (
          <Grid item>
            <Typography
              noWrap
              style={{
                textAlign: "center",
              }}
            >
              {[userData.firstName, userData.lastName]
                .filter((a) => a)
                .join(" ")}
              &nbsp;
              {/* {" |"} */}
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Button
            style={{ marginLeft: '1rem' }}
            className={classes.logOut}
            variant="contained"
            onClick={() => logout({ returnTo: microSite })}
            endIcon={<ExitToApp />}
          >
            Log Out
          </Button>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <div>
        <Button
          color="inherit"
          id="login-button"
          onClick={() => loginWithRedirect()}
        >
          Log In
        </Button>
      </div>
    );
  }
};

export default Auth0Login;
