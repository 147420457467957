import React, { useEffect, useState } from "react";

import { GetWithAuth } from "./HttpClient";
import PubNubHelper from "./PubNubHelper";
import Spinner from "../components/Spinner";
import { UserContext } from "./UserContext";
import { useAuth0 } from "@auth0/auth0-react";

const UserProvider = ({ children }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    if (isAuthenticated) {
      const sessionId = PubNubHelper.getSessionIdFromURL();
      const url = sessionId ? `/UserAccount/WhoAmI?sessionId=${sessionId}` : "/UserAccount/WhoAmI";
      getAccessTokenSilently()
        .then((accessToken) => {
          GetWithAuth(url, accessToken)
            .then((response) => {
              if (isMounted) {
                setUserData(response.data);
                setIsLoading(false);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return () => (isMounted = false);
  }, [isAuthenticated]);
  if (isLoading && isAuthenticated) {
    return <Spinner />;
  } else {
    return (
      <UserContext.Provider value={userData}>{children}</UserContext.Provider>
    );
  }
};

export default UserProvider;
