import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const styles = {
  paperContainer: {
    backgroundColor: "lightgrey",
  },
};

const PageNotFound = () => {
  return (
    <Grid container alignItems="center" justify="center">
      <Grid style={styles.paperContainer}>
        <div className="inner-container">
          <Card>
            <CardContent>
              <Typography variant="h4" align="center" justifyContent="center">
                              <p>404 - Page Not Found</p>
                              <p>Please verify that you have the correct url</p>
              </Typography>
            </CardContent>
          </Card>
        </div>
      </Grid>
    </Grid>
  );
};

export default PageNotFound;
