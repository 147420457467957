import React, { useContext, useEffect, useRef, useState } from "react";

import { PubNubChannels } from "../../infrastructure/PubNubChannelEnum";
import PubNubHelper from "../../infrastructure/PubNubHelper";
import { UserContext } from "../../infrastructure/UserContext";
import VoteBroadcast from "./index";
import { usePubNub } from "pubnub-react";

const VoteBroadcastListener = () => {
  const pubNub = usePubNub();
  const userData = useContext(UserContext);
  const [ui, render] = useState({});
  const yesTally = useRef(0);
  const noTally = useRef(0);
  useEffect(() => {
    if (!userData) return;
    const pnListner = {
      message: (messageEvent) => {
        if (
          messageEvent.channel ===
          `${PubNubChannels.VoteAdmin}-${userData.eventId}${PubNubHelper.appendSessionIdFromURL()}`
        ) {
          if (messageEvent.message.action === "InitiateVote") {
            yesTally.current = 0;
            noTally.current = 0;
          } else if (messageEvent.message.action === "EndVoteResults") {
            yesTally.current = messageEvent.message.voteResults.yesTally;
            noTally.current = messageEvent.message.voteResults.noTally;
          }
        }
        render({});
        render({});
      },
    };
    // set last voting result on page load
    PubNubHelper.GetCurrentVote(pubNub, userData).then((response) => {
      if (response.action === "EndVoteResults") {
        yesTally.current = response.voteResults.yesTally;
        noTally.current = response.voteResults.noTally;
      }
      render({});
    });
    PubNubHelper.VoteBroadcastListenerSubscribe(pubNub, userData, pnListner);

    return () => {
      PubNubHelper.VoteBroadcastListenerUnsubscribe(
        pubNub,
        userData,
        pnListner
      );
    };
  }, [userData]);

  return (
    <VoteBroadcast yesTally={yesTally.current} noTally={noTally.current} />
  );
};

export default VoteBroadcastListener;
