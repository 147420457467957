import { useEffect, useState } from "react";
import { Device } from "twilio-client";

const GetDevice = () => {
  const [device, setDevice] = useState({});

  useEffect(() => {
    if (!device.device) {
      setDevice({ device: new Device() });
    }
  });
  return device.device;
};

export default GetDevice;
