import PubNubMessageBase from "./PubNubMessageBase";

class CallMessage extends PubNubMessageBase {
  constructor(user, messageType, slot, phoneNumber, action, podiumNumber, timeToken) {
    super(user, messageType, podiumNumber, timeToken);
    this.name = user.name;
    this.locale = user.locale;
    this.chapter = user.chapter;
    this.state = user.state;
    this.slot = slot;
    this.phoneNumber = phoneNumber;
    this.action = action;
    this.podiumNumber = podiumNumber;
  }
}

export default CallMessage;
