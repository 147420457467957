export default function getConstants() {
  const origin = window.location.origin;

  switch (origin) {
    case "https://localhost:3000":
    case "https://localhost:3001":
    case "http://localhost:3000":
    case "http://localhost:3001":
      return {
        WebUrl: origin + "/",
        ApiUrl: "https://localhost:44354",
        Auth0Url: "onlineeventplus.us.auth0.com",
        PointOfOrder: false,
        StreamUrl:
          "https://onlineeventplusdev-stream.freeman.com/live/smil:multiAudioABR.smil/playback.m3u8",
        AFSCMEClientId: "vZoTnYilfnYGJhrxSc0KLPdlAX9UguHi",
        AFCSMEConnection: "Username-Password-Authentication",
        AFSCMELogo:
          "https://upload.wikimedia.org/wikipedia/en/f/f7/AFSCME_logo.png",
        Stage: "Local",
        Routes: ["afscme", "demo"],
        AppInsightsInstrumentationKey: "ae1e3803-2261-4bc0-886d-54c7b9a1bf44",
        PointOfOrderChatlio: "e0b75e74-316a-49c9-41c6-ebc2a908548e",
        EventSupportChatlio: "5f92aafd-1068-40d0-49b8-db8ffe4a8c10",
        //repeating values
        TextStageOffset: 10,
        TextStage: "TextStage",
        PhoneStage: "PhoneStage",

      };
    case "https://dev-fup-web.azurewebsites.net":
    case "https://staticsitetestcontainer.z5.web.core.windows.net":
    case "https://reactstatictest.azureedge.net":
      return {
        WebUrl: origin + "/",
        ApiUrl: "https://dev-fup-api.azurewebsites.net",
        Auth0Url: "onlineeventplus.us.auth0.com",
        PointOfOrder: false,
        StreamUrl:
          "https://onlineeventplusdev-stream.freeman.com/live/smil:multiAudioABR.smil/playback.m3u8",
        AFSCMEClientId: "vZoTnYilfnYGJhrxSc0KLPdlAX9UguHi",
        AFCSMEConnection: "Username-Password-Authentication",
        AFSCMELogo:
          "https://upload.wikimedia.org/wikipedia/en/f/f7/AFSCME_logo.png",
        Stage: "AllataDev",
        Routes: ["afscme", "demo"],
        AppInsightsInstrumentationKey: "ae1e3803-2261-4bc0-886d-54c7b9a1bf44",
        PointOfOrderChatlio: "e0b75e74-316a-49c9-41c6-ebc2a908548e",
        EventSupportChatlio: "5f92aafd-1068-40d0-49b8-db8ffe4a8c10",
        //repeating values
        TextStageOffset: 10,
        TextStage: "TextStage",
        PhoneStage: "PhoneStage",
        
      };
    case "https://onlineeventplusdev-web.freeman.com":
    case "https://oeplus-web-dev.azurewebsites.net":
    case "https://unity-dev.freeman.com":
      return {
        WebUrl: origin + "/",
        ApiUrl: "https://unity-apidev.freeman.com",
        Auth0Url: "onlineeventplus.us.auth0.com",
        PointOfOrder: false,
        StreamUrl:
          "https://onlineeventplusdev-stream.freeman.com/live/smil:multiAudioABR.smil/playback.m3u8",
        AFSCMEClientId: "vZoTnYilfnYGJhrxSc0KLPdlAX9UguHi",
        AFCSMEConnection: "Username-Password-Authentication",
        AFSCMELogo:
          "https://upload.wikimedia.org/wikipedia/en/f/f7/AFSCME_logo.png",
        Stage: "FreemanDev",
        Routes: ["afscme", "demo"],
        AppInsightsInstrumentationKey: "dade4956-c53d-4569-b06b-21bec0695942",
        PointOfOrderChatlio: "e0b75e74-316a-49c9-41c6-ebc2a908548e",
        EventSupportChatlio: "5f92aafd-1068-40d0-49b8-db8ffe4a8c10",
        //repeating values
        TextStageOffset: 10,
        TextStage: "TextStage",
        PhoneStage: "PhoneStage",
        
      };
    case "https://onlineeventplus.com":
    case "https://www.onlineeventplus.com":
    case "https://oeplus-web-scus-prod.azurewebsites.net.azurewebsites.net":
    case "https://unity.freeman.com":
      return {
        WebUrl: origin + "/",
        ApiUrl: "https://api.onlineeventplus.com",
        Auth0Url: "onlineeventplus.us.auth0.com",
        PointOfOrder: true,
        StreamUrl:
          "https://cdn3.wowza.com/5/a09VajhFVzBDcHNK/live/smil:afscme_av-cptions-abr_prod_multiAudio-3ch.smil/playback.m3u8",
        AFSCMEClientId: "A1jEzthdTNf2EtagnTFSOyehyt2NTqHD",
        AFCSMEConnection: "AFSCME-USERS-PROD",
        AFSCMELogo:
          "https://upload.wikimedia.org/wikipedia/en/f/f7/AFSCME_logo.png",
        AFSCMERehearsalClientId: "MPHz0XqtaoosIHe33B8zecOco0XHzLRz",
        AFCSMERehearsalConnection: "AFSCME-USERS-REHEARSAL",
        AFSCMERehearsalLogo:
          "https://upload.wikimedia.org/wikipedia/en/f/f7/AFSCME_logo.png",
        Stage: "FreemanProd",
        Routes: ["afscme", "afscme-rehearsal", "demo"],
        AppInsightsInstrumentationKey: "14544097-a6ac-475c-a666-e25375ffb0aa",
        PointOfOrderChatlio: "095e5b34-ca70-4301-4678-1855fd3cec57",
        EventSupportChatlio: "38b1c8e7-ffc1-414c-7de2-93447a1b9484",
        //repeating values
        TextStageOffset: 10,
        TextStage: "TextStage",
        PhoneStage: "PhoneStage",
        
      };
    // no default
  }
}
