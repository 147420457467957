import "./styles.css";

import React, { useContext, useEffect, useRef, useState } from "react";

import EndVote from "./EndVote";
import { PubNubChannels } from "../../../../infrastructure/PubNubChannelEnum";
import PubNubHelper from "../../../../infrastructure/PubNubHelper";
import { UserContext } from "../../../../infrastructure/UserContext";
import { usePubNub } from "pubnub-react";

const VoteListener = ({ voteId, voteDescription, enabledVoteWeight, uniquePollId, downloadReport }) => {
  const pubNub = usePubNub();
  const messages = useRef([]);
  const [Messages, setMessages] = useState([]);
  const userData = useContext(UserContext);
  const [disableReloadVotesButton, setDisableReloadVotesButton] = useState(false);

  const loadVotes = () => {
    setDisableReloadVotesButton(true);
    return PubNubHelper.GetAllVotes(pubNub, userData, uniquePollId).then(
      (response) => {
        if (!response) return;
        response.forEach((vote) => {
          let newArr = messages.current.filter(
            (x) => x.userId !== vote.userId
          );
          newArr = newArr.concat(vote);
          messages.current = newArr;
          setMessages(newArr);
        });
        setDisableReloadVotesButton(false);
      }
    );
    
  }

  useEffect(() => {
    if (!userData) return;
    messages.current = [];
    const pnListener = {
      message: (messageEvent) => {
        if (
          messageEvent.channel ===
          `${PubNubChannels.VoteBallot}-${userData.eventId}${PubNubHelper.appendSessionIdFromURL()}`
        ) {
          let newArr = messages.current.filter(
            (x) => x.userId !== messageEvent.message.userId
          );
          newArr = newArr.concat(messageEvent.message);
          messages.current = newArr;
          setMessages(newArr);
        }
      },
    };

    PubNubHelper.ModeratorVoteListenerSubscribe(pubNub, userData, pnListener);

    if (uniquePollId) {
      loadVotes();
    }

    return () => {
      PubNubHelper.ModeratorVoteListenerUnsubscribe(
        pubNub,
        userData,
        pnListener
      );
    };
  }, [uniquePollId]);

  return (
    <EndVote
      voteId={voteId}
      voteDescription={voteDescription}
      enabledVoteWeight={enabledVoteWeight}
      uniquePollId={uniquePollId}
      Messages={messages.current}
      downloadReport={downloadReport}
      reloadVotes={loadVotes}
      disableReloadVotesButton={disableReloadVotesButton}
    ></EndVote>
  );
};

export default VoteListener;
