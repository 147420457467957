import React, { useContext, useEffect, useRef, useState } from "react";

import { MessageActions } from "../../infrastructure/MessageActions";
import ModeratorQueue from "./index";
import PubNubHelper from "../../infrastructure/PubNubHelper";
import { UserContext } from "../../infrastructure/UserContext";
import { usePubNub } from "pubnub-react";
import getConstants from "../../infrastructure/Constants";
// import myData from './loadtestdata.json';

function ModeratorListener() {
    // Component scaffolding
    const pubNub = usePubNub();
    const userData = useContext(UserContext);
    const [, render] = useState({});
    const [pubNubDown, setpubNubDown] = useState(false);
    // Data elements
    const messages = useRef([]);
    const input = useRef("");
    const resolution = useRef("");
    const resolutionObj = useRef();
    const resolutionId = useRef("");
    const timeout = useRef();
    const isLoaded = useRef(false);
    const stageStatuses = useRef({});
    const stagePodiumNumbers = useRef({});
    const previousDate = useRef(0);
    const [disablePausePubnubMessages, setDisablePausePubnubMessages] = useState(false);
    const [disableResumePubnubMessages, setDisableResumePubnubMessages] = useState(true);
    const [disableReloadPubnubMessages, setDisableReloadPubnubMessages] = useState(false);
    const colorCode = useRef([
        "#fcb900",
        "#00d084",
        "#0693e3",
        "#eb144c",
        "#f5f5f5",
    ]);
    const onCardColorChange = (color) => {
        if (colorCode.current.includes(color) == false) {
            colorCode.current = [...colorCode.current, color];
        }
    };



    const pnListener = {
        message: (messageEvent) => {

            //myData.forEach((messageEvent) => { 100 test users
            if (!messageEvent) return;
            let message = messageEvent.message;
            if (!messageEvent.message) message = messageEvent;
            if (!message) return;

            // let slot = message.slot;
            // if (isNaN(slot) && message.queue && message.queue.length > 0) {
            //   slot = (message.queue.includes(getConstants().TextStage) ? 
            //     parseInt(message.queue.substring(getConstants().TextStage.length)) + getConstants().TextStageOffset -1: 
            //     parseInt(message.queue.substring(getConstants().PhoneStage.length))) - 1;
            // } else {
            //     slot = parseInt(message.slot) - 1;
            // }
            // if (isNaN(slot))
            //   slot = null;
            // if (slot !== null) {
            //   let slotString = `TextStage${slot}`;

            //   if (stagePodiumNumbers.current && stagePodiumNumbers.current[slotString]) {
            //     if (stagePodiumNumbers.current[slotString].userId === message.userId) {
            //       message.podiumNumber = stagePodiumNumbers.current[slotString].podiumNumber;
            //       if (stagePodiumNumbers.current.activeSpeaker === slotString) {
            //         //message.callStatus = "Speaking";
            //       } else {
            //         //message.callStatus = "Ready"; // we know that this should have been ready (the only way to get a podium number in)
            //       }
            //     }
            //   }
            // }

            switch (message.action) {
                case "Join": {
                    const currentmessages = messages.current;
                    const elems = currentmessages.filter(
                        (item) => item.userId === message.userId
                    );
                    // Don't add twice
                    if (elems.length > 0) break;

                    messages.current = [...messages.current, message];
                    break;
                }
                case MessageActions.Evicted:
                case "Leave": {
                    let currentmessages = messages.current;
                    let queue = message.queue;
                    if (message.action === "Leave") {
                        queue = messages.current.find((x) => x.userId === message.userId)
                            ?.queue;
                    }
                    if (!queue) break;
                    console.log("Leave handler ", message);
                    const elems = currentmessages.filter(
                        (item) => item.userId !== message.userId
                    );
                    messages.current = elems;
                    clearSingleStageQueueStatus(queue);
                    break;
                }
                case "Clear": {
                    let pointOfOrderAndPointOfInformationMessages = messages.current.filter(
                        (x) => x.participationType === "Point of Order" || x.participationType === "Point of Information"
                    );
                    messages.current = pointOfOrderAndPointOfInformationMessages;
                    resolution.current = "";
                    resolutionId.current = "";
                    clearAllStageQueueStatuses();
                    break;
                }
                case "Create":
                    resolutionObj.current = message;
                    resolution.current = message.resolutionNumber;
                    resolutionId.current = message.id;
                    clearAllStageQueueStatuses();
                    break;
                case MessageActions.PauseAndClear:
                    messages.current = messages.current.filter(x => x.participationType === "Point of Order" || x.participationType === "Point of Information" || message.lockedUserIds.some(a => a === x.userId));
                    resolutionObj.current = message;
                    resolution.current = message.resolutionNumber;
                    resolutionId.current = message.id;
                    break;
                case "Move": {
                    let newArr = [...messages.current];
                    //find where the card originally went
                    let findIndex = newArr.findIndex((x) => x.id === message.id);
                    if (findIndex === -1) break;
                    if (newArr[findIndex].queue === stageStatuses.current.activeSpeaker) {
                        stageStatuses.current.activeSpeaker = "";
                        stagePodiumNumbers.current.activeSpeaker = "";
                    }
                    //remove the card from its previous spot
                    if (findIndex !== message.newIndex) {
                        newArr.splice(findIndex, 1);
                        newArr.splice(message.newIndex, 0, message);
                        messages.current = newArr;
                    } else {
                        newArr[findIndex] = message;
                        messages.current = newArr;
                    }
                    break;
                }
                case "ChangeColor": {
                    let newArr = [...messages.current];
                    let newCard = newArr.find((x) => x.userId === message.userId);
                    if (newCard) {
                        newCard.color = message.color;
                        if (colorCode.current.includes(message.color) == false) {
                            colorCode.current = [...colorCode.current, message.color];
                        }
                    }
                    messages.current = newArr;
                    break;
                }
                case "LockUserCard": {
                    let newArr = [...messages.current];
                    let newCard = newArr.find((x) => x.userId === message.userId);
                    if (newCard) {
                        newCard.isLock = message.isLock;
                    }
                    messages.current = newArr;
                    break;
                }
                case "ChangePodiumNumber": {
                    let newArr = [...messages.current];
                    let newCard = newArr.find((x) => x.userId === message.userId);
                    if (newCard) {
                        newCard.podiumNumber = message.podiumNumber;
                    }
                    messages.current = newArr;
                    break;
                }
            }
            switch (message.messageType) {
                case MessageActions.QueueReorder: {
                    console.log("re ordering messages.current");
                    if (!message.auth0IdSequence) {
                        message.auth0IdSequence = [];
                    }
                    let queueMessages = messages.current.filter(
                        (msg) => (message.auth0IdSequence && message.auth0IdSequence.includes(msg.auth0Id))
                    );
                    let otherArr = messages.current.filter(
                        (msg) => !(message.auth0IdSequence && message.auth0IdSequence.includes(msg.auth0Id))
                    );
                    if (queueMessages && queueMessages.length > 0 &&
                        message.auth0IdSequence && message.auth0IdSequence.length > 0) {
                        const newOrder = [];
                        message.auth0IdSequence.forEach(auth0Id => {
                            const itemIndex = queueMessages.findIndex(a => a.auth0Id === auth0Id);
                            if (itemIndex !== -1) {
                                newOrder.push(queueMessages[itemIndex]);
                            }
                        });
                        messages.current = [...otherArr, ...newOrder];
                    }
                    break;
                }
            }
            if (timeout.current) clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                render({});
                render({});
                timeout.current = null;
            }, 50);
            //})100 test users
        },
        status: (statusEvent) => {
            if (statusEvent.category === "PNNetworkDownCategory") {
                setpubNubDown(true);
            } else {
                setpubNubDown(false);
            }
        },
    };

    const loadResolutionData = () => {
        setDisableReloadPubnubMessages(true);
        // Get Current Resolution
        PubNubHelper.GetCurrentResolution(pubNub, userData).then((resolutionResponse) => {
            if (!resolutionResponse) {
                setDisableReloadPubnubMessages(false);
            }
            if (resolutionResponse) {
                pnListener.message(resolutionResponse);
            }
            render({});
            // Get People in Queue
            let endTimeToken = undefined;
            // default to 'no-resolution' for Point of Order requests
            let resolutionId = 'no-resolution';
            // if current resolution then user its' id
            if (resolutionResponse) {
                resolutionId = resolutionResponse.id;
            }
            // if current resolution is Clear the use closingResolutionId
            if (resolutionResponse && resolutionResponse.action === 'Clear') {
                // endTimeToken to restrict history to last resolution
                endTimeToken = resolutionResponse.timetoken;
                resolutionId = resolutionResponse.closingResolutionId;
            }
            PubNubHelper.GetPeopleInResolution(pubNub, resolutionId, userData, undefined, endTimeToken).then(
                (response) => {
                    // if the resolution no longer exists then process only "Point of Order" and ordering messages
                    if (resolutionResponse && resolutionResponse.action === 'Clear') {
                        response = response.filter(x => (x.auth0IdSequence && x.messageType === MessageActions.QueueReorder) || x.participationType === "Point of Order" || x.participationType === "Point of Information");
                    }
                    setDisableReloadPubnubMessages(false);
                    let reorderMessage = null;
                    if (response) {
                        response.sort((a, b) => {
                            return a.timeToken - b.timeToken;
                        }).forEach((x) => {
                            // if messageType QueueReorder and auth0IdSequence is exists then collect message to process later.
                            if (x.auth0IdSequence && x.messageType === MessageActions.QueueReorder) {
                                // set reorderMessage to process later at end of message processing
                                // only last reorderMessage message needs to be processed
                                reorderMessage = x;
                            }
                            // do not process QueueReorder messages
                            if (x.messageType !== MessageActions.QueueReorder) {
                                pnListener.message(x);
                                if (x.queue.indexOf('Stage') > -1) {
                                    pnListenerStage.buildPodiumNumbers(x);
                                }
                            }
                        });
                    }
                    // if reorderMessage found then process it.
                    if (reorderMessage) {
                        pnListener.message(reorderMessage);
                        if (reorderMessage.queue.indexOf('Stage') > -1) {
                            pnListenerStage.message(reorderMessage);
                        }
                    }
                    if (resolutionResponse && resolutionResponse.action === MessageActions.PauseAndClear && messages.current && messages.current.length > 0) {
                        messages.current = messages.current.filter(x => x.participationType === "Point of Order" || x.participationType === "Point of Information" || resolutionResponse.lockedUserIds.some(a => a === x.userId));
                    }
                    if (messages.current && messages.current.length > 0) {
                        // get latest status on page load for staging queue users
                        messages.current.filter(a => a.auth0Id && a.queue && a.queue.indexOf('Stage') > -1).forEach(stagePerson => {
                            PubNubHelper.GetUserCallStatus(pubNub, stagePerson.auth0Id, stagePerson.eventId).then(msg => {
                                pnListenerStage.message(msg);
                            });
                        });
                    }
                    render({});
                    render({});
                    isLoaded.current = true;
                }
            );
        });

    }

    // Stage Card Listener
    const pnListenerStage = {
        buildPodiumNumbers: (messageEvent) => {
            let msg = messageEvent.message ? messageEvent.message : messageEvent;
            stagePodiumNumbers.current[msg.slot] = {
                podiumNumber: msg.podiumNumber,
                userId: msg.userId,
            };
        },
        message: (messageEvent) => {
            let msg = messageEvent.message ? messageEvent.message : messageEvent;
            stagePodiumNumbers.current[msg.slot] = {
                podiumNumber: msg.podiumNumber,
                userId: msg.userId,
            };
            if (msg.messageType === "UpdateCallStatus") {
                // update active speaker
                if (msg.action === "Speaking") {
                    if (msg.timeToken >= previousDate.current) {
                        stageStatuses.current.activeSpeaker = msg.slot;
                        stagePodiumNumbers.current.activeSpeaker = msg.slot;
                        previousDate.current = msg.timeToken;
                        let newArr = [...messages.current];
                        let oldSpeaker = newArr.find((x) => x.isSpeaking === true);
                        if (oldSpeaker) {
                            oldSpeaker.isSpeaking = false;
                        }
                        let newSpeaker = newArr.find((x) => x.userId === msg.userId);
                        if (newSpeaker) {
                            newSpeaker.isSpeaking = true;
                        }
                        messages.current = newArr;
                    }
                }
                // update stage status
                if (messages.current.filter(a => a.queue === msg.slot).length > 0) {
                    stageStatuses.current[msg.slot] = msg.action;
                } else {
                    stageStatuses.current[msg.slot] = "";
                }
                render({});
                render({});
            }
        },
    };

    const pausePubnubMessages = () => {
        setDisablePausePubnubMessages(true);
        setDisableResumePubnubMessages(false);
        PubNubHelper.ModeratorResolutionListenerUnsubscribeToRequestsToSpeak(pubNub, userData, pnListener);
    }

    const resumePubnubMessages = () => {
        setDisableResumePubnubMessages(true);
        setDisablePausePubnubMessages(false);
        PubNubHelper.ModeratorResolutionListenerSubscribeToRequestsToSpeak(pubNub, userData, pnListener);
    }



    useEffect(() => {
        if (!userData) return;

        loadResolutionData();

        PubNubHelper.ModeratorResolutionListenerSubscribe(
            pubNub,
            userData,
            pnListener
        );
        PubNubHelper.ModeratorCallListenerSubscribe(
            pubNub,
            userData,
            pnListenerStage
        );

        return () => {
            PubNubHelper.ModeratorResolutionListenerUnsubscribe(
                pubNub,
                userData,
                pnListener
            );
            PubNubHelper.ModeratorCallListenerUnsubscribe(
                pubNub,
                userData,
                pnListenerStage
            );
            isLoaded.current = false;
        };
    }, [userData]);

    // Reorder in the same list
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        if (result && result.length > 0) {
            // screen break bug fix
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);
            return result ? result : [];
        } else {
            return [];
        }
    };

    const clearAllStageQueueStatuses = () => {
        stageStatuses.current = {};
    };

    const clearSingleStageQueueStatus = (queue) => {
        stageStatuses.current[queue] = undefined;
        if (stageStatuses.current.activeSpeaker === queue) {
            stageStatuses.current.activeSpeaker = "";
            stagePodiumNumbers.current.activeSpeaker = "";
        }
    };

    // Filter messages
    const getList = (type) => messages.current.filter((x) => x.queue === type);

    // Clear queues on resolution end
    const clearQueues = () => {
        // Do not process if no resolution exists, it create unnecessary messages
        if (resolutionId.current) {
            var speakerOrLockCards = messages.current.find(a => a.isSpeaking || a.isLock);
            if (speakerOrLockCards) {
                var lockedUserIds = messages.current.filter(a => a.isSpeaking || a.isLock).map(a => a.userId);
                PubNubHelper.PauseAndClear(pubNub, resolutionObj.current, lockedUserIds);
                let messagesToKeep = messages.current.filter(
                    (x) => x.participationType === "Point of Order" || x.participationType === "Point of Information" || x.isSpeaking === true || x.isLock === true
                );
                messages.current = messagesToKeep;
            } else {
                PubNubHelper.EndQueue(pubNub, userData, input.current, resolutionId.current);
                let pointOfOrderAndPointOfInformationMessages = messages.current.filter(
                    (x) => x.participationType === "Point of Order" || x.participationType === "Point of Information"
                );
                messages.current = pointOfOrderAndPointOfInformationMessages;
                resolution.current = "";
                resolutionId.current = "";
                render({});
                render({});
            }

        }
    };

    // Move card to new list
    const move = (source, destination, droppableSource, droppableDestination) => {
        const queues = ["Point of Order", "Point of Information"];
        // 240871 Participant that left queue, card has been cancel by external event
        if (!source) {
            return;
        }
        if (
            (droppableSource.droppableId === "Production" &&
                !droppableDestination.droppableId.includes("Stage")) ||
            (droppableSource.droppableId.includes("Stage") &&
                droppableDestination.droppableId !== "Production")
        ) {
            if (
                (queues.includes(source.participationType) && !queues.includes(droppableDestination.droppableId)) ||
                (droppableDestination.droppableId !== source.participationType && !queues.includes(source.participationType))
            ) {
                return;
            } else {
                messages.current = messages.current.map(a=>{
                    if (a.id  == source.id) {
                        return { ...a, participationType: droppableDestination.droppableId };
                    }
                    return a;
                });
                let newArr = [...messages.current];
                //find moved card
                let movedCard = newArr.find((x) => x.id === source.id);
                //filter out original array by queue
                let arr = newArr.filter(
                    (x) => x.queue === droppableDestination.droppableId
                );
                //changed moved card queue TODO?
                movedCard.queue = droppableDestination.droppableId;
                movedCard.slot = droppableDestination.droppableId.includes(getConstants().TextStage) ?
                    parseInt(droppableDestination.droppableId.substring(getConstants().TextStage.length)) :
                    parseInt(droppableDestination.droppableId.substring(getConstants().PhoneStage.length));
                if (isNaN(movedCard.slot))
                    movedCard.slot = null;
                // add moved card to the drop spot you want it to go
                arr.splice(droppableDestination.index, 0, movedCard);
                let otherArr = messages.current.filter(
                    (message) => message.queue !== droppableDestination.droppableId
                );

                let newMessagesArr = [...arr, ...otherArr];
                //send the new Index to the other moderator views so that they know where to put the cards

                let newIndex = newMessagesArr.findIndex((x) => x.id === movedCard.id);

                movedCard.action = "Move";
                messages.current = newMessagesArr;

                PubNubHelper.MoveCard(pubNub, movedCard, newIndex);

                const queue = ["Support", "Oppose", "Point of Order", "Point of Information"];
                if (
                    queue.includes(droppableDestination.droppableId) &&
                    droppableSource.droppableId.includes("Stage")
                ) {
                    PubNubHelper.SetCallState(
                        pubNub,
                        source,
                        MessageActions.EndCall,
                        source.status,
                        droppableSource.droppableId,
                        source.phoneNumber,
                        source.podiumNumber
                    );
                }
            }
        } else {
            let newArr = [...messages.current];

            let movedCard = newArr.find((x) => x.id === source.id);
            let arr = newArr.filter(
                (x) => x.queue === droppableDestination.droppableId
            );
            movedCard.queue = droppableDestination.droppableId;
            movedCard.slot = droppableDestination.droppableId.includes(getConstants().TextStage) ?
                parseInt(droppableDestination.droppableId.substring(getConstants().TextStage.length)) :
                parseInt(droppableDestination.droppableId.substring(getConstants().PhoneStage.length));
            if (isNaN(movedCard.slot))
                movedCard.slot = null;
            arr.splice(droppableDestination.index, 0, movedCard);
            let otherArr = messages.current.filter(
                (message) => message.queue !== droppableDestination.droppableId
            );
            let newMessagesArr = [...arr, ...otherArr];
            let newIndex = newMessagesArr.findIndex((x) => x.id === movedCard.id);

            movedCard.action = "Move";

            messages.current = newMessagesArr;

            PubNubHelper.MoveCard(pubNub, movedCard, newIndex);
            if (
                droppableDestination.droppableId.includes("Production") &&
                droppableSource.droppableId.includes("Stage")
            ) {
                PubNubHelper.SetCallState(
                    pubNub,
                    source,
                    MessageActions.EndCall,
                    source.status,
                    droppableSource.droppableId,
                    source.phoneNumber,
                    source.podiumNumber
                );
            }
        }
        render({});
        render({});
    };

    const onDragEnd = (result) => {
        const { source, destination } = result;
        let messageExits = messages.current && messages.current.filter((msg) => msg && msg.id === result.draggableId).length > 0;
        if (!messageExits) {
            // 240871 Participant that left queue, card has been cancel by external event
            return;
        }
        if (source.droppableId.indexOf("Stage") > -1) {
            if (stageStatuses.current.activeSpeaker === source.droppableId) {
                stageStatuses.current.activeSpeaker = "";
                stagePodiumNumbers.current.activeSpeaker = "";
            }
        }
        const queues = ["Support", "Oppose", "Point of Order", "Point of Information"];
        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            let newArr = messages.current.filter(
                (message) => message.queue === source.droppableId
            );
            let otherArr = messages.current.filter(
                (message) => message.queue !== source.droppableId
            );
            const items = reorder(newArr, source.index, destination.index);
            messages.current = [...otherArr, ...items];
            // production queue order is handled at the end of this function
        }
        if(
            (source.droppableId === "Point of Order" && destination.droppableId === "Point of Information") ||
            (source.droppableId === "Point of Information" && destination.droppableId === "Point of Order")
         ){
            messages.current = messages.current.map(a=>{
                if (a.id  == result.draggableId) {
                    return { ...a, participationType: destination.droppableId };
                }
                return a;
            });
            move(
                getList(source.droppableId).filter(
                    (x) => x.id === result.draggableId
                )[0],
                getList(destination.droppableId)[destination.index],
                source,
                destination
            );
        }
        if((source.droppableId === "Production" && destination.droppableId === "Point of Information") ||
        (source.droppableId === "Production" && destination.droppableId === "Point of Order")){
            messages.current = messages.current.map(a=>{
                if (a.id  == result.draggableId && ((a.participationType === "Point of Information") || (a.participationType === "Point of Order"))) {
                    return { ...a, participationType: destination.droppableId };
                }
                return a;
            });
        }
        if (
            queues.includes(source.droppableId) &&
            destination.droppableId !== "Production"
        ) {
            return;
        } else {
            move(
                getList(source.droppableId).filter(
                    (x) => x.id === result.draggableId
                )[0],
                getList(destination.droppableId)[destination.index],
                source,
                destination
            );
        }
        // any changes in production queue sequence should publish new reorder message
        if (source.droppableId === "Production" || destination.droppableId === "Production") {
            const productionQueue = getList("Production");
            let auth0IdSequence = [];
            if (productionQueue.length > 0) {
                auth0IdSequence = productionQueue.map(a => a.auth0Id);
                PubNubHelper.ReOrderQueue(
                    pubNub,
                    userData,
                    productionQueue[0].resolutionId,
                    "Production",
                    source.slot,
                    auth0IdSequence
                );
            }
        }
        render({});
        render({});
    };

    const submitResolution = () => {
        if (messages.current.length > 0) {
            let pointOfOrderExists = messages.current.some(
                (x) => x.participationType === "Point of Order"
            );
            if (pointOfOrderExists) {
                window.alert(
                    "Cannot create new resolution until all point of order messages are cleared from the current resolution queue!"
                );
                return;
            }
            let pointOfInformationExists = messages.current.some(
                (x) => x.participationType === "Point of Information"
            );
            if (pointOfInformationExists) {
                window.alert(
                    "Cannot create new resolution until all point of information messages are cleared from the current resolution queue!"
                );
                return;
            }
            let lockCards = messages.current.some(
                (x) => x.isLock === true
            );
            if (lockCards) {
                window.alert(
                    "Cannot create new resolution until all lock messages are cleared from the current resolution queue!"
                );
                return;
            }
            let speaker = messages.current.some(
                (x) => x.isSpeaking === true
            );
            if (speaker) {
                window.alert(
                    "Cannot create new resolution until speaker is cleared from the current resolution queue!"
                );
                return;
            }
        }
        clearQueues();

        setTimeout(function () {
            resolution.current = input.current;
            var newMessage = PubNubHelper.BeginQueue(pubNub, userData, input.current);
            resolutionId.current = newMessage.id;
            input.current = "";
            render({});
            render({});
        }, 200);
    };

    const setInput = (name) => {
        input.current = name;
        render({});
        render({});
    };

    return (
        <ModeratorQueue
            messages={messages.current}
            input={input.current}
            resolution={resolution.current}
            clearQueues={clearQueues}
            setInput={setInput}
            submitResolution={submitResolution}
            onDragEnd={onDragEnd}
            isLoaded={isLoaded.current}
            stageStatuses={stageStatuses.current}
            stagePodiumNumbers={stagePodiumNumbers.current}
            isPubnubDown={pubNubDown}
            pausePubnubMessages={pausePubnubMessages}
            disablePausePubnubMessages={disablePausePubnubMessages}
            resumePubnubMessages={resumePubnubMessages}
            disableResumePubnubMessages={disableResumePubnubMessages}
            reloadPubnubMessages={loadResolutionData}
            disableReloadPubnubMessages={disableReloadPubnubMessages}
            onCardColorChange={onCardColorChange}
            colorCode={colorCode.current}
        />
    );
}

export default ModeratorListener;
