import React, { useState, useEffect, useRef, useContext } from "react";
import { usePubNub } from "pubnub-react";
import { useAuth0 } from "@auth0/auth0-react";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import IconButton from "@material-ui/core/IconButton";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import CallEndIcon from "@material-ui/icons/CallEnd";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DoneIcon from "@material-ui/icons/Done";
import PhoneDisabledIcon from "@material-ui/icons/PhoneDisabled";
import "./styles.css";
import { MessageActions } from "../../infrastructure/MessageActions";
import PubNubHelper from "../../infrastructure/PubNubHelper";
import { makeStyles } from "@material-ui/core/styles";
import { ConfigurationContext } from "../../infrastructure/ConfigurationContext";
import { UserContext } from "../../infrastructure/UserContext";
import { PostUrlFormEncoded, PostUrlFormEncodedWithAuth } from "../../infrastructure/HttpClient";
import { getToPhoneLocal } from "../../infrastructure/GetToPhoneLocal";
import getConstants from "../../infrastructure/Constants";

const useStyles = makeStyles({
  button: {
    padding: "2px",
  },
});
const StageCard = ({ message, isPresidentsView, isLoaded, stageStatuses, stagePodiumNumbers }) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const iconSize = isPresidentsView ? 40 : 24;
  const activeConfig = useContext(ConfigurationContext);
  const userData = useContext(UserContext);
  const FROM_PHONE = activeConfig.configData.twilioPhoneNumber;
  const options = [
    {
      status: "Dialing",
      icon: (
        <>
          <img
            src="https://static.thenounproject.com/png/162416-200.png"
            style={{ height: `${iconSize}px`, width: `${iconSize}px` }}
          />
        </>
      ),
    },
    {
      status: "Speaking",
      icon: (
        <>
          <RecordVoiceOverIcon style={{ fontSize: iconSize }} />
        </>
      ),
    },
    {
      status: "Connected",
      icon: (
        <>
          <CheckBoxIcon style={{ fontSize: iconSize }} />
        </>
      ),
    },
    {
      status: "Failed",
      icon: (
        <>
          <div>Failed</div>
        </>
      ),
    },
    {
      status: "Disconnected",
      icon: (
        <>
          <PhoneDisabledIcon style={{ fontSize: iconSize }} />
        </>
      ),
    },
    {
      status: "Ready",
      icon: (
        <>
          <DoneAllIcon style={{ fontSize: iconSize }} />
        </>
      ),
    },
    {
      status: "NotReady",
      icon: (
        <>
          <PhoneDisabledIcon style={{ fontSize: iconSize }} />
        </>
      ),
    },
  ];
  const pubNub = usePubNub();
  const selectedOption = useRef(options[0]);
  const previousOption = useRef(options[0]);
  const isMounted = useRef(true);
  const [isSpeaker, setIsSpeaker] = useState(false);

  useEffect(() => {

    if (stageStatuses && stageStatuses[message.queue]) {
      switch (stageStatuses[message.queue]) {
        case "Dialing":
          previousOption.current = selectedOption.current;
          selectedOption.current = options[0];
          break;
        case "Connected":
          previousOption.current = selectedOption.current;
          selectedOption.current = options[2];
          break;
        case "Failed":
          previousOption.current = selectedOption.current;
          selectedOption.current = options[3];
          break;
        case "Disconnected":
          previousOption.current = selectedOption.current;
          selectedOption.current = options[4];
          break;
        case "Ready":
          previousOption.current = selectedOption.current;
          selectedOption.current = options[5];
          break;
          case "NotReady":
            previousOption.current = selectedOption.current;
            selectedOption.current = options[6];
            break;
      }
    }

    if (stageStatuses) {
      if (stageStatuses.activeSpeaker === message.queue) {
        setIsSpeaker(true);
        // Don't change my status if I'm already the speaker
        if (selectedOption.current.status !== "Speaking") {
          previousOption.current = selectedOption.current;
          selectedOption.current = options[1];
        }
      } else {
        setIsSpeaker(false);
        // I was the speaker, but now I'm not
        if (selectedOption.current.status === "Speaking")
          // User is speaking but currently not active speaker then show it as Ready
          selectedOption.current = options[5];
      }
    }

    // const slot = message.queue.includes(getConstants().TextStage) ? 
    //   parseInt(message.queue.substring(getConstants().TextStage.length)) + getConstants().TextStageOffset -1: 
    //   parseInt(message.queue.substring(getConstants().PhoneStage.length)) - 1;
    // let slotString = `TextStage${slot}`;

    // if (stagePodiumNumbers && stagePodiumNumbers[slotString]) {
    //   if (stagePodiumNumbers[slotString].userId === message.userId) {
    //     message.podiumNumber = stagePodiumNumbers[slotString].podiumNumber;
    //     if (stagePodiumNumbers.activeSpeaker === slotString) {
    //       //message.callStatus = "Speaking";
    //     } else {
    //       //message.callStatus = "Ready"; // we know that this should have been ready (the only way to get a podium number in)
    //     }
    //   }
    // }

    if (isMounted.current && message.slot !== null && message.phoneNumber && isLoaded) {
      if (message.slot >= getConstants().TextStageOffset) {

        PubNubHelper.SetCallState(
          pubNub,
          message,
          MessageActions.Connected,
          message.status,
          message.queue, // slot, 
          message.phoneNumber,
          message.podiumNumber
        );

        // let details = {
        //   operatorLineNumber: message.slot, 
        //   fromPhone: getToPhoneLocal(FROM_PHONE), 
        //   toPhone: getToPhoneLocal(message.phoneNumber), 
        //   delegateId: message.userId, 
        //   messageBody: activeConfig.configData.welcomeTextMessage, 
        //   eventId: userData.eventId, 
        //   sessionId: userData.sessionId ? userData.sessionId : ''
        // };

        // PostUrlFormEncoded('/Text/SendMessage', details)
        // .then((response) => {
        //   if (response != null && response != undefined) {


        //     console.log('StageCard:164 Sending TextState, outgoing message is ', message);

        //     PubNubHelper.SetTextState(
        //       pubNub,
        //       message,
        //       message, //activeConfig.configData.welcomeTextMessage,
        //       MessageActions.Sent,
        //       message.status, 
        //       message.queue, // slot, 
        //       FROM_PHONE,
        //       message.phoneNumber,
        //       message.userId,
        //       message.podiumNumber
        //     );
        //   }
        // })
        // .catch((e) => {
        //   details['messageBody'] = 'Failure: ' + details['messageBody'];
        // });
      } else {
        PubNubHelper.SetCallState(
          pubNub,
          message,
          message.isCalled ? MessageActions.Connected : MessageActions.BeginCall,
          message.status,
          message.queue, // slot, 
          message.phoneNumber,
          message.podiumNumber
        );
      }
    }

    return () => {
      isMounted.current = false;
    };
  });

  const deleteMessage = () => {
    if (message.slot == null) return;

    if (message.slot >= (getConstants().TextStageOffset - 1)) {
      let details = {
        operatorLineNumber: message.slot,
        fromPhone: getToPhoneLocal(FROM_PHONE),
        toPhone: getToPhoneLocal(message.phoneNumber),
        delegateId: message.userId,
        messageBody: activeConfig.configData.goodbyeTextMessage,
        eventId: userData.eventId,
        sessionId: userData.sessionId ? userData.sessionId : ''
      };
      getAccessTokenSilently()
        .then((accessToken) => {
          PostUrlFormEncodedWithAuth('/Text/SendMessage', details, accessToken)
            .then((response) => {
              if (response != null && response != undefined) {
                if (response.data && response.data.isSuccess) {
                  PubNubHelper.SetCallState(
                    pubNub,
                    message,
                    MessageActions.EndCall,
                    message.status,
                    message.queue,
                    message.phoneNumber,
                    message.podiumNumber
                  );

                  PubNubHelper.SetTextState(
                    pubNub,
                    message,
                    activeConfig.configData.goodbyeTextMessage,
                    MessageActions.EndCall,
                    message.status,
                    message.queue,
                    FROM_PHONE,
                    message.phoneNumber,
                    message.userId,
                    message.podiumNumber
                  );
                  PubNubHelper.LeaveQueue(
                    pubNub,
                    message,
                    message.resolutionId,
                    message.participationType
                  );
                  PubNubHelper.EvictUser(pubNub, message);
                } else if (response.data && response.data.error) {
                  window.alert("Error occured while sending the goodbye message, Please check the delegate phone number.\nInternal Error:" + response.data.error);
                }
              }
            })
            .catch((e) => {
              details['messageBody'] = 'Failure: ' + details['messageBody'];
            });
        });
    } else {
      PubNubHelper.SetCallState(
        pubNub,
        message,
        MessageActions.EndCall,
        message.status,
        message.queue,
        message.phoneNumber,
        message.podiumNumber
      );

      PubNubHelper.LeaveQueue(
        pubNub,
        message,
        message.resolutionId,
        message.participationType
      );
      PubNubHelper.EvictUser(pubNub, message);
    }
  };

  const setSpeaker = (e) => {
    setIsSpeaker(true);
    PubNubHelper.SetCallState(
      pubNub,
      message,
      MessageActions.UpdateCallStatus,
      "Speaking",
      message.queue,
      message.phoneNumber,
      message.podiumNumber,
      + new Date()
    );
  };

  return (
    <div className="card-wrapper">
      {
        message?.queue.includes(getConstants().TextStage) && isSpeaker && !isPresidentsView && (
          <DoneAllIcon style={{ fontSize: iconSize }} />
        )
      }
      {
        selectedOption.current.status !== "Dialing" ? selectedOption.current.icon :
          message?.queue.includes(getConstants().PhoneStage) ? (
            <img
              src="https://static.thenounproject.com/png/162416-200.png"
              style={{ height: `${iconSize}px`, width: `${iconSize}px` }}
            />
          ) :
            (message?.podiumNumber !== null && message.podiumNumber.trim() !== "") ? (
              <DoneAllIcon style={{ fontSize: iconSize }} />
            ) : (
              <CheckBoxIcon style={{ fontSize: iconSize }} />
            )
      }
      {message?.queue.includes("Stage") && !isSpeaker && !isPresidentsView && (
        <IconButton
          onClick={() => setSpeaker()}
          classes={{ root: classes.button }}
        >
          <PersonAddIcon style={{ color: "#000" }} />
        </IconButton>
      )}

      {!isPresidentsView && (
        <IconButton
          onClick={() => {
            deleteMessage();
          }}
          classes={{ root: classes.button }}
        >
          <CallEndIcon style={{ color: "#000" }} />
        </IconButton>
      )}
    </div>
  );
};

export default StageCard;
