export enum MessageActions {
  NewResolution = "NewResolution",
  InitiateVote = "InitiateVote",
  EndVote = "EndVote",
  QueueReorder = "QueueReorder",
  BeginCall = "BeginCall",
  EndCall = "EndCall",
  Disconnected = "Disconnected",
  Connected = "Connected",
  Dialing = "Dialing",
  UpdateCallStatus = "UpdateCallStatus",
  Evicted = "Evicted",
  Clear = "Clear",
  PauseAndClear = "PauseAndClear",
  Create = "Create",
  EndVoteResults = "EndVoteResults",
  WaitingRoom = "WaitingRoom",
  RefreshPage = "RefreshPage",
  Move = "Move",
  Sent = "Sent",
  NotReady = "NotReady"
}
