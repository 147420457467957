import PubNubMessageBase from "./PubNubMessageBase";

class ResolutionMessage extends PubNubMessageBase {
  constructor(
    user,
    messageType,
    resolutionId,
    action,
    participationType,
    queue,
    color,
    podiumNumber,
    callStatus,
    isCalled
  ) {
    super(user, messageType, podiumNumber);
    this.name = user.firstName + " " + user.lastName;
    this.locale = user.city;
    this.language = user.preferredLanguage;
    this.chapter = user.chapter;
    this.state = user.state;
    this.phoneNumber = user.phoneNumber;
    this.action = action;
    this.resolutionId = resolutionId;
    this.participationType = participationType;
    this.queue = queue;
    this.color = color;
    this.callStatus = callStatus;
    this.isCalled = isCalled;
    this.roleColor = user.roleColor;
    this.assignedRole = user.assignedRole;
  }
}

export default ResolutionMessage;
