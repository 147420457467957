import "./initialvotestyles.css";
import "../voting.css";

import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";

import Can from "../../../Can";
import { ConfigurationContext } from "../../../../infrastructure/ConfigurationContext";
import { Get } from "../../../../infrastructure/HttpClient";
import { GetWithAuth } from "../../../../infrastructure/HttpClient";
import { MessageActions } from "../../../../infrastructure/MessageActions";
import { Permissions } from "../../../../infrastructure/RbacEnum";
import PubNubHelper from "../../../../infrastructure/PubNubHelper";
import { UserContext } from "../../../../infrastructure/UserContext";
import VoteListener from "../EndVote/VoteListener.jsx";
import fileDownload from "js-file-download";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth0 } from "@auth0/auth0-react";
import { usePubNub } from "pubnub-react";

const InitiateVote = () => {
  const userData = useContext(UserContext);
  const activeConfig = useContext(ConfigurationContext);
  const { getAccessTokenSilently } = useAuth0();
  const pubNub = usePubNub();
  const [voteId, setVoteId] = useState("");
  const [voteDescription, setVoteDescription] = useState("");
  const [enabledVoteWeight, setEnabledVoteWeight] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [uniquePollId, setUniquePollId] = useState("");

  const idChange = (event) => {
    setVoteId(event.target.value);
  };

  const descriptionChange = (event) => {
    setVoteDescription(event.target.value);
  };
  const voteWeightChange = (event) => {
    setEnabledVoteWeight(event.target.checked);
  };
  const getVotingReport = async (voteGuId) => {
    let reportUrl = `/Report/GetVoteReport?eventId=${activeConfig.configData.eventId}`;
    reportUrl += !voteGuId && activeConfig.configData.session ? `&sessionId=${activeConfig.configData.session.id}` : '';
    reportUrl += voteGuId ? `&voteGuId=${voteGuId}` : '';
    getAccessTokenSilently()
      .then((accessToken) => {
        GetWithAuth(reportUrl, accessToken)
          .then((response) => {
            fileDownload(
              response.data,
              voteGuId ? `VotingReport_${voteGuId}_${Date().toLocaleString()}.csv` : `VotingReport_${Date().toLocaleString()}.csv`
            );
          });
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    const id = PubNubHelper.StartVote(
      pubNub,
      userData,
      voteId,
      voteDescription,
      enabledVoteWeight
    );
    setUniquePollId(id);
  };

  const useStyles = makeStyles({
    table: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "200px",
    },
  });

  const classes = useStyles();

  useEffect(() => {
    if (!userData) return;

    PubNubHelper.GetCurrentVote(pubNub, userData).then((response) => {
      if (!response || response.action !== MessageActions.InitiateVote) return;
      setSubmitted(true);
      setVoteId(response.resolutionNumber);
      setVoteDescription(response.description);
      setUniquePollId(response.id);
      setEnabledVoteWeight(response.enabledVoteWeight);
    });

    return () => { };
  }, [userData]);

  return (
    <Can
      permissions={userData?.permissions}
      task={Permissions.Moderate}
      yes={() => (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => getVotingReport()}
            style={{ float: "right", marginTop: "0.5rem" }}
            id="download-vote-button"
          >
            Download voting report
          </Button>
          {submitted ? (
            <VoteListener
              voteId={voteId}
              voteDescription={voteDescription}
              enabledVoteWeight={enabledVoteWeight}
              uniquePollId={uniquePollId}
              downloadReport={(e) => getVotingReport(e)}
            />
          ) : (
            <FormControl className={classes.table} id="initiate-vote-form">
              <Card>
                <div className="header">
                  <CardHeader title="Vote # :" />
                </div>
                <TextField
                  variant="outlined"
                  size="small"
                  label="Vote Id"
                  onChange={(e) => idChange(e)}
                  value={voteId}
                  inputProps={{ maxLength: 50 }}
                  id="vote-id-field"
                />
                <TextField
                  variant="outlined"
                  label="Description"
                  size="small"
                  onChange={(e) => {
                    descriptionChange(e);
                  }}
                  value={voteDescription}
                  inputProps={{ maxLength: 50 }}
                  id="vote-description-field"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={enabledVoteWeight}
                      onChange={(e) => {
                        voteWeightChange(e)
                      }}
                      name="enabledVoteWeight"
                      color="primary"
                    />
                  }
                  label="Enable vote weight"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                  id="submit-vote-button"
                >
                  Submit Vote
                </Button>
              </Card>
            </FormControl>
          )}
        </>
      )}
    />
  );
};

export default InitiateVote;
