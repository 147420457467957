import React, {useContext} from "react";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ResolutionListener from "./ResolutionListener";
import Can from "../Can";
import {Permissions} from "../../infrastructure/RbacEnum";
import {UserContext} from "../../infrastructure/UserContext";

const useStyles = makeStyles({
  containerPadding: {
    padding: "100px",
  },
});

const WaitingRoom = (props) => {
  const { waitingRoomEnabled, waitingRoomContent } = props;
  const classes = useStyles();
  const userData = useContext(UserContext);
  if (waitingRoomEnabled) {
    return (
        <Can
            task={Permissions.WaitingRoomSkip}
            permissions={userData?.permissions}
            yes={() => <ResolutionListener />}
            no={() =>       <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
                className={classes.containerPadding}
            >
              <Grid item>
                <Typography variant="h2">{waitingRoomContent}</Typography>
              </Grid>
            </Grid>}
        />

    );
  } else {
    return <ResolutionListener />;
  }
};

export default WaitingRoom;
