import React from "react";
import { Draggable } from "react-beautiful-dnd";
import "./styles.css";
import LanguageIcon from "@material-ui/icons/Language";
import StageCard from "./StageCard";

const PresidentsCard = ({ message, messageIndex, stageStatuses, stagePodiumNumbers }) => {
  let style = {
    backgroundColor: message.roleColor,
    width: "20px",
    minHeight: "100px",
    marginLeft: "-5px",
    paddingRight: "5px",
  };

  return (
    <>
      {message.id !== undefined ? (
        <Draggable
          key={messageIndex}
          draggableId={message.id}
          index={messageIndex}
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              key={provided.messageIndex}
              style={{ display: "flex" }}
            >
              <div style={style} />
              <div className="president-content-wrapper">
                <div className="row president-content">
                  <div className="president-card-delegate-name">{message.name}</div>
                  <div className="overflow-wrap">{message.chapter}</div>
                  <div>{message.state}</div>
                </div>

                <div className="row" style={{ width: "125px", padding: "0px", textAlign: "left" }}>
                  <div style={{ width: "70px", padding: "0px", textAlign: "left" }}>
                      {message.queue.includes("Stage") && (
                        <StageCard
                          message={message}
                          isPresidentsView={true}
                          stageStatuses={stageStatuses}
                          stagePodiumNumbers={stagePodiumNumbers}
                        />
                      )}
                      {message.language !== "English" && (
                        <LanguageIcon style={{ fontSize: 40 }} />
                      )}
                  </div>
                  <div className="podium-number" style={{ width: "125px", overflow: "hidden" }}>
                    { message.podiumNumber }
                  </div>
                </div>

              </div>
            </div>
          )}
        </Draggable>
      ) : null}
    </>
  );
};

export default PresidentsCard;
