import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card } from "@material-ui/core";

const CardWrapper = ({
  message,
  messageIndex,
  provided,
  snapshot,
  children,
}) => {
  const useStyles = makeStyles({
    root: {
      background: message.color,
      borderRadius: 3,
      border: 1,
      borderStyle: "solid",
      ...provided.dragHandleProps.style,
      margin: "5px",
    },
  });
  const classes = useStyles(provided, snapshot);

  return (
    <Card
      classes={{
        root: classes.root,
      }}
      key={messageIndex}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      {children}
    </Card>
  );
};

export default CardWrapper;
