import React, { useState, useEffect, useContext, useRef } from "react";
import { usePubNub } from "pubnub-react";
import { UserContext } from "../../infrastructure/UserContext";
import { MessageActions } from "../../infrastructure/MessageActions";
import PresidentsView from "./index";
import PubNubHelper from "../../infrastructure/PubNubHelper";
import getConstants from "../../infrastructure/Constants";

const PresidentListener = () => {
  const pubNub = usePubNub();
  const userData = useContext(UserContext);

  const messages = useRef([]);
  const input = useRef("");
  const stageStatuses = useRef({});
  const stagePodiumNumbers = useRef({});
  const previousDate = useRef(0);
  const [ui, render] = useState({});

  const pnListener = {
    // Optional renderPage to turn off rendering page for bulk message processing
    message: (messageEvent, renderPage = true) => {
      let message = messageEvent.message;
      if (!messageEvent.message) message = messageEvent;

      // let slot = message.slot;
      // if (isNaN(slot) && message.queue && message.queue.length > 0) {
      //   slot = (message.queue.includes(getConstants().TextStage) ?
      //     parseInt(message.queue.substring(getConstants().TextStage.length)) + getConstants().TextStageOffset - 1 :
      //     parseInt(message.queue.substring(getConstants().PhoneStage.length))) - 1;
      // } else {
      //   slot = parseInt(message.slot) - 1;
      // }
      // if (isNaN(slot))
      //   slot = null;
      // if (slot !== null) {
      //   let slotString = `TextStage${slot}`;

      //   // if (stagePodiumNumbers.current && stagePodiumNumbers.current[slotString]) {
      //   //   if (stagePodiumNumbers.current[slotString].userId === message.userId) {
      //   //     message.podiumNumber = stagePodiumNumbers.current[slotString].podiumNumber;
      //   //     if (stagePodiumNumbers.current.activeSpeaker === slotString) {
      //   //       //message.callStatus = "Speaking";
      //   //     } else {
      //   //       //message.callStatus = "Ready"; // we know that this should have been ready (the only way to get a podium number in)
      //   //     }
      //   //   }
      //   // }
      // }

      switch (message.action) {
        case "Join": {
          const elems = messages.current.filter(
            (item) => item.userId === message.userId
          );
          // Don't add twice
          if (elems.length > 0) break;

          messages.current.push(message);
          break;
        }
        case MessageActions.Evicted:
        case "Leave": {
          let queue = message.queue;
          let currentMessages = messages.current;
          if (message.action === "Leave") {
            queue = messages.current.find((x) => x.userId === message.userId)
              ?.queue;
          }
          if (!queue) break;
          const elems = currentMessages.filter(
            (item) => item.userId !== message.userId
          );
          messages.current = elems;
          clearSingleStageQueueStatus(queue);
          break;
        }
        case "Clear":
          // Point of Order should not be clear from queue messages
          let pointOfOrderAndPointOfInformationMessages = messages.current ? messages.current.filter(
            (x) => x.participationType === "Point of Order" || x.participationType === "Point of Information"
          ) : [];
          messages.current = pointOfOrderAndPointOfInformationMessages;
          input.current = "";
          clearAllStageQueueStatuses();
          break;
        case "PauseAndClear":
          // Point of Order should not be clear from queue messages
          {
            input.current = message.resolutionNumber;
            messages.current = messages.current.filter(x => x.participationType === "Point of Order" || x.participationType === "Point of Information" || message.lockedUserIds.some(a => a === x.userId));
          }
          break;
        case "Create":
          input.current = message.resolutionNumber;
          clearAllStageQueueStatuses();
          break;
        case "Move": {
          let newArr = [...messages.current];
          let findIndex = newArr.findIndex((x) => x.id === message.id);
          if (findIndex === -1) break;
          if (newArr[findIndex].queue === stageStatuses.current.activeSpeaker) {
            stageStatuses.current.activeSpeaker = "";
            // stagePodiumNumbers.current.activeSpeaker = "";
          }
          newArr[findIndex] = message;
          messages.current = newArr;
          break;
        }
        case "ChangeColor": {
          let newArr = [...messages.current];
          let newCard = newArr.find((x) => x.userId === message.userId);
          if (newCard) {
            newCard.color = message.color;
          }
          messages.current = newArr;
          break;
        }
        case "ChangePodiumNumber": {
          let newArr = [...messages.current];
          let newCard = newArr.find((x) => x.userId === message.userId);
          if (newCard) {
            newCard.podiumNumber = message.podiumNumber;
          }
          messages.current = newArr;
          break;
        }
        // no default
      }
      if (renderPage) {
        render({});
        render({});
      }
    },
  };

  // Stage Card Listener
  const pnListenerStage = {
    // buildPodiumNumbers: (messageEvent) => {
    //   let msg = messageEvent.message ? messageEvent.message : messageEvent;
    //   stagePodiumNumbers.current[msg.slot] = {
    //     podiumNumber: msg.podiumNumber,
    //     userId: msg.userId,
    //   };
    // },
    message: (messageEvent) => {
      let msg = messageEvent.message ? messageEvent.message : messageEvent;
      // stagePodiumNumbers.current[msg.slot] = {
      //   podiumNumber: msg.podiumNumber,
      //   userId: msg.userId,
      // };
      let newArr = [...messages.current];
      if (msg.messageType === "UpdateCallStatus") {
        // update active speaker
        if (msg.action === "Speaking") {
          if (msg.timeToken >= previousDate.current) {
            stageStatuses.current.activeSpeaker = msg.slot;
            // stagePodiumNumbers.current.activeSpeaker = msg.slot;
            previousDate.current = msg.timeToken;
            let oldSpeaker = newArr.find((x) => x.isSpeaking === true);
            if (oldSpeaker) {
              oldSpeaker.isSpeaking = false;
            }
            let newSpeaker = newArr.find((x) => x.userId === msg.userId);
            if (newSpeaker) {
              newSpeaker.isSpeaking = true;
            }
          }
        }
        // update stage status and card callStatus
        let newCard = newArr.find((x) => x.queue === msg.slot);
        if (newCard) {
          newCard.callStatus = msg.action;
          newCard.podiumNumber = msg.podiumNumber;
        }
        messages.current = newArr;
        stageStatuses.current[msg.slot] = msg.action;

        if (messages.current.filter(a => a.queue === msg.slot).length > 0) {
          stageStatuses.current[msg.slot] = msg.action;
        } else {
          stageStatuses.current[msg.slot] = "";
        }
        if (msg.action !== "Speaking") {
          stagePodiumNumbers.current[msg.slot] = msg.podiumNumber;
        }
        render({});
        render({});
      }
    },
  };

  const loadResolutionData = () => {
    // Get Current Resolution
    PubNubHelper.GetCurrentResolution(pubNub, userData).then((resolutionResponse) => {
      if (resolutionResponse) {
        pnListener.message(resolutionResponse);
        if (resolutionResponse.queue && resolutionResponse.queue.indexOf('Stage') > -1) {
          // pnListenerStage.buildPodiumNumbers(resolutionResponse); // buildPodiumNumbers message 
        }
      }
      render({});
      // Get People in Queue
      let endTimeToken = undefined;
      // default to 'no-resolution' for Point of Order requests
      let resolutionId = 'no-resolution';
      // if current resolution then user its' id
      if (resolutionResponse) {
        resolutionId = resolutionResponse.id;
      }
      // if current resolution is Clear the use closingResolutionId
      if (resolutionResponse && resolutionResponse.action === 'Clear') {
        // endTimeToken to restrict history to last resolution
        endTimeToken = resolutionResponse.timetoken;
        resolutionId = resolutionResponse.closingResolutionId;
      }
      PubNubHelper.GetPeopleInResolution(pubNub, resolutionId, userData, undefined, endTimeToken).then(
        (peopleResponse) => {
          // if the resolution no longer exists then process only "Point of Order" and ordering messages
          if (resolutionResponse && resolutionResponse.action === 'Clear') {
            peopleResponse = peopleResponse.filter(x => (x.auth0IdSequence && x.messageType === MessageActions.QueueReorder)
              || x.participationType === "Point of Order" || x.participationType === "Point of Information");
          }
          if (peopleResponse) {
            peopleResponse.forEach((x) => {
              if (x.messageType !== MessageActions.QueueReorder) {
                pnListener.message(x, false);
                if (x.queue.indexOf('Stage') > -1) {
                  // pnListenerStage.buildPodiumNumbers(x);
                }
              }
            });
          }
          if (resolutionResponse && resolutionResponse.action === MessageActions.PauseAndClear && messages.current && messages.current.length > 0) {
            messages.current = messages.current.filter(a => resolutionResponse.lockedUserIds.some(x => x === a.userId));
          }
          if (messages.current && messages.current.length > 0) {
            // get latest status on page load for staging queue users
            messages.current.filter(a => a.auth0Id && a.queue && a.queue.indexOf('Stage') > -1).forEach(stagePerson => {
              PubNubHelper.GetUserCallStatus(pubNub, stagePerson.auth0Id, stagePerson.eventId).then(msg => {
                pnListenerStage.message(msg);
              });
            });
          }
          render({});
          render({});
        }
      );
    });
  }


  useEffect(() => {
    if (!userData) return;

    loadResolutionData();

    PubNubHelper.PresidentListenerSubscribe(pubNub, userData, [pnListener, pnListenerStage]);

    return () => {
      PubNubHelper.PresidentListenerUnsubscribe(pubNub, userData, [pnListener, pnListenerStage]);
    };
  }, [userData]);
  const clearAllStageQueueStatuses = () => {
    stageStatuses.current = {};
    stagePodiumNumbers.current = {};
  };
  const clearSingleStageQueueStatus = (queue) => {
    stageStatuses.current[queue] = undefined;
    stagePodiumNumbers.current[queue] = undefined;
    if (stageStatuses.current.activeSpeaker === queue) {
      stageStatuses.current.activeSpeaker = "";
      // stagePodiumNumbers.current.activeSpeaker = "";
    }
  };
  return (
    <PresidentsView
      messages={messages.current}
      input={input.current}
      stageStatuses={stageStatuses.current}
      stagePodiumNumbers={stagePodiumNumbers.current}
      reloadResolutionData={loadResolutionData}
    />
  );
};

export default PresidentListener;
