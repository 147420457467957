import "./VoteSelection.css";
import "../../../Resolution/styles.css";

import React, { useContext, useEffect } from "react";

import Button from "@material-ui/core/Button";
import { ConfigurationContext } from "./../../../../infrastructure/ConfigurationContext";
import FormControl from "@material-ui/core/FormControl";
import HowToVoteIcon from "@material-ui/icons/HowToVote";
import Modal from "react-bootstrap/Modal";
import PubNubHelper from "../../../../infrastructure/PubNubHelper";
import { SessionConfigurationContext } from "../../../../infrastructure/SessionConfigurationContext";
import ThumbDownOutlinedIcon from "@material-ui/icons/ThumbDownOutlined";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import { Typography } from "@material-ui/core";
import { UserContext } from "../../../../infrastructure/UserContext";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { usePubNub } from "pubnub-react";

const useStyles = makeStyles({
  button: { marginTop: 10, color: "#fff" },
  headers: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#666",
    textAlign: "center",
    paddingBottom: "2px",
  },
  blue: {
    backgroundColor: "#0066B3",
    "&:hover": {
      backgroundColor: "#8ecef1",
    },
  },
  background: {
    backgroundColor: "rgba(0,0,0,0.12)",
    fontSize: ".7rem",
  },
});

const VoteSelection = ({
  VoteQueue,
  CurrentVote,
  CastVote,
  ShowVoteModal,
  SetShowVoteModal,
  setActionError
}) => {
  const pubNub = usePubNub();
  const userData = useContext(UserContext);
  const sessionData = useContext(SessionConfigurationContext);
  const activeConfig = useContext(ConfigurationContext);
  const classes = useStyles();

  const handleModalClose = () => SetShowVoteModal(false);
  useEffect(() => { }, [CurrentVote]);

  const castVote = (voteChoice) => {
    //handleModalClose();
    SetShowVoteModal(false);
    try {
      let voteWeight = 1;
      if (VoteQueue.enabledVoteWeight) {
        if (sessionData && sessionData.configData) {
          const session = userData.sessions.find(a => a.sessionId === sessionData.configData.id);
          if (session) {
            voteWeight = session.voteWeight || 1;
          } else {
            voteWeight = 1;
          }
        } else {
          voteWeight = userData.voteWeight || 1;
        }
      }
      PubNubHelper.CastVote(pubNub, userData, VoteQueue, voteChoice, voteWeight);
      CastVote(voteChoice);
    } catch (err) {
      console.log('Operation failed error:', err);
      if (setActionError) {
        setActionError({ hasError: true, errorInfo: 'Voting: Operation failed.' });
      }
    }
  };

  return (
    <>
      {ShowVoteModal && (
        <Modal show={ShowVoteModal} onHide={handleModalClose} keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>
              Vote: {VoteQueue.resolutionNumber}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              Description: {VoteQueue.description} <br />
              Make a vote selection for {activeConfig.configData.uiConfigs.button_resolution_txt} {
                VoteQueue.resolutionNumber
              }{" "}
              or choose to abstain from the vote.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="vote-buttons">
              <Button
                id="vote-yes"
                variant="contained"
                className={classnames(classes.button, classes.blue)}
                onClick={() => {
                  castVote("Yes");
                }}
                disabled={CurrentVote === "Yes" || !VoteQueue || !VoteQueue.id}
              >
                <span style={{ paddingRight: "4px" }}>
                  {" "}
                  <ThumbUpAltOutlinedIcon />{" "}
                </span>{" "}
                {activeConfig.configData.uiConfigs.button_yes_txt}
              </Button>
              <Button
                id="vote-abstain"
                variant="contained"
                hidden={
                  activeConfig.configData.uiConfigs.button_abstain_show === 'false' ||
                  !ShowVoteModal ||
                  CurrentVote === "No" ||
                  CurrentVote === "Yes" ||
                  !VoteQueue ||
                  !VoteQueue.id
                }
                className={classnames(classes.button, classes.blue)}
                onClick={() => {
                  handleModalClose();
                }}
                disabled={
                  CurrentVote === "No" ||
                  CurrentVote === "Yes" ||
                  !VoteQueue ||
                  !VoteQueue.id
                }
              >
                {activeConfig.configData.uiConfigs.button_abstain_txt}
              </Button>
              <Button
                id="vote-no"
                variant="contained"
                className={classnames(classes.button, classes.blue)}
                onClick={() => {
                  castVote("No");
                }}
                disabled={CurrentVote === "No" || !VoteQueue || !VoteQueue.id}
              >
                <span style={{ paddingRight: "4px" }}>
                  <ThumbDownOutlinedIcon />
                </span>{" "}
                {activeConfig.configData.uiConfigs.button_no_txt}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      <div className="content">
        <ul className="listing">
          <li>
            <Typography variant="body2">
              {" "}
              <HowToVoteIcon />
              Voting
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              {" "}
              <span
                id="vote-online"
                className={VoteQueue && VoteQueue.id ? "online" : "offline"}
              ></span>
              {VoteQueue && VoteQueue.id ? "Open" : "Closed"}
            </Typography>
          </li>
        </ul>
        <div className="notify-vote">
          <Typography variant="h6" className={classes.headers} id="vote-name">
            {VoteQueue !== undefined && VoteQueue.id
              ? "Vote: " + VoteQueue.resolutionNumber
              : "No vote is currently active"}
          </Typography>
          <Typography
            variant="h6"
            className={classes.headers}
            id="vote-description"
          >
            {VoteQueue !== undefined ? VoteQueue.description : ""}
          </Typography>
          {VoteQueue && VoteQueue.id && CurrentVote && (
            <Typography
              id="vote-submitted"
              variant="h6"
              className={classnames(classes.headers, classes.background)}
            >
              Vote submitted! You have selected "{activeConfig.configData.uiConfigs[
                "button_" + CurrentVote.toLowerCase() + "_txt"
              ]}"
            </Typography>
          )}
        </div>
        <FormControl fullWidth={true}>
          <Button
            id="vote-yes"
            variant="contained"
            className={classnames(classes.button, classes.blue)}
            onClick={() => {
              castVote("Yes");
            }}
            disabled={CurrentVote === "Yes" || !VoteQueue || !VoteQueue.id}
          >
            <span style={{ paddingRight: "4px" }}>
              {" "}
              <ThumbUpAltOutlinedIcon />{" "}
            </span>{" "}
            {activeConfig.configData.uiConfigs.button_yes_txt}
          </Button>
          <Button
            id="vote-abstain"
            variant="contained"
            hidden={activeConfig.configData.uiConfigs.button_abstain_show === 'false'}
            className={classnames(classes.button, classes.blue)}
            onClick={() => {
              handleModalClose();
            }}
            disabled={
              CurrentVote === "No" ||
              CurrentVote === "Yes" ||
              !VoteQueue ||
              !VoteQueue.id
            }
          >
            {activeConfig.configData.uiConfigs.button_abstain_txt}
          </Button>
          <Button
            id="vote-no"
            variant="contained"
            className={classnames(classes.button, classes.blue)}
            onClick={() => {
              castVote("No");
            }}
            disabled={CurrentVote === "No" || !VoteQueue || !VoteQueue.id}
          >
            <span style={{ paddingRight: "4px" }}>
              <ThumbDownOutlinedIcon />
            </span>{" "}
            {activeConfig.configData.uiConfigs.button_no_txt}
          </Button>
        </FormControl>
      </div>
    </>
  );
};

export default VoteSelection;
