export const getToPhoneLocal = (phoneNumber) => {
    let toPhoneLocal = phoneNumber || '';
    if (toPhoneLocal.length === 10 && toPhoneLocal.substring(0, 1) !== '+') {
        toPhoneLocal = '+1' + toPhoneLocal; // US
    } else if (toPhoneLocal.length === 11 && toPhoneLocal.substring(0, 1) === '1') {
        toPhoneLocal = '+' + toPhoneLocal; // US
    } else if (toPhoneLocal.substring(0, 1) !== '+') {
        toPhoneLocal = '+' + toPhoneLocal; // International
    }

    return toPhoneLocal;
};
