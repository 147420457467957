import {
    CardHeader,
    MenuItem,
    Paper,
    Select,
    TextField
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import React, { useContext, useEffect, useState } from "react";
import CardContent from "./CardContent";
import { ConfigurationContext } from "../../infrastructure/ConfigurationContext";
import DraggableContainer from "./DraggableContainer";
import { Droppable } from "react-beautiful-dnd";
import Pagination from "@material-ui/lab/Pagination";
import PresidentsCard from "./PresidentsCard";
import { makeStyles } from "@material-ui/core/styles";

const InnerList = React.memo(function InnerList(props) {
    const messages = props.messages;
    const isPresidentsView = props.isPresidentsView;

    return messages
        ? messages.map((message, messageIndex) => {
            return (
                <DraggableContainer
                    message={message}
                    messageIndex={messageIndex}
                    key={message.id}
                >
                    {isPresidentsView ? (
                        <PresidentsCard
                            message={message}
                            messageIndex={messageIndex}
                            stageStatuses={props.stageStatuses}
                            stagePodiumNumbers={props.stagePodiumNumbers}
                        />
                    ) : (
                        <CardContent
                            message={message}
                            messageIndex={messageIndex}
                            stageStatuses={props.stageStatuses}
                            stagePodiumNumbers={props.stagePodiumNumbers}
                            colorCode={props.colorCode}
                            onCardColorChange={props.onCardColorChange}
                        />
                    )}
                </DraggableContainer>
            );
        })
        : null;
});

const ActionWrapper = ({
    actionType,
    messages,
    isPresidentsView,
    stageStatuses,
    stagePodiumNumbers,
    clearPointOfOrder,
    colorCode,
    onCardColorChange,
}) => {
    const actionString = `${actionType}`;
    const [filteredMessages, setFilteredMessages] = useState(messages);
    const [page, setPage] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [role, selectRole] = useState("");
    const activeConfig = useContext(ConfigurationContext);
    const config = activeConfig.configData;
    const [count, setCount] = useState(0);
    useEffect(() => {
        searchMessages(searchValue, role, page);
    }, [messages]);

    const useStyles = makeStyles({
        root: {
            background: "#0066B3",
            textAlign: "center",
            color: "#f5f5f5",
            height: "40px",
        },
        paper: {
            overflow: "auto",
            overflowX: "hidden",
            height: "90%",
            maxHeight: "1000px",
        },
    });
    const classes = useStyles();
    const searchMessages = (searchValue, role = "", page = 1) => {
        let lowerCaseValue = searchValue.toLowerCase();

        let newMessages = messages?.filter(
            (message) =>
                (message?.name.toLowerCase().includes(lowerCaseValue) ||
                    message?.locale.toLowerCase().includes(lowerCaseValue)) &&
                message?.assignedRole.toLowerCase().includes(role.toLowerCase())
        );
        let count = Math.ceil(newMessages.length / 20);

        setCount(count);
        let slicedMessages = newMessages.slice((page - 1) * 20, 20 * page);
        setFilteredMessages(slicedMessages);
    };

    const handleSearch = (e) => {
        setSearchValue(e.target.value);
        searchMessages(e.target.value, role);
    };

    const filterByRole = (option) => {
        selectRole(option);
        searchMessages(searchValue, option);
    };
    const handleChange = (event, value) => {
        searchMessages(searchValue, role, value);
        setPage(value);
    };

    return (
        <div className="column">
            <CardHeader
                title={`${actionType} (${messages.length})`}
                classes={{
                    root: classes.root,
                }}
            />
            {!isPresidentsView && (
                <React.Fragment>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <TextField id={actionType + "-search"} inputProps={{ "aria-label": "Search" }} size="small" placeholder="Search…" onChange={(e) => handleSearch(e)} />
                        <div>
                            {clearPointOfOrder &&
                             (
                             <IconButton aria-label="delete" onClick={clearPointOfOrder}>
                               <DeleteIcon />
                             </IconButton>)
                            }
                        </div>
                        <Select
                            label="Role"
                            value={role}
                            displayEmpty
                            onChange={(e) => filterByRole(e.target.value)}
                            defaultValue=""
                            id={actionType + "-role-dropdown"}
                        >
                            <MenuItem value={""}>All roles</MenuItem>
                            {config.roles.map((role) => (
                                <MenuItem key={role.name} value={role.name}>
                                    {role.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Pagination
                            count={count}
                            size="small"
                            page={page}
                            onChange={handleChange}
                            id={actionType + "-pagination"}

                        />
                    </div>
                </React.Fragment>
            )}

            <Paper
                elevation={3}
                classes={{
                    root: classes.paper,
                }}
            >
                <Droppable droppableId={actionString}>
                    {(provided, snapshot) => (
                        <div
                            id={actionString + "-queue"}
                            ref={provided.innerRef}
                            className="drop-container"
                            key={actionString}
                            {...provided.droppableProps}
                        >
                            <InnerList
                                messages={filteredMessages}
                                isPresidentsView={isPresidentsView}
                                stageStatuses={stageStatuses}
                                stagePodiumNumbers={stagePodiumNumbers}
                                colorCode={colorCode}
                                onCardColorChange={onCardColorChange}
                            />
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </Paper>
        </div>
    );
};

export default ActionWrapper;
