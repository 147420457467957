import PubNubMessageBase from "./PubNubMessageBase";

class QueueMessage extends PubNubMessageBase {
  constructor(user, messageType, description, resolutionNumber, action, enabledVoteWeight, closingResolutionId, podiumNumber) {
    super(user, messageType, podiumNumber);
    this.description = description;
    this.resolutionNumber = resolutionNumber;
    this.action = action;
    // closingResolutionId for "Clear" queue message
    this.closingResolutionId = closingResolutionId;
    if (messageType === "ResolutionQueue" && action === "Create") {
      console.log("ResolutionId: " + this.id);
    }

    if (enabledVoteWeight) {
      this.enabledVoteWeight = enabledVoteWeight;
    }
  }
}

export default QueueMessage;
