import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { ConfigurationContext } from "./ConfigurationContext";
import { Get } from "./HttpClient";
import PageNotFound from "../infrastructure/PageNotFound";
import { SessionConfigurationContext } from "./SessionConfigurationContext";
import Spinner from "../components/Spinner";

const SessionConfigurationProvider = ({ children }) => {
  const activeConfig = useContext(ConfigurationContext);
  let { sessionId } = useParams();
  const [configData, setConfigData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    let isMounted = true;
    Get(`/Configuration/GetSessionConfiguration?sessionId=${sessionId}&eventId=${activeConfig.configData.eventId}`)
      .then((response) => {
        if (isMounted) {
          if (response.status === 200) {
            const config = response.data;
            setConfigData(config);
          } else {
            setHasError(true);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setHasError(true);
        console.log(err);
      });
    return () => (isMounted = false);
  }, [sessionId]);
  if (isLoading) {
    return <Spinner />;
  } else if (hasError) {
    return <PageNotFound />;
  } else {
    return (
      <SessionConfigurationContext.Provider value={{ configData }}>
        {children}
      </SessionConfigurationContext.Provider>
    );
  }
};

export default SessionConfigurationProvider;
