export enum PubNubChannels {
  NewResolution = "NewResolution",
  President = "President",
  VoteAdmin = "voteAdmin",
  VoteBallot = "voteBallot",
  ResolutionMessage = "ResolutionMessage",
  Broadcast = "Broadcast",
  Evicted = "Evicted",
  VirtualCallCenter = "VirtualCallCenter",
  UserCallStatus = "UserCallStatus",
  UserTextStatus = "UserTextStatus",
  EndVoteResults = "EndVoteResults",
  WaitingRoom = "WaitingRoom",
  TextLineMessageReturn = "TextLineMessageReturn",
}
