import axios from "axios";
import getConstants from "./Constants";

export function PostWithAuth(url, payload, authToken) {
  const baseUrl = getConstants().ApiUrl;
  if (url && url !== "") {
    return axios.post(`${baseUrl}/api${url}`, JSON.stringify(payload), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
  }
}

export function Post(url, payload) {
  const baseUrl = getConstants().ApiUrl;
  if (url && url !== "") {
    return axios.post(`${baseUrl}/api${url}`, JSON.stringify(payload), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export function PostUrlFormEncoded(url, payload) {
  const baseUrl = getConstants().ApiUrl;

  let formBody = [];
  for (let property in payload) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(payload[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  if (url && url !== "") {
    return axios.post(`${baseUrl}/api${url}`, formBody, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
  }
}

export function PostUrlFormEncodedWithAuth(url, payload, authToken) {
  const baseUrl = getConstants().ApiUrl;

  let formBody = [];
  for (let property in payload) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(payload[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  if (url && url !== "") {
    return axios.post(`${baseUrl}/api${url}`, formBody, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        Authorization: `Bearer ${authToken}`,
      },
    });
  }
}

export function PutWithAuth(url, payload, authToken) {
  const baseUrl = getConstants().ApiUrl;
  if (url && url !== "") {
    return axios.put(`${baseUrl}/api/${url}`, JSON.stringify(payload), {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
  }
}

export function GetWithAuth(url, authToken) {
  const baseUrl = getConstants().ApiUrl;
  if (url && url !== "") {
    return axios.get(`${baseUrl}/api${url}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
  }
}

export function Get(url) {
  const baseUrl = getConstants().ApiUrl;
  if (url && url !== "") {
    return axios.get(`${baseUrl}/api${url}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export function PostUserImport(eventGuid, payload, authToken) {
  const formData = new FormData();
  formData.append('file', payload);
  const baseUrl = getConstants().ApiUrl;
  return axios.post(`${baseUrl}/api/UserImports/ImportUserDataCSV/${eventGuid}`, formData, {
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${authToken}`
    }
  })

}
