import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./index.css";
import "./fonts/Futura Bold font.ttf";
import "./fonts/Futura Extra Black font.ttf";
import "./fonts/futura light bt.ttf";

import * as serviceWorker from "./serviceWorker";

import App from "./App";
import Auth0ProviderWithHistory from "./infrastructure/auth0-provider-with-history";
import { BrowserRouter } from "react-router-dom";
import ConfigurationProvider from "./infrastructure/ConfigurationProvider";
import GlobalErrorBoundary from "./components/GlobalErrorBoundary";
import PubNubProviderFunction from "./infrastructure/PubNubProviderFunction";
import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/core";
import UserProvider from "./infrastructure/UserProvider";
import { theme } from "./theme";

ReactDOM.render(
  <BrowserRouter>
    <GlobalErrorBoundary>
      <ConfigurationProvider>
        <Auth0ProviderWithHistory>
          <UserProvider>
            <PubNubProviderFunction>
              <React.StrictMode>
                <ThemeProvider theme={theme}>
                  <App />
                </ThemeProvider>
              </React.StrictMode>
            </PubNubProviderFunction>
          </UserProvider>
        </Auth0ProviderWithHistory>
      </ConfigurationProvider>
    </GlobalErrorBoundary>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
