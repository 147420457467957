import "./style.css";

import { Button, Typography } from "@material-ui/core";

import React from "react";

const check = (userPermissions, task) => {
  if (userPermissions.some((x) => x == task)) {
    return true;
  }
  return false;
};

const Can = (props) => {
  if (!props.permissions) {
    return props.no();
  }
  return check(props.permissions, props.task) ? props.yes() : props.no();
};

Can.defaultProps = {
  yes: () => null,
  no: () => (
    <div className="error-wrapper">
      <Typography color="error" align="center">
        You do not have permission to access this content
      </Typography>{" "}
      <Button variant="contained" color="primary" href="/">
        Return to homepage
      </Button>
    </div>
  ),
};

export default Can;
