import React from "react";
import "../styles.css";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import classnames from "classnames";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";

const useStyles = makeStyles({
  button: {
    marginTop: 10,
    color: "#fff",
    "&:disabled": { color: "rgb(0 0 0 / 0.26)" },
  },

  blue: {
    backgroundColor: "#0066B3",
    "&:hover": {
      backgroundColor: "#8ecef1",
    },
  },
});

const ResolutionButton = ({ resolutionData, ShowFunction }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <Button
          onClick={ShowFunction}
          variant="contained"
          className={classnames(classes.button, classes.blue)}
          fullWidth={true}
        >
          <div className="button-style" style={{ paddingTop: "5px" }}>
            <div className="button-wrapper">
              <SpeakerNotesIcon />
              <span className={resolutionData[0] ? "online border" : "offline border"} />
            </div>
            <p style={{ margin: "0px" }}>Request To Speak</p>
          </div>
        </Button>
      </Grid>
    </>
  );
};
export default ResolutionButton;
