/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-vars */

import PubNub, { HistoryMessage } from "pubnub";

import CallMessage from "../models/CallMessage";
import { MessageActions } from "./MessageActions";
import { PubNubChannels } from "./PubNubChannelEnum";
import Pubnub from "pubnub";
import QueueMessage from "../models/QueueMessage";
import QueueReorderMessage from "../models/QueueReorderMessage";
import ResolutionMessage from "../models/ResolutionMessage";
import User from "../models/User";
import Vote from "../models/Vote";
import TextMessage from "../models/TextMessage";

interface HandRaised {
    message: ResolutionMessage;
    timeToken: string | number | undefined;
}

// var sessionId: string;
// if (window.location.pathname.includes("session")) {
//     var urlArray = window.location.pathname.split("/");
//     var sessionIndex = urlArray.indexOf("session");
//     var sessionId = urlArray[sessionIndex + 1];
// } else {
//     sessionId = "";
// }


export default abstract class PubNubHelper {
    public static getSessionIdFromURL() {
        let sessionChannel = '';
        if (window.location.pathname.includes("session")) {
            const urlArray = window.location.pathname.split("/");
            const sessionIndex = urlArray.indexOf("session")
            sessionChannel = urlArray[sessionIndex + 1];
        }
        return sessionChannel;
    }
    public static appendSessionIdFromURL() {
        let sessionChannel = this.getSessionIdFromURL();
        return sessionChannel ? ('-' + sessionChannel) : '';
    }
    public static GetCurrentVote(
        pubNub: PubNub,
        userData: User
    ): Promise<QueueMessage> {
        const result = pubNub.history({
            channel: `${PubNubChannels.VoteAdmin}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            count: 1,
        });
        return result.then(
            (response) => {
                if (response && response.messages.length > 0)
                    return response.messages[0].entry;
                return undefined;
            },
            // Rejection triggered when PubNub provided before userData initialized
            (rejection) => {
                return new Promise<QueueMessage>(function (resolve) {
                    resolve(undefined);
                });
            }
        );
    }

    public static GetCurrentResolution(
        pubNub: PubNub,
        userData: User
    ): Promise<QueueMessage> {
        const result = pubNub.history({
            channel: `${PubNubChannels.NewResolution}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            count: 2, // fetch last two messages to get last 'create' time in case current is 'clear'
            reverse: false
        });
        return result.then(
            (response) => {
                if (response && response.messages.length > 0) {
                    // sort messages to newest to oldest
                    response.messages = response.messages.sort(
                        (a, b) => (b.timetoken as number) - (a.timetoken as number)
                    );
                    if (response.messages[0].entry && response.messages[0].entry.action === 'Clear' &&
                        response.messages[1] && response.messages[1].entry && response.messages[1].entry.action === 'Create') {
                        // if latest message is "clear" and previous is 'created then take last created timetoken
                        return { ...response.messages[0].entry, timetoken: response.messages[1].timetoken };
                    } else if (response.messages[0].entry && response.messages[0].entry.action === 'Clear' &&
                        response.messages[1] && response.messages[1].entry && response.messages[1].entry.action === 'PauseAndClear') {
                        // if latest message is "clear" and previous is 'PauseAndClear then take last created timetoken
                        return { ...response.messages[0].entry, timetoken: response.messages[1].entry.timetoken };
                    } else if (response.messages[0].entry && response.messages[0].entry.action === 'PauseAndClear' &&
                        response.messages[1] && response.messages[1].entry && response.messages[1].entry.action === 'Create') {
                        // if latest message is "PauseAndClear" and previous is 'Create' then take last created timetoken
                        return { ...response.messages[0].entry, pauseAndClear: true, lockedUserIds: response.messages[0].entry.lockedUserIds };
                    } else if (response.messages[0].entry && response.messages[0].entry.action === 'PauseAndClear' &&
                        response.messages[1] && response.messages[1].entry && response.messages[1].entry.action === 'PauseAndClear') {
                        // if latest message is "clear" and previous is 'created then take last created timetoken
                        return { ...response.messages[1].entry, pauseAndClear: true, timetoken: response.messages[1].entry.timetoken, lockedUserIds: response.messages[1].entry.lockedUserIds };
                    } else {
                        return response.messages[0].entry;
                    }
                }
                return undefined;
            },
            // Rejection triggered when PubNub provided before userData initialized
            (rejection) => {
                return new Promise<QueueMessage>(function (resolve) {
                    resolve(undefined);
                });
            }
        );
    }

    public static async GetPeopleInResolution(
        pubNub: PubNub,
        resolutionId: string,
        userData: User,
        timeToken: string | number | undefined,
        endTimeToken: string | number | undefined = undefined, // endTimeToken to fetch messages of older resolution
        allEvictedUsers: Array<HistoryMessage> = [] // evictedUsers user should be also concat
    ): Promise<Array<ResolutionMessage>> {
        const result = await pubNub.history({
            channel: `${PubNubChannels.ResolutionMessage}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            count: 100,
            end: timeToken ? undefined : timeToken,
            start: timeToken ? timeToken : undefined,
            reverse: timeToken ? false : false,
        });
        const evictedUsers = await pubNub.history({
            channel: `${PubNubChannels.Evicted}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            count: 100,
            end: timeToken ? undefined : timeToken,
            start: timeToken ? timeToken : undefined,
            reverse: timeToken ? false : false,
        });
        const filteredEvictedUsers = evictedUsers.messages.filter(
            (x) => x.entry.resolutionId === resolutionId
        );
        // contact allEvictedUsers
        allEvictedUsers = [...allEvictedUsers, ...filteredEvictedUsers];
        const resolutionMessages = result.messages
            .filter(
                (x) =>
                    x.entry.resolutionId === resolutionId &&
                    !allEvictedUsers.some(
                        (e) =>
                            e.entry.auth0Id === x.entry.auth0Id &&
                            e.timetoken !== undefined &&
                            x.timetoken !== undefined &&
                            e.timetoken > x.timetoken
                    )
            )
            .map((x) => x.entry);

        // if messages are less then 100, so break the loop
        if (result.messages && result.messages.length < 100) return resolutionMessages;
        // if endTimeToken is not passed then it is current resolution, break loop when no messages found
        if ((!endTimeToken && resolutionMessages.length === 0)) return [];
        // if endTimeToken is passed then it is old resolution, break loop when no messages in actual channel
        if (endTimeToken && timeToken && endTimeToken > timeToken) return resolutionMessages;
        // call history for next time frame
        return resolutionMessages.concat(
            await PubNubHelper.GetPeopleInResolution(
                pubNub,
                resolutionId,
                userData,
                result.startTimeToken,
                endTimeToken,
                allEvictedUsers
            )
        );
    }

    public static IsMyHandRaised(
        pubNub: PubNub,
        userData: User,
        timeToken: string | number | undefined
    ): Promise<HandRaised | undefined> {
        const result = pubNub.history({
            channel: `${PubNubChannels.ResolutionMessage}-${userData.auth0Id}`,
            count: 100,
            end: timeToken,
        });

        return result.then(
            (response) => {
                if (!response) return undefined;
                const currentQueueMessages = response.messages.filter(
                    (x) =>
                        (x.entry.userId === userData.id && x.entry.action === "Join") ||
                        x.entry.action === "Leave"
                );
                // If I raised my hand, finish
                if (currentQueueMessages.length > 0) {
                    const data = {
                        message:
                            currentQueueMessages[currentQueueMessages.length - 1].entry,
                        timeToken:
                            currentQueueMessages[currentQueueMessages.length - 1].timetoken,
                    };

                    return data;
                }
                // If we pulled the maximum number of messages and there is an end time token
                if (response.messages.length === 100) {
                    const time = new Date((response.endTimeToken as number) / 10000);
                    // have I gont back more than 10 minutes?
                    if (Date.now() - ((time as unknown) as number) > 10 * 60 * 1000) {
                        return undefined;
                    }
                    return PubNubHelper.IsMyHandRaised(
                        pubNub,
                        userData,
                        response.endTimeToken
                    );
                }
                return undefined;
            },
            // Rejection triggered when PubNub provided before userData initialized
            (rejection) => {
                return new Promise<HandRaised>(function (resolve) {
                    resolve(undefined);
                });
            }
        );
    }

    public static WasIEvicted(
        pubNub: PubNub,
        userData: User
    ): Promise<string | number | undefined> {
        const result = pubNub.history({
            channel: `${PubNubChannels.Evicted}-${userData.auth0Id}`,
            count: 100,
        });

        return result.then(
            (response) => {
                if (response && response.messages.length > 0) {
                    const myEvictions = response.messages.filter(
                        (x) => x.entry.userId === userData.id
                    );
                    // return most recent eviction
                    if (myEvictions.length > 0) {
                        return myEvictions[myEvictions.length - 1].timetoken;
                    }
                }
                return new Promise<string | number | undefined>(function (resolve) {
                    resolve(undefined);
                });
            },
            (rejection) => {
                return new Promise<string | number | undefined>(function (resolve) {
                    resolve(undefined);
                });
            }
        );
    }

    public static AmIInTheStageQueue(
        pubNub: PubNub,
        userData: User
    ): Promise<string | number | undefined | any> {
        const result = pubNub.history({
            channel: `${PubNubChannels.ResolutionMessage}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            count: 100,
        });

        return result.then(
            (response) => {
                if (response && response.messages.length > 0) {
                    const myStages = response.messages.filter(
                        (x) => x.entry.userId === userData.id
                    );
                    const movedMessages = myStages.filter(
                        (x) => x.entry.action === "Move"
                    );
                    //most recent stage queue
                    if (movedMessages.length > 0) {
                        return movedMessages[movedMessages.length - 1].timetoken;
                    }
                }
                return new Promise<string | number | undefined | any>(function (
                    resolve
                ) {
                    resolve(undefined);
                });
            },
            (rejection) => {
                return new Promise<string | number | undefined>(function (resolve) {
                    resolve(undefined);
                });
            }
        );
    }

    public static GetLastSpeaker(
        pubNub: PubNub,
        userData: User
    ): Promise<string | number | undefined> {
        const result = pubNub.history({
            channel: `${PubNubChannels.VirtualCallCenter}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            count: 100,
        });

        return result.then(
            (response) => {
                if (response && response.messages.length > 0) {
                    const currentCall = response.messages.filter(
                        (x) =>
                            x.entry.messageType === "UpdateCallStatus" &&
                            x.entry.action === "Speaking"
                    );
                    // return most recent speaker
                    if (currentCall.length > 0) {
                        return currentCall[currentCall.length - 1].entry;
                    }
                }
                return new Promise<string | number | undefined>(function (resolve) {
                    resolve(undefined);
                });
            },
            (rejection) => {
                return new Promise<string | number | undefined>(function (resolve) {
                    resolve(undefined);
                });
            }
        );
    }

    public static DidIVote(
        pubNub: PubNub,
        userData: User,
        voteId: string,
        timeToken: string | number | undefined
    ): Promise<Vote> {
        const result = pubNub.history({
            channel: `${PubNubChannels.VoteBallot}-${userData.auth0Id}`,
            count: 100,
            end: timeToken ? undefined : timeToken,
            start: timeToken ? timeToken : undefined,
            reverse: timeToken ? false : false,
        });

        return result.then(
            (response) => {
                if (!response) return undefined;
                // Filter votes for current poll
                const currentVoteMessages = response.messages.filter(
                    (x) => x.entry.pollId === voteId
                );
                // Filter my votes
                if (currentVoteMessages.length > 0) {
                    const myVotes = currentVoteMessages.filter(
                        (x) => x.entry.userId === userData.id
                    );
                    if (myVotes.length > 0) {
                        return myVotes[myVotes.length - 1].entry;
                    }
                    // Recursively check until we move to previous poll
                    return this.DidIVote(pubNub, userData, voteId, response.endTimeToken);
                }
                return undefined;
            },
            // Rejection triggered when PubNub provided before userData initialized
            (rejection) => {
                return new Promise<Vote>(function (resolve) {
                    resolve(undefined);
                });
            }
        );
    }

    public static async GetAllVotes(
        pubNub: PubNub,
        userData: User,
        voteId: string,
        timeToken: string | number | undefined
    ): Promise<Array<Vote>> {

        const result: Pubnub.HistoryResponse = await pubNub.history({
            channel: `${PubNubChannels.VoteBallot}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            count: 100,
            start: timeToken ? timeToken : undefined,
        });

        const currentVoteMessages = result.messages.filter(
            (x) => x.entry.pollId === voteId
        );
        const sortedVotes = (currentVoteMessages || []).sort(
            (a, b) => (a.timetoken as number) - (b.timetoken as number)
        ).map((x) => x.entry);

        if ((sortedVotes || []).length < 100) return sortedVotes;
        return sortedVotes.concat(await PubNubHelper.GetAllVotes(pubNub, userData, voteId, result.startTimeToken));
    }

    public static EvictUser(pubNub: PubNub, message: ResolutionMessage): void {
        const pnMessage = new ResolutionMessage(
            message,
            "RequestToSpeakMessage",
            message.resolutionId,
            "Evicted",
            message.messageType,
            message.queue
        );
        pubNub.publish({
            message: pnMessage,
            channel: `${PubNubChannels.Evicted}-${message.eventId}${this.appendSessionIdFromURL()}`,
        });
        // Publish to private channel
        pubNub.publish({
            message: pnMessage,
            channel: `${PubNubChannels.Evicted}-${message.auth0Id}`,
        });
    }

    public static LeaveQueue(
        pubNub: PubNub,
        userData: User,
        resolutionId: string,
        channel: string
    ): ResolutionMessage {
        const pnMessage = new ResolutionMessage(
            userData,
            "RequestToSpeakMessage",
            resolutionId,
            "Leave",
            channel,
            channel
        );
        pubNub.publish({
            message: pnMessage,
            channel: `${PubNubChannels.ResolutionMessage}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        });
        // Publish to private channel
        pubNub.publish({
            message: pnMessage,
            channel: `${PubNubChannels.ResolutionMessage}-${userData.auth0Id}`,
        });

        return pnMessage;
    }

    public static ChangeColor(
        pubNub: PubNub,
        message: ResolutionMessage,
        color: any,
        podiumNumber: any
    ): void {
        const pnMessage = new ResolutionMessage(
            message,
            "RequestToSpeakMessage",
            message.resolutionId,
            "ChangeColor",
            message.messageType,
            message.queue,
            color.hex,
            podiumNumber
        );
        pubNub.publish({
            message: pnMessage,
            channel: `${PubNubChannels.ResolutionMessage}-${message.eventId}${this.appendSessionIdFromURL()}`,
        });
    }

    public static ChangeLock(
        pubNub: PubNub,
        message: ResolutionMessage,
        isLock: boolean,
        podiumNumber: any
    ): void {
        const pnMessage = new ResolutionMessage(
            message,
            "RequestToSpeakMessage",
            message.resolutionId,
            "LockUserCard",
            message.messageType,
            message.queue,
            'f5f5f5',
            podiumNumber
        );
        const lockMessage = { ...pnMessage, isLock: isLock };
        pubNub.publish({
            message: lockMessage,
            channel: `${PubNubChannels.ResolutionMessage}-${message.eventId}${this.appendSessionIdFromURL()}`,
        });
    }

    public static ChangePodiumNumber(
        pubNub: PubNub,
        message: ResolutionMessage,
        podiumNumber: any,
        callStatus: string | undefined
    ): void {
        const pnMessage = new ResolutionMessage(
            message,
            "RequestToSpeakMessage",
            message.resolutionId,
            "ChangePodiumNumber",
            message.messageType,
            message.queue,
            callStatus,
            podiumNumber
        );
        pubNub.publish({
            message: pnMessage,
            channel: `${PubNubChannels.ResolutionMessage}-${message.eventId}${this.appendSessionIdFromURL()}`,
        });
    }

    public static SetCallState(
        pubNub: PubNub,
        userData: any,
        action: MessageActions,
        state: string | undefined,
        slot: string | undefined,
        phoneNumber: string | undefined,
        podiumNumber: string | undefined,
        timeToken: number | undefined
    ): void {
        const pnMessage = new CallMessage(
            userData,
            action,
            slot,
            phoneNumber,
            state,
            podiumNumber,
            timeToken
        );
        console.log("Setting call state (userData, action, slot, phoneNumber, state)", userData, action, slot, phoneNumber, state);
        if (slot) pnMessage.slot = slot;
        pubNub.publish({
            message: pnMessage,
            channel: `${PubNubChannels.UserCallStatus}-${userData.auth0Id}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        })

        pubNub.publish({
            message: pnMessage,
            channel: `${PubNubChannels.VirtualCallCenter}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        });
    }

    public static SetTextState(
        pubNub: PubNub,
        userData: any,
        message: ResolutionMessage,
        action: MessageActions,
        state: string | undefined,
        slot: string | undefined,
        fromPhone: string | undefined,
        toPhone: string | undefined,
        delegateUserId: number | undefined,
        podiumNumber: string | undefined
    ): void {
        const pnMessage = new TextMessage(
            message, //userData
            message,
            action,
            slot,
            fromPhone,
            toPhone,
            delegateUserId,
            state,
            podiumNumber
        );
        console.log("Setting text state (userData, action, slot, message, fromPhone, toPhone, state, podiumNumber)", userData, action, slot, message, fromPhone, toPhone, state, podiumNumber);
        if (slot) pnMessage.slot = slot;
        pubNub.publish({
            message: pnMessage,
            channel: `${PubNubChannels.UserTextStatus}-${slot}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        });

        // publish the podium number to the color channel so the President's View can see it (ChangeColor)
        // pubNub.publish({
        //     message: pnMessage,
        //     channel:`${PubNubChannels.UserCallStatus}-${userData.auth0Id}-${message.eventId}${this.appendSessionIdFromURL()}`
        // });

        PubNubHelper.ChangePodiumNumber(pubNub, message, podiumNumber, state);
    }

    public static GetUserTextStatus(
        pubNub: PubNub,
        slot: string,
        eventId: string
    ): Promise<TextMessage> {
        const result = pubNub.history({
            channel: `${PubNubChannels.UserTextStatus}-${slot}-${eventId}${this.appendSessionIdFromURL()}`,
            count: 1,
        });
        return result.then(
            (response) => {
                if (response && response.messages.length > 0)
                    return response.messages[0].entry;
                return undefined;
            },
            (rejection) => {
                return new Promise<TextMessage | undefined>(function (resolve) {
                    resolve(undefined);
                });
            }
        );
    }

    public static GetUserCallStatus(
        pubNub: PubNub,
        userAuth0Id: string,
        eventId: string
    ): Promise<QueueMessage> {
        const result = pubNub.history({
            channel: `${PubNubChannels.UserCallStatus}-${userAuth0Id}-${eventId}${this.appendSessionIdFromURL()}`,
            count: 1,
        });
        return result.then(
            (response) => {
                if (response && response.messages.length > 0)
                    return response.messages[0].entry;
                return undefined;
            },
            // Rejection triggered when PubNub provided before userData initialized
            (rejection) => {
                return new Promise<QueueMessage | undefined>(function (resolve) {
                    resolve(undefined);
                });
            }
        );
    }
    public static CastVote(
        pubNub: PubNub,
        userData: User,
        VoteQueue: any,
        voteChoice: string,
        voteWeight: number
    ): void {
        const pnMessage = new Vote(
            userData,
            "CastVoteMessage",
            VoteQueue.description,
            VoteQueue.id,
            voteChoice,
            true,
            voteChoice === "Yes",
            VoteQueue.resolutionNumber,
            voteWeight || 1
        );
        pubNub.publish({
            message: pnMessage,
            channel: `${PubNubChannels.VoteBallot}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        });
        // Publish to private channel
        pubNub.publish({
            message: pnMessage,
            channel: `${PubNubChannels.VoteBallot}-${userData.auth0Id}`,
        });
    }

    public static StartVote(
        pubNub: PubNub,
        userData: User,
        voteId: string,
        voteDescription: string,
        enabledVoteWeight: boolean
    ): string {
        const pnMessage = new QueueMessage(
            userData,
            MessageActions.InitiateVote,
            voteDescription,
            voteId,
            MessageActions.InitiateVote,
            enabledVoteWeight,
            null, null
        );
        pubNub.publish({
            message: pnMessage,
            channel: `${PubNubChannels.VoteAdmin}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        });
        return pnMessage.id;
    }

    public static EndVote(
        pubNub: PubNub,
        userData: User,
        voteId: string,
        voteDescription: string
    ): Promise<Pubnub.PublishResponse> {
        const pnMessage = new QueueMessage(
            userData,
            MessageActions.EndVote,
            voteDescription,
            voteId,
            MessageActions.EndVote,
            null, null, null
        );
        return pubNub.publish({
            message: pnMessage,
            channel: `${PubNubChannels.VoteAdmin}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        });
    }

    public static BroadcastVoteResults(
        pubNub: PubNub,
        userData: User,
        message: any
    ): void {
        pubNub.publish({
            message: message,
            // FIXME - Change channel
            channel: `${PubNubChannels.VoteAdmin}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        });
    }

    public static JoinQueue(
        pubNub: PubNub,
        userData: User,
        resolutionId: string,
        channel: string
    ): ResolutionMessage {
        const pnMessage = new ResolutionMessage(
            userData,
            "RequestToSpeakMessage",
            resolutionId,
            "Join",
            channel,
            channel,
            "f5f5f5"
        );
        pubNub.publish({
            message: pnMessage,
            channel: `${PubNubChannels.ResolutionMessage}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        });
        // Publish to private channel
        pubNub.publish({
            message: pnMessage,
            channel: `${PubNubChannels.ResolutionMessage}-${userData.auth0Id}`,
        });

        return pnMessage;
    }

    public static BeginQueue(
        pubNub: PubNub,
        userData: User,
        resolutionId: string
    ): QueueMessage {
        const pnMessage = new QueueMessage(
            userData,
            "ResolutionQueue",
            "",
            resolutionId,
            "Create",
            null, null, ''
        );
        pubNub.publish({
            message: pnMessage,
            channel: `${PubNubChannels.NewResolution}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        });

        return pnMessage;
    }

    public static EndQueue(
        pubNub: PubNub,
        userData: User,
        resolutionId: string,
        closingResolutionId: string
    ): QueueMessage {
        const pnMessage = new QueueMessage(
            userData,
            "ResolutionQueue",
            "",
            resolutionId,
            "Clear",
            undefined,
            closingResolutionId,
            ''
        );
        pubNub.publish({
            message: pnMessage,
            channel: `${PubNubChannels.NewResolution}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        });

        return pnMessage;
    }

    public static PauseAndClear(
        pubNub: PubNub,
        resolutionMessage: any,
        lockedUserIds: string[]
    ): QueueMessage {
        const pnMessage = { ...resolutionMessage, action: 'PauseAndClear', pauseAndClear: true, lockedUserIds: lockedUserIds };
        pubNub.publish({
            message: pnMessage,
            channel: `${PubNubChannels.NewResolution}-${resolutionMessage.eventId}${this.appendSessionIdFromURL()}`,
        });

        return pnMessage;
    }

    public static MoveCard(pubNub: PubNub, message: any, index: number): void {
        const pnMessage = Object.assign({}, message);
        pnMessage.isCalled = true;
        pnMessage.newIndex = index;
        pubNub.publish({
            message: pnMessage,
            channel: `${PubNubChannels.ResolutionMessage}-${message.eventId}${this.appendSessionIdFromURL()}`,
        });
    }

    public static ReOrderQueue(
        pubNub: PubNub,
        userData: User,
        resolutionId: string,
        queue: string,
        slot: number,
        auth0IdSequence: string[]
    ): void {
        const pnMessage = new QueueReorderMessage(
            userData,
            MessageActions.QueueReorder,
            resolutionId,
            queue,
            slot,
            auth0IdSequence
        );
        console.log(pnMessage, 'ReOrderQueue');
        pubNub.publish({
            message: pnMessage,
            channel: `${PubNubChannels.ResolutionMessage}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        });
    }

    // Subscribe and Unsubscribe Helerps
    public static DelegateVoteListenerSubscribe(
        pubNub: PubNub,
        userData: User,
        pnListener: Pubnub.ListenerParameters
    ): void {
        const channels = [`${PubNubChannels.VoteAdmin}-${userData.eventId}${this.appendSessionIdFromURL()}`];
        pubNub.addListener(pnListener);
        pubNub.subscribe({ channels: channels, withPresence: true });
    }

    public static DelegateVoteListenerUnsubscribe(
        pubNub: PubNub,
        userData: User,
        pnListener: Pubnub.ListenerParameters
    ): void {
        const channels = [`${PubNubChannels.VoteAdmin}-${userData.eventId}${this.appendSessionIdFromURL()}`];
        pubNub.removeListener(pnListener);
        pubNub.unsubscribe({ channels: channels });
    }

    public static DelegateResolutionListenerSubscribe(
        pubNub: PubNub,
        userData: User,
        pnListener: Pubnub.ListenerParameters
    ): void {
        const channels = [
            `${PubNubChannels.NewResolution}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            `${PubNubChannels.Evicted}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            `${PubNubChannels.UserCallStatus}-${userData.auth0Id}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        ];
        pubNub.addListener(pnListener);
        pubNub.subscribe({ channels: channels, withPresence: true });
    }

    public static DelegateResolutionListenerUnsubscribe(
        pubNub: PubNub,
        userData: User,
        pnListener: Pubnub.ListenerParameters
    ): void {
        const channels = [
            `${PubNubChannels.NewResolution}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            `${PubNubChannels.Evicted}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            `${PubNubChannels.UserCallStatus}-${userData.auth0Id}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        ];
        pubNub.removeListener(pnListener);
        pubNub.unsubscribe({ channels: channels });
    }

    public static WaitingRoomSubscribe(
        pubNub: PubNub,
        userData: User,
        pnListener: Pubnub.ListenerParameters
    ): void {
        const channels = [
            `${PubNubChannels.WaitingRoom}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            `${PubNubChannels.WaitingRoom}-${userData.auth0Id}`,
        ];
        pubNub.addListener(pnListener);
        pubNub.subscribe({ channels: channels, withPresence: true });

        pubNub.addListener(pnListener);
        pubNub.subscribe({ channels: channels, withPresence: true });
    }

    public static WaitingRoomUnsubscribe(
        pubNub: PubNub,
        userData: User,
        pnListener: Pubnub.ListenerParameters
    ): void {
        const channels = [
            `${PubNubChannels.WaitingRoom}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            `${PubNubChannels.WaitingRoom}-${userData.auth0Id}`,
        ];
        pubNub.removeListener(pnListener);
        pubNub.unsubscribe({ channels: channels });
    }


    public static ModeratorResolutionListenerSubscribe(
        pubNub: PubNub,
        userData: User,
        pnListener: Pubnub.ListenerParameters
    ): void {
        const channels = [
            `${PubNubChannels.NewResolution}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            `${PubNubChannels.Evicted}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            `${PubNubChannels.ResolutionMessage}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        ];
        pubNub.addListener(pnListener);
        pubNub.subscribe({ channels: channels });
    }

    public static ModeratorResolutionListenerUnsubscribe(
        pubNub: PubNub,
        userData: User,
        pnListener: Pubnub.ListenerParameters
    ): void {
        const channels = [
            `${PubNubChannels.NewResolution}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            `${PubNubChannels.Evicted}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            `${PubNubChannels.ResolutionMessage}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        ];
        pubNub.removeListener(pnListener);
        pubNub.unsubscribe({ channels: channels });
    }

    // Use for pause queue button in moderator UI
    public static ModeratorResolutionListenerUnsubscribeToRequestsToSpeak(
        pubNub: PubNub,
        userData: User,
        pnListener: Pubnub.ListenerParameters
    ): void {
        const channels = [
            `${PubNubChannels.ResolutionMessage}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        ];
        pubNub.removeListener(pnListener);
        pubNub.unsubscribe({ channels: channels });
    }

    // Use for the resume queue button in moderator UI
    public static ModeratorResolutionListenerSubscribeToRequestsToSpeak(
        pubNub: PubNub,
        userData: User,
        pnListener: Pubnub.ListenerParameters
    ): void {
        const channels = [
            `${PubNubChannels.ResolutionMessage}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        ];
        pubNub.addListener(pnListener);
        pubNub.subscribe({ channels: channels });
    }

    public static ModeratorCallListenerSubscribe(
        pubNub: PubNub,
        userData: User,
        pnListener: Pubnub.ListenerParameters
    ): void {
        const channels = [
            `${PubNubChannels.VirtualCallCenter}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            `${PubNubChannels.UserCallStatus}-${userData.auth0Id}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        ];
        pubNub.addListener(pnListener);
        pubNub.subscribe({ channels: channels });
    }

    public static ModeratorCallListenerUnsubscribe(
        pubNub: PubNub,
        userData: User,
        pnListener: Pubnub.ListenerParameters
    ): void {
        const channels = [
            `${PubNubChannels.VirtualCallCenter}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            `${PubNubChannels.UserCallStatus}-${userData.auth0Id}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        ];
        pubNub.removeListener(pnListener);
        pubNub.unsubscribe({ channels: channels });
    }

    public static ModeratorVoteListenerSubscribe(
        pubNub: PubNub,
        userData: User,
        pnListener: Pubnub.ListenerParameters
    ): void {
        const channels = [`${PubNubChannels.VoteBallot}-${userData.eventId}${this.appendSessionIdFromURL()}`];
        pubNub.addListener(pnListener);
        pubNub.subscribe({ channels: channels });
    }

    public static ModeratorVoteListenerUnsubscribe(
        pubNub: PubNub,
        userData: User,
        pnListener: Pubnub.ListenerParameters
    ): void {
        const channels = [`${PubNubChannels.VoteBallot}-${userData.eventId}${this.appendSessionIdFromURL()}`];
        pubNub.removeListener(pnListener);
        pubNub.unsubscribe({ channels: channels });
    }

    public static OperatorListenerSubscribe(
        pubNub: PubNub,
        userData: User,
        pnListener: Pubnub.ListenerParameters
    ): void {
        const channels = [
            `${PubNubChannels.NewResolution}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            `${PubNubChannels.VirtualCallCenter}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        ];
        pubNub.addListener(pnListener);
        pubNub.subscribe({ channels: channels });
    }

    public static OperatorListenerUnsubscribe(
        pubNub: PubNub,
        userData: User,
        pnListener: Pubnub.ListenerParameters
    ): void {
        const channels = [
            `${PubNubChannels.NewResolution}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            `${PubNubChannels.VirtualCallCenter}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        ];
        pubNub.removeListener(pnListener);
        pubNub.unsubscribe({ channels: channels });
    }

    public static PresidentListenerSubscribe(
        pubNub: PubNub,
        userData: User,
        pnListener: Pubnub.ListenerParameters[]
    ): void {
        const channels = [
            `${PubNubChannels.NewResolution}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            `${PubNubChannels.VirtualCallCenter}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            `${PubNubChannels.ResolutionMessage}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            `${PubNubChannels.Evicted}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            `${PubNubChannels.UserCallStatus}-${userData.auth0Id}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        ];
        pnListener.forEach(element => {
            pubNub.addListener(element);
        });

        pubNub.subscribe({ channels: channels });
    }

    public static PresidentListenerUnsubscribe(
        pubNub: PubNub,
        userData: User,
        pnListener: Pubnub.ListenerParameters[]
    ): void {
        const channels = [
            `${PubNubChannels.NewResolution}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            `${PubNubChannels.VirtualCallCenter}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            `${PubNubChannels.ResolutionMessage}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            `${PubNubChannels.Evicted}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            `${PubNubChannels.UserCallStatus}-${userData.auth0Id}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        ];
        pnListener.forEach(ele => {
            pubNub.removeListener(ele);
        });
        pubNub.unsubscribe({ channels: channels });
    }

    public static BroadcastListenerSubscribe(
        pubNub: PubNub,
        userData: User,
        pnListener: Pubnub.ListenerParameters
    ): void {
        const channels = [
            `${PubNubChannels.VirtualCallCenter}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        ];
        pubNub.addListener(pnListener);
        pubNub.subscribe({ channels: channels });
    }

    public static BroadcastListenerUnsubscribe(
        pubNub: PubNub,
        userData: User,
        pnListener: Pubnub.ListenerParameters
    ): void {
        const channels = [
            `${PubNubChannels.VirtualCallCenter}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        ];
        pubNub.removeListener(pnListener);
        pubNub.unsubscribe({ channels: channels });
    }

    public static VoteBroadcastListenerSubscribe(
        pubNub: PubNub,
        userData: User,
        pnListener: Pubnub.ListenerParameters
    ): void {
        const channels = [
            `${PubNubChannels.VoteAdmin}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            `${PubNubChannels.VoteBallot}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        ];
        pubNub.addListener(pnListener);
        pubNub.subscribe({ channels: channels });
    }

    public static VoteBroadcastListenerUnsubscribe(
        pubNub: PubNub,
        userData: User,
        pnListener: Pubnub.ListenerParameters
    ): void {
        const channels = [
            `${PubNubChannels.VoteAdmin}-${userData.eventId}${this.appendSessionIdFromURL()}`,
            `${PubNubChannels.VoteBallot}-${userData.eventId}${this.appendSessionIdFromURL()}`,
        ];
        pubNub.removeListener(pnListener);
        pubNub.unsubscribe({ channels: channels });
    }

    public static StartVoteListenerSubscribe(
        pubNub: PubNub,
        userData: User,
        pnListener: Pubnub.ListenerParameters
    ): void {
        const channels = [`${PubNubChannels.VoteAdmin}-${userData.eventId}${this.appendSessionIdFromURL()}`];
        pubNub.addListener(pnListener);
        pubNub.subscribe({ channels: channels });
    }

    public static StartVoteListenerUnsubscribe(
        pubNub: PubNub,
        userData: User,
        pnListener: Pubnub.ListenerParameters
    ): void {
        const channels = [`${PubNubChannels.VoteAdmin}-${userData.eventId}${this.appendSessionIdFromURL()}`];
        pubNub.removeListener(pnListener);
        pubNub.unsubscribe({ channels: channels });
    }

    public static TextOperatorListenerSubscribe(
        pubNub: PubNub,
        slot: string,
        sessionString: string,
        userData: User,
        pnListener: Pubnub.ListenerParameters
    ): void {
        const channels = [`${PubNubChannels.UserTextStatus}-TextStage${slot}-${userData.eventId}${sessionString}`];
        pubNub.addListener(pnListener);
        pubNub.subscribe({ channels: channels });
    }

    public static TextOperatorListenerUnsubscribe(
        pubNub: PubNub,
        slot: string,
        sessionString: string,
        userData: User,
        pnListener: Pubnub.ListenerParameters
    ): void {
        const channels = [`${PubNubChannels.UserTextStatus}-TextStage${slot}-${userData.eventId}${sessionString}`];
        pubNub.removeListener(pnListener);
        pubNub.unsubscribe({ channels: channels });
    }

    public static TextOperatorResponseListenerSubscribe(
        pubNub: PubNub,
        slot: string,
        sessionString: string,
        userData: User,
        pnListener: Pubnub.ListenerParameters
    ): void {
        const channels = [`${PubNubChannels.TextLineMessageReturn}-${userData.eventId}-${slot}${sessionString}`];
        pubNub.addListener(pnListener);
        pubNub.subscribe({ channels: channels });
    }

    public static TextOperatorListenerResponseUnsubscribe(
        pubNub: PubNub,
        slot: string,
        sessionString: string,
        userData: User,
        pnListener: Pubnub.ListenerParameters
    ): void {
        const channels = [`${PubNubChannels.TextLineMessageReturn}-${userData.eventId}-${slot}${sessionString}`];
        pubNub.removeListener(pnListener);
        pubNub.unsubscribe({ channels: channels });
    }
}
