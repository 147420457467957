import PubNubMessageBase from "./PubNubMessageBase";

class Vote extends PubNubMessageBase {
  constructor(
    user,
    messageType,
    voteDescription,
    pollId,
    voteSelection,
    voteActive,
    voteBool,
    resolutionNumber,
    voteWeight
  ) {
    super(user, messageType);
    this.voteDescription = voteDescription;
    this.pollId = pollId;
    this.voteSelection = voteSelection;
    this.voteActive = voteActive;
    this.voteBool = voteBool;
    this.resolutionNumber = resolutionNumber;
    this.voteWeight = voteWeight;
  }
}

export default Vote;
