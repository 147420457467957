import "./styles.css";

import { Hidden, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";

import { ConfigurationContext } from "../../infrastructure/ConfigurationContext";
import { Helmet } from "react-helmet";
import { SessionConfigurationContext } from "../../infrastructure/SessionConfigurationContext";
import Switch from "@material-ui/core/Switch"
import moment from "moment";
import { withStyles } from '@material-ui/core/styles';

const WhiteSwitch = withStyles({
  switchBase: {
    color: "#fff",
    '&:not($checked) + $track': {
      backgroundColor: "#fff",
    },
    '&$checked': {
      color: "#fff",
    },
    '&$checked + $track': {
      backgroundColor: "#fff",
    },
  },
  checked: {},
  track: {},
})(Switch);

const VideoStream = () => {
  const activeConfig = useContext(ConfigurationContext);
  const sessionConfig = useContext(SessionConfigurationContext);
  const [streamTextUrl, setStreamTextUrl] = useState(null);
  const [textStreamingOn, setTextStreamingOn] = useState(false);
  useEffect(() => {
    let textUrl = sessionConfig && sessionConfig.configData ? sessionConfig.configData.streamTextUrl : activeConfig.configData.streamTextUrl;
    textUrl = textUrl ? textUrl.trim() : null;
    if (textUrl) {
      if (textUrl.indexOf('https://www.streamtext.net') !== -1) {
        // www.streamtext.net has some padding, so iframe is getting horizontal scroll, which is not look good on our page.
        // if streamtext and does not have any content style then add overflow hidden, otherwise it will be considered that overflow is mentioned in url.
        if (textUrl.indexOf('content-style') === -1) {
          textUrl = textUrl + '&content-style=overflow:hidden';
        }
      }
      setTextStreamingOn(getCookie('streamTextOn') === 'true');
      setStreamTextUrl(textUrl);
    }
  }, [activeConfig, sessionConfig]);
  const setCookie = (cname, cvalue, exdays) => {
    let d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  const getCookie = (cname) => {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  const toggleStreaming = () => {
    setCookie('streamTextOn', !textStreamingOn, 365);
    setTextStreamingOn(!textStreamingOn);
  }
  return (
    <div className="video-stream-container">
      <Helmet>
        <style>
          {`/* Customization css style */
            .theo-primary-color,
            .vjs-selected {
                color: #0066b3 !important;
            }

            .theo-primary-background {
                color: #000000 !important;
                background-color: #0066b3 !important;
            }

            .theo-tertiary-color {
                color: #000000 !important;
            }

            .vjs-fullscreen-control {
                display: none !important;
            }
            
            .theoplayer-skin li[aria-label="Open the video speed settings menu"] {
                display: none !important;
            }
            
            .on-off-control {
              color: white !important;
              cursor: pointer;
              background-color: black !important;
              padding-top: 26%;
              padding-bottom: 26%;
            }
            .caption-placeholder {
              color: white !important;
              background-color: black !important;
            }
          `}
        </style>
        <script type="text/javascript">
          {`
          function setCookie(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            var expires = "expires="+d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
          }
          
          function getCookie(cname) {
            var name = cname + "=";
            var ca = document.cookie.split(';');
            for(var i = 0; i < ca.length; i++) {
              var c = ca[i];
              while (c.charAt(0) == ' ') {
                c = c.substring(1);
              }
              if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
              }
            }
            return "";
          }
          
                var theodiv = document.querySelector(".theoplayer-container");
                var onOffControl = document.getElementById("on-off-control");

                var player = null;
                function startStreamingVideo() {
                  theodiv.style.display = "block";
                  player = new THEOplayer.Player(theodiv, {
                    libraryLocation: "https://cdn.myth.theoplayer.com/358aeeb3-d6b9-46aa-942a-e46a90732a34",
                    license : 'sZP7IYe6T6P13lao3LPl06kK0lBzFSacCKa-TS31Tmzk0uPe3lP1IKaoTS56FOPlUY3zWokgbgjNIOf9flfZ0S110u41FD4eCoB-3uIg36z_3DxeFSfZ3KhLTS0_TSIlC6fVfK4_bQgZCYxNWoryIQXzImf90SCk0L0i0u5i0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3la_0ubcTu0rTuekFOPeWok1dDrLYtA1Ioh6TgV6v6fVfKcqCoXVdQjLUOfVfGxEIDjiWQXrIYfpCoj-fgzVfKxqWDXNWG3ybojkbK3gflNWf6E6FOPVWo31WQ1qbta6FOPzdQ4qbQc1sD4ZFK3qWmPUFOPLIQ-LflNWfK1zWDikfgzVfG3gWKxydDkibK4LbogqW6f9UwPkIYz'
                  });
                  // OPTIONAL CONFIGURATION
                  // Customized video player parameters
                  player.source = {
                      sources: [{
                        "src": "${sessionConfig && sessionConfig.configData ? sessionConfig.configData.streamUrl : activeConfig.configData.streamUrl}",
                          "type": "application/x-mpegurl",
                          "lowLatency": "true"
                      }]
                  };
                  // setting up the custom icon by setting up a video-js component
                  var Button = THEOplayer.videojs.getComponent('Button');
                  var TurnOffButton = THEOplayer.videojs.extend(Button, {
                    constructor: function() {
                      Button.apply(this, arguments); // required
                      
                      /* Extra after initializing your button */
                      
                      // add tooltip
                      var tooltipSpan = document.createElement('span');
                      tooltipSpan.className = 'theo-button-tooltip vjs-hidden';
                      tooltipSpan.innerText = 'Turn off';
                      function toggleTooltip() {
                          tooltipSpan.classList.toggle('vjs-hidden');
                      }
                      this.el().addEventListener('mouseover', toggleTooltip);
                      this.el().addEventListener('mouseout', toggleTooltip);
                      this.el().appendChild(tooltipSpan);
                      
                    },
                    handleClick: function() {
                      stopStreamingVideo();
                    },
                    buildCSSClass: function () {
                      return 'fa fa-power-off custom-info-icon vjs-control vjs-button'; // insert all class names here
                    }
                  });

                  THEOplayer.videojs.registerComponent('TurnOffButton', TurnOffButton);
                  player.ui.getChild('controlBar').addChild('turnOffButton', {});

                  player.addEventListener('play', function(e) {
                    console.log('playing event detected!');

                    //let item = document.getElementsByClassName("theo-live-control")[0];
                    //item.click();
                    document.getElementsByClassName('vjs-play-control')[0].style.display = 'none';
                  });

                  player.autoplay = true;
                  player.preload = 'auto';
                  player.playbackRate = 1;

                  
                  setCookie('videoOn', true, 365);
                  onOffControl.style.display = "none";
                }
                function stopStreamingVideo() {
                  if (player) {
                    player.destroy();
                  }
                  setCookie('videoOn', false, 365);
                  onOffControl.style.display = "block";
                  theodiv.style.display = "none";
                }
                onOffControl.addEventListener("click", function () {
                  startStreamingVideo();
                });
                // start player function to call on page load and awake
                function startPlayerOnPageLoad() {
                  if (getCookie('videoOn') !== 'false') {
                    startStreamingVideo();
                  } else {
                    onOffControl.style.display = "block";
                    theodiv.style.display = "none";
                  }
                }
                // start player
                startPlayerOnPageLoad();
                // iosWakeDetect Bug fix 240868
                // iosWakeDetect reference https://stackoverflow.com/a/35215953/1660178
                var lastTime;
                function requestAnimationFrameCallback() {
                  webkitRequestAnimationFrame(function() {
                    var thisTime = (new Date).getTime();
                    if (lastTime && (thisTime - lastTime) > 20000) {
                      setTimeout(pageAwakenedReloadPlayer, 0);
                    }
                    lastTime = thisTime;
                    requestAnimationFrameCallback();
                  });
                }
                // ios specific fix for reload player
                if (/^iPhone|^iPad|^iPod/.test(navigator.platform) && !window.indexedDB && window.webkitRequestAnimationFrame) {
                  requestAnimationFrameCallback();
                }
                // mobile/tablet device reload player
                var isReloadingPlayer = false; // To remove duplicate event happen
                function pageAwakenedReloadPlayer() {
                  if (isReloadingPlayer) return;
                  isReloadingPlayer = true;
                  // destroy player
                  if (player) {
                    player.destroy();
                  }
                  // start player with check turn on/off
                  startPlayerOnPageLoad();
                  isReloadingPlayer = false;
                }
                // desktop device click on live
                var isClickingLive = false;
                function pageAwakenedClickLive() {
                  // to remove duplicate click event
                  if (isClickingLive) return;
                  isClickingLive = true;
                    if (player) {
                      let item = document.getElementsByClassName("theo-live-control");
                      if (item && item.length > 0)
                      {
                        item[0].click();
                      }
                    }
                    isClickingLive = false;
                };
                //window.addEventListener('focus', pageAwakenedClickLive);
                //window.addEventListener('pageshow', pageAwakenedClickLive);
                //window.addEventListener('visibilitychange', function() {
                //  !document.hidden && pageAwakenedClickLive();
                //});
            `}
        </script>
      </Helmet>
      <div className="theoplayer-container video-js theoplayer-skin vjs-16-9"></div>
      <a className="on-off-control" id="on-off-control">
        Incoming video is turned off, click here to turn it back on.
      </a>
      {streamTextUrl && (
        <div className="caption-placeholder">
          <div style={{ textAlign: "right", padding: "0 5px" }}>
            <WhiteSwitch
              size="small"
              color="primary"
              checked={textStreamingOn}
              onChange={toggleStreaming} />Captions
          </div>
          {textStreamingOn &&
            <iframe id="stFrame"
              src={streamTextUrl}
              frameBorder="0"
              style={{ width: "calc(100% - 1rem)", maxHeight: "200px", margin: "0 auto" }}>
            </iframe>
          }
        </div>
      )}

      <Hidden only={["xl", "lg"]}>
        {/*Placeholders for events*/}
        <Typography
          variant="h6"
          style={{
            textAlign: "left",
            padding: "0 8px"
          }}
        >
          {activeConfig.configData.eventDescriptionText} |{" "}
          {moment().format("MMM Do YYYY")}
        </Typography>
        {/* <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          className={classes.root}
        >
          <Grid style={{ display: "flex" }}>
            <Box pt={2} title="Union">
              <img
                src={activeConfig.configData.logoUrl}
                alt="logo"
                width="auto"
                height="60"
              />
            </Box>
          </Grid>
          <Grid>
            <Box pt={1} title="Event">
              <Typography
                variant="h6"
                style={{
                  textAlign: "center",
                }}
              >
                {activeConfig.configData.eventDescriptionText} |{" "}
                {moment().format("MMM Do YYYY")}
              </Typography>
            </Box>
          </Grid>
        </Grid> */}
      </Hidden>
    </div>

  );
};


export default VideoStream;
