import React, { ReactNode, useContext, useState } from "react";

import { ConfigurationContext } from "./infrastructure/ConfigurationContext";
import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";

const _breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 768,
    lg: 920,
    xl: 1280,
  },
};
export const theme = createMuiTheme({
  breakpoints: _breakpoints,
});
export const getTheme = (color: string) => {
  if (color)
    return createMuiTheme({
      breakpoints: _breakpoints,
      palette: {
        primary: { main: color },
        contrastThreshold: 3,
      },
    });
  else return theme;
};
interface IProps {
  children: ReactNode;
  // any other props that come into the component
}

export const CustomTheme = ({ children }: IProps) => {
  const activeConfig: any = useContext(ConfigurationContext);
  return (
    <ThemeProvider
      theme={
        activeConfig && activeConfig.configData
          ? getTheme(activeConfig.configData.uiConfigs.primary_color)
          : theme
      }
    >
      {children}
    </ThemeProvider>
  );
};
