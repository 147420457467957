import "../App.css";

import { Button, Grid, Hidden, Typography, makeStyles } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import { Permissions, Roles } from "../infrastructure/RbacEnum";
import React, { useContext, useEffect, useState } from "react";

import AppBar from "@material-ui/core/AppBar";
import Auth0Login from "../components/Auth0Login/Auth0Login";
import Box from "@material-ui/core/Box";
import { ConfigurationContext } from "./../infrastructure/ConfigurationContext";
import { CustomTheme } from "../theme";
import { GetWithAuth } from "./../infrastructure/HttpClient";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PubNubHelper from "./PubNubHelper";
import Toolbar from "@material-ui/core/Toolbar";
import { UserContext } from "../infrastructure/UserContext";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles({
  navDisplayFlex: {
    display: `flex`,
    justifyContent: `space-between`,
  },
  linkText: {
    padding: "6px 12px",
    textDecoration: `none`,
    textTransform: `uppercase`,
    color: `black`,
    "&:hover": {
      color: `black`,
    },
  },
});

function Nav() {

  const classes = useStyles();
  const userData = useContext(UserContext);
  const activeConfig = useContext(ConfigurationContext);
  const [sessions, setSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const location = useLocation();
  // get base path
  const regx = /\/(\w+\/session\/\d+)/
  const matches = regx.exec(location.pathname);
  const basePath = matches && matches[1] ? matches[1] : location.pathname.split("/")[1];
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  let definedRole = "";
  let getRole = "default";


  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sessionEl, setSessionAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    if (isAuthenticated &&
      userData &&
      userData.permissions &&
      userData.permissions.indexOf(Permissions.Moderate) !== -1 &&
      activeConfig && activeConfig.configData && activeConfig.configData.eventId) {
      getAccessTokenSilently()
        .then((accessToken) => {
          GetWithAuth(
            `/event/${activeConfig.configData.eventId}/session`,
            accessToken
          ).then((response) => {
            if (response && response.status === 200) {
              const sessionId = PubNubHelper.getSessionIdFromURL();
              if (sessionId) {
                setSelectedSession(response.data.find(a => a.id === Number(sessionId)));
              } else {
                setSelectedSession(null);
              }
              setSessions(response.data);
            }
          })
        });
    }
  }, [activeConfig, isAuthenticated, userData]);

  const openSessionMenu = (event) => {
    setSessionAnchorEl(event.currentTarget);
  };

  const handleSessionClose = () => {
    setSessionAnchorEl(null);
  };
  const selectSession = (sessionId) => {
    const sessionIdInPath = PubNubHelper.getSessionIdFromURL();
    let pathToNavigate = '';
    if (sessionIdInPath) {
      let path = "demo";
      const pathArray = window.location.pathname.split('/')
      if (pathArray.length > 1 && pathArray[1] !== "") {
        path = pathArray[1];
      };
      const currentLocation = window.location.href;
      if (sessionId) {
        pathToNavigate = currentLocation.replace(`${path}/session/${sessionIdInPath}`, `${path}/session/${sessionId}`);
      } else {
        pathToNavigate = currentLocation.replace(`${path}/session/${sessionIdInPath}`, `${path}`);
      }
    } else {
      const currentLocation = window.location.href;
      if (sessionId) {
        pathToNavigate = currentLocation.replace(basePath, `${basePath}/session/${sessionId}`);
      } else {
        pathToNavigate = currentLocation;
      }
    }
    window.location.href = pathToNavigate;
    setSessionAnchorEl(null);
  }


  const handleClose = (event) => {
    if (event.currentTarget.innerText == "DELEGATE")
      window.location.reload();
    setAnchorEl(null);
  };

  const get = function (obj, key) {
    return key.split(".").reduce(function (o, x) {
      return typeof o == "undefined" || o === null ? o : o[x];
    }, obj);
  };

  getRole = get(userData, "role");

  if (getRole) {
    definedRole = getRole.toLowerCase();
  }

  var test = Roles.Delegate;
  const noNavBar = userData?.permissions.indexOf(Permissions.Moderate) < 0;
  const getNav = (base) => {
    if (definedRole == Roles.Delegate.toLowerCase()) {
      return [
        { path: `/${base}`, name: "Delegate", },]
    }
    return [
      { path: `/${base}`, name: "Delegate", },
      { path: `/${base}/moderator`, name: "Moderator", },
      { path: `/${base}/president`, name: "President", },
      { path: `/${base}/broadcast`, name: "Broadcast", },
      { path: `/${base}/initiatevote`, name: "Moderator Voting", },
      { path: `/${base}/vote-broadcast`, name: "Vote Broadcast", },
      { path: `/${base}/operator-phone/1`, name: "Phone Operator Line 1", },
      { path: `/${base}/operator-phone/2`, name: "Phone Operator Line 2", },
      { path: `/${base}/operator-phone/3`, name: "Phone Operator Line 3", },
      { path: `/${base}/operator-phone/4`, name: "Phone Operator Line 4", },
      { path: `/${base}/operator-phone/5`, name: "Phone Operator Line 5", },
      { path: `/${base}/operator-phone/6`, name: "Phone Operator Line 6", },
      { path: `/${base}/operator-phone/7`, name: "Phone Operator Line 7", },
      { path: `/${base}/operator-phone/8`, name: "Phone Operator Line 8", },
      { path: `/${base}/operator-phone/9`, name: "Phone Operator Line 9", },
      { path: `/${base}/operator-text/10`, name: "Text Operator Line 10", },
      { path: `/${base}/operator-text/11`, name: "Text Operator Line 11", },
      { path: `/${base}/operator-text/12`, name: "Text Operator Line 12", },
      { path: `/${base}/operator-text/13`, name: "Text Operator Line 13", },
      { path: `/${base}/operator-text/14`, name: "Text Operator Line 14", },
      { path: `/${base}/operator-text/15`, name: "Text Operator Line 15", },
      { path: `/${base}/operator-text/16`, name: "Text Operator Line 16", },
      { path: `/${base}/operator-text/17`, name: "Text Operator Line 17", },
      { path: `/${base}/operator-text/18`, name: "Text Operator Line 18", },
    ];
  };
  if (!isAuthenticated) {
    return <></>;
  } else if (isAuthenticated && noNavBar) {
    return (
      <CustomTheme>
        <AppBar position="static">
          <Toolbar className="">
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              className={classes.root}
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    <Box title="Union">
                      <img
                        src={activeConfig.configData.logoUrl}
                        alt="logo"
                        width="auto"
                        height="46"
                      />
                    </Box>
                  </Grid>
                  <Hidden only={["md", "sm", "xs"]}>
                    <Grid item>
                      <Box title="Event">
                        <Typography
                          variant="h6"
                          style={{
                            textAlign: "left",
                            padding: "0 5px"
                          }}
                        >{`${activeConfig.configData.eventDescriptionText} | ${moment().format("MMM Do YYYY")}`}
                        </Typography>
                      </Box>
                    </Grid>
                  </Hidden>
                </Grid>
              </Grid>
              <Grid item>
                <Auth0Login />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </CustomTheme>
    );
  }
  else {
    return (
      <CustomTheme>
        <AppBar position="static">
          <Toolbar className="">
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              className={classes.root}
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    <Box title="Union">
                      <img
                        src={activeConfig.configData.logoUrl}
                        alt="logo"
                        width="auto"
                        height="46"
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <div>
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        color="inherit"
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                      >
                        {getNav(basePath).map(({ name, path }) => (
                          <MenuItem
                            key={name}
                            selected={name === "Delegate"}
                            onClick={handleClose}
                          >
                            <Link className={classes.linkText} to={path}>
                              {name}
                            </Link>
                          </MenuItem>
                        ))}
                      </Menu>
                    </div>
                  </Grid>
                  <Hidden only={["md", "sm", "xs"]}>
                    <Grid item>
                      <Box title="Event">
                        <Typography
                          variant="h6"
                          style={{
                            textAlign: "left",
                            padding: "0 5px"
                          }}
                        >{`${activeConfig.configData.eventDescriptionText} | ${moment().format("MMM Do YYYY")}`}
                        </Typography>
                      </Box>
                    </Grid>
                  </Hidden>
                  {sessions && sessions.length > 0 && (
                    <Grid item>
                      <Button aria-controls="session-menu" aria-haspopup="true" onClick={openSessionMenu}
                        color="inherit">
                        <Typography
                          variant="subtitle1"
                          style={{
                            textDecoration: "underline",
                            textTransform: "capitalize"
                          }}
                        >
                          {selectedSession ? (
                            <span>Session:&nbsp; <b>{selectedSession.name}</b></span>
                          ) : "Go to Session"}
                        </Typography>
                      </Button>
                      <Menu
                        id="session-menu"
                        anchorEl={sessionEl}
                        keepMounted
                        open={Boolean(sessionEl)}
                        onClose={handleSessionClose}
                      >
                        {sessions.filter(a => !(selectedSession && selectedSession.id === a.id)).map(({ id, name }) => (
                          <MenuItem
                            key={id}
                            style={{ minWidth: "120px" }}
                            onClick={() => selectSession(id)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                        {selectedSession && (
                          <MenuItem style={{ minWidth: "120px" }} onClick={() => selectSession(null)}>Go to Event</MenuItem>
                        )}
                      </Menu>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item style={{ flex: "auto" }}>
                <Auth0Login color="primary" />
              </Grid>
            </Grid>

            {/* <div>
            <div style={{ float: "right" }}>
              <Auth0Login />
            </div>
            <nav className="navbar-links">
              <ul
                id="nav-links"
                style={{ display: "flex", justifyContent: "space-evenly" }}
              ></ul>
            </nav>
          </div> */}
          </Toolbar>
        </AppBar>
      </CustomTheme>
    );
  }
}

export default Nav;