import "./styles.css";

import React, { useContext, useEffect, useRef, useState } from "react";

import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Can from "../Can";
import CloseIcon from "@material-ui/icons/Close";
import { ConfigurationContext } from "../../infrastructure/ConfigurationContext";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Documents from "./Documents";
import FilterNoneOutlinedIcon from "@material-ui/icons/FilterNoneOutlined";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { Helmet } from "react-helmet";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import { Permissions } from "../../infrastructure/RbacEnum";
import PubNubHelper from "../../infrastructure/PubNubHelper";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import ResolutionButton from "./MobileComponents/ResolutionButton";
import Snackbar from "@material-ui/core/Snackbar";
import { Typography } from "@material-ui/core";
import { UserContext } from "../../infrastructure/UserContext";
import VideoStream from "./VideoStream";
import VoteButton from "./MobileComponents/VoteButton";
import VoteSelection from "../Voting/Participant/VoteSelection/VoteSelection";
import classnames from "classnames";
import getConstants from "../../infrastructure/Constants";
import { makeStyles } from "@material-ui/core/styles";
import { usePubNub } from "pubnub-react";

const useStyles = makeStyles({
  button: {
    marginTop: 10,
    color: "#fff",
    "&:disabled": { color: "rgb(0 0 0 / 0.26)" },
  },
  documentLink: {
    marginTop: 10,
  },
  headers: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#666",
    // height: "35px",
  },
  grey: {
    color: "rgba(0,0,0,0.23)",
    backgroundColor: "rgba(0,0,0,0.12)",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.12)",
    },
  },
  darkGrey: {
    color: "#fff",
    backgroundColor: "#808080",
    "&:hover": {
      backgroundColor: "#808080",
    },
  },
  blue: {
    backgroundColor: "#0066B3",
    "&:hover": {
      backgroundColor: "#8ecef1",
    },
    "&:focus": {
      backgroundColor: "#0066B3",
    },
    "&:active": {
      backgroundColor: "#0066B3",
    },
    "&.selected": {
      backgroundColor: "#0066B3 !important",
    }
  },
  orange: {
    color: "#fff",
    backgroundColor: "#FF9933",
    "&:hover": {
      backgroundColor: "#f7b06b"
    },
    "&:focus": {
      backgroundColor: "#FF9933",
    },
    "&:active": {
      backgroundColor: "#FF9933",
    },
    "&.selected": {
      backgroundColor: "#FF9933 !important",
    }
  },
  lightblue: {
    color: "#fff",
    backgroundColor: "#8ecef1",
    "&:hover": {
      backgroundColor: "#8ecef1",
    },
  },
  lightGreen: {
    color: "#fff",
    backgroundColor: "#9FCC00",
    "&:hover": {
      backgroundColor: "#baef00",
    },
    "&:focus": {
      backgroundColor: "#9FCC00",
    },
    "&:active": {
      backgroundColor: "#9FCC00",
    },
    "&.selected": {
      backgroundColor: "#9FCC00 !important",
    }
  },
  pushers: {
    fontSize: "0.9rem",
    padding: "8px",
  },
  dialogTitle: {
    display: "flex",
    "justify-content": "space-between",
    "align-items": "center",
  },
});

const Resolution = ({
  amILock,
  voteQueueData,
  voteSelection,
  resolutionData,
  lastResolutionId,
  resolutionSelection,
  castVoteCallback,
  joinQueueCallback,
  leaveQueueCallback,
  leaveQueueDisabled,
  disablePlaySound,
  playSound,
  voteModalEnabled,
  setVoteShowModalCallback,
}) => {
  const classes = useStyles();
  const pubNub = usePubNub();
  const userData = useContext(UserContext);
  const activeConfig = useContext(ConfigurationContext);

  const buttonsState = useRef([
    {
      buttonName: "Support",
      buttonCode: "support",
      disabled: false,
      selected: false,
    },
    {
      buttonName: "Oppose",
      buttonCode: "oppose",
      disabled: false,
      selected: false,
    },
    {
      buttonName: "Point of Order",
      buttonCode: "point_of_order",
      disabled: false,
      selected: false,
    },
    {
      buttonName: "Point of Information",
      buttonCode: "point_of_information",
      disabled: false,
      selected: false,
    },
  ]);
  const reqState = useRef(false);
  const [showVoting, setShowVoting] = useState(false);
  const [showLeaveConfimation, setLeaveConfirmation] = useState(false);
  const [leaveQueueButtonName, setLeaveQueueButtonName] = useState('');
  const [showDocument, setShowDocument] = useState(false);
  const [showAdditionalContent, setShowAdditionalContent] = useState(false);
  const [showRTS, setShowRTS] = useState(false);
  const [wasPointOfOrder, setWasPointOfOrder] = useState(false);
  const [wasPointOfInformation, setWasPointOfInformation] = useState(false);
  const [actionError, setActionError] = useState({ hasError: false, errorInfo: '' });
  const resetButtonState = () => {
    let newArr = [...buttonsState.current];
    newArr.forEach((x) => {
      x.disabled = false;
      x.selected = false;
    });
    buttonsState.current = newArr;
  };

  const chatlioRef = useRef(null);
  const audio = new Audio(activeConfig.configData.alertSound);

  useEffect(() => {
    if (activeConfig.configData.alertSound) {
      document.getElementById("res-wrapper").addEventListener("click", () => {
        audio.play();
        audio.pause();
      });
    }

    if (playSound) {
      if (activeConfig.configData.alertSound) {
        audio.play();
      }
      disablePlaySound();
    }

    // initialize to support
    if (resolutionSelection === undefined || resolutionData.length === 0) {
      leaveQueue(null, true);
      reqState.current = false;
    } else if (resolutionSelection.action === "Join" && resolutionData.length > 0 && resolutionData[0].pauseAndClear && !amILock) {
      console.log('Hi')
      leaveQueue(null, true);
      reqState.current = false;
    } else if (resolutionSelection.action === "Join") {
      joinQueue(resolutionSelection.queue, true);
      reqState.current = true;
    }
    if (
      resolutionSelection &&
      resolutionSelection.action === "Join" &&
      resolutionSelection.queue === "Point of Order"
    ) {
      joinQueue(resolutionSelection.queue, true);
      reqState.current = true;
    }
    if (
      resolutionSelection &&
      resolutionSelection.action === "Join" &&
      resolutionSelection.queue === "Point of Information"
    ) {
      joinQueue(resolutionSelection.queue, true);
      reqState.current = true;
    }
    return () => { };
  });

  const user = userData != null ? userData : {};
  const setSelectedButton = (selectedButton) => {
    let newArr = [...buttonsState.current];
    newArr.forEach((x) => {
      if (x.buttonName === selectedButton) {
        x.disabled = true;
        x.selected = true;
      } else {
        x.disabled = true;
        x.selected = false;
      }
    });
    buttonsState.current = newArr;
  };

  const joinQueue = (channel, silent) => {
    try {
      setSelectedButton(channel);
      reqState.current = true;

      // Don't publish if pulling from history
      if (silent) return;

      if (channel === "Point of Order" && activeConfig.configData.pointOfOrder) {
        closeModal();
        if (chatlioRef.current === activeConfig.configData.pointOfOrderChatlioId) {
          window._chatlio.identify(userData.id, {
            name: `${userData.firstName} ${userData.lastName}`,
          });
          window._chatlio.open();
          window._chatlio.send("Point of Order request");
        } else {
          chatlioRef.current = activeConfig.configData.pointOfOrderChatlioId;
          const eventListener = () => {
            window._chatlio.identify(userData.id, {
              name: `${userData.firstName} ${userData.lastName}`,
            });
            window._chatlio.open();
            window._chatlio.clearHistory();
            window._chatlio.send("Point of Order request");
            document.removeEventListener("chatlio.ready", eventListener);
          };
          window._chatlio.switchWidget(activeConfig.configData.pointOfOrderChatlioId);
          document.addEventListener("chatlio.ready", eventListener);
        }
      }

      if (channel === "Point of Information" && activeConfig.configData.pointOfInformation) {
        closeModal();
        if (chatlioRef.current === activeConfig.configData.pointOfInformationChatlioId) {
          window._chatlio.identify(userData.id, {
            name: `${userData.firstName} ${userData.lastName}`,
          });
          window._chatlio.open();
          window._chatlio.send("Point of Information request");
        } else {
          chatlioRef.current = activeConfig.configData.pointOfInformationChatlioId;
          const eventListener = () => {
            window._chatlio.identify(userData.id, {
              name: `${userData.firstName} ${userData.lastName}`,
            });
            window._chatlio.open();
            window._chatlio.clearHistory();
            window._chatlio.send("Point of Information request");
            document.removeEventListener("chatlio.ready", eventListener);
          };
          window._chatlio.switchWidget(activeConfig.configData.pointOfInformationChatlioId);
          document.addEventListener("chatlio.ready", eventListener);
        }
      }

      if ((resolutionData[0] && resolutionData[0].id) || (channel === "Point of Order") || (channel === "Point of Information")) {
        // For "Point of Order", if current resolution exists then use that id, else use lastResolutionId
        // lastResolutionId and current resolution is not exists then it is direct join request for "Point of Order", so use 'no-resolution'
        const pnMessage = PubNubHelper.JoinQueue(
          pubNub,
          user,
          resolutionData[0] ? resolutionData[0].id : (lastResolutionId ? lastResolutionId : 'no-resolution'),
          channel
        );
        joinQueueCallback(pnMessage);
      }
    } catch (err) {
      console.log('Operation failed error:', err);
      const opButton = channel ? [...buttonsState.current].find(a => a.buttonName === channel) : null;
      const opName = activeConfig.configData && activeConfig.configData.uiConfigs && activeConfig.configData.uiConfigs["button_" + opButton.buttonCode + "_txt"];
      setActionError({ hasError: true, errorInfo: `${opName ? opName : 'Join queue'}: Operation failed.` });
    }
  };
  const leaveQueueConfirmation = (channel) => {
    setLeaveQueueButtonName(channel);
    setLeaveConfirmation(true);
  }
  const confirmLeaveQueue = (response) => {
    setLeaveConfirmation(false);
    if (response) {
      leaveQueue(leaveQueueButtonName);
      reqState.current = false;
    }
  }
  const leaveQueue = (channel, silent) => {
    try {
      if (wasPointOfOrder) {
        reqState.current = false;
        setWasPointOfOrder(false);
      }
      if (wasPointOfInformation) {
        reqState.current = false;
        setWasPointOfInformation(false);
      }
      resetButtonState();
      if (silent) return;
      const leaveResolutionMessage = PubNubHelper.LeaveQueue(
        pubNub,
        user,
        resolutionData[0] ? resolutionData[0].id : (lastResolutionId ? lastResolutionId : 'no-resolution'),
        channel
      );
      leaveQueueCallback(leaveResolutionMessage);
    } catch (err) {
      console.log('Operation failed error:', err);
      setActionError({ hasError: true, errorInfo: 'Leave queue: Operation failed.' });
    }
  };

  const chatlioSupportStart = () => {
    try {
      window._chatlio.identify(userData.id, {
        name: `${userData.firstName} ${userData.lastName}`,
      });
      if (chatlioRef.current === activeConfig.configData.eventSupportChatlioId) {
        window._chatlio.open();
      } else {
        chatlioRef.current = activeConfig.configData.eventSupportChatlioId;
        const eventListener = () => {
          window._chatlio.identify(userData.id, {
            name: `${userData.firstName} ${userData.lastName}`,
          });
          window._chatlio.open();
          window._chatlio.clearHistory();
          document.removeEventListener("chatlio.ready", eventListener);
        };
        window._chatlio.switchWidget(activeConfig.configData.eventSupportChatlioId);
        document.addEventListener("chatlio.ready", eventListener);
      }
    } catch (err) {
      const actionName = (activeConfig && activeConfig.configData &&
        activeConfig.configData.uiConfigs &&
        activeConfig.configData.uiConfigs.button_technical_support_txt) || 'Technical Support';
      console.log('Operation failed error:', err);
      setActionError({ hasError: true, errorInfo: `${actionName}:Operation failed.` });
    }
  };

  const closeModal = () => {
    setShowRTS(false);
  };

  const handleDocumentOpen = () => {
    setShowDocument(true);
  };

  const handleDocumentClose = () => {
    setShowDocument(false);
  };
  const handleAdditionalContentOpen = () => {
    try {
      if (activeConfig.configData.uiConfigs.additional_content_url) {
        if (activeConfig.configData.uiConfigs.additional_content_use_iframe === 'true') {
          setShowAdditionalContent(true);
        } else {
          window.open(activeConfig.configData.uiConfigs.additional_content_url, '_blank').focus();
        }
      }
    } catch (err) {
      console.log('Operation failed error:', err);
      setActionError({ hasError: true, errorInfo: 'Operation failed.' });
    }
  };

  const handleAdditionalContentClose = () => {
    setShowAdditionalContent(false);
  };
  const handleActionErrorClose = () => {
    setActionError({ hasError: false, errorInfo: '' });
  }
  return (
    <div className="App" id="res-wrapper">
      <Helmet>
        <script type="text/javascript">
          {`
    window._chatlio = window._chatlio||[];
    !function(){ var t=document.getElementById("chatlio-widget-embed");if(t&&window.ChatlioReact&&_chatlio.init)return void _chatlio.init(t,ChatlioReact);for(var e=function(t){return function(){_chatlio.push([t].concat(arguments)) }},i=["configure","identify","track","show","hide","isShown","isOnline", "page", "open", "showOrHide"],a=0;a<i.length;a++)_chatlio[i[a]]||(_chatlio[i[a]]=e(i[a]));var n=document.createElement("script"),c=document.getElementsByTagName("script")[0];n.id="chatlio-widget-embed",n.src="https://w.chatlio.com/w.chatlio-widget.js",n.async=!0,n.setAttribute("data-embed-version","2.3");
       n.setAttribute('data-widget-id','${activeConfig?.configData?.eventSupportChatlioId ? activeConfig.configData.eventSupportChatlioId : getConstants().EventSupportChatlio}');
       c.parentNode.insertBefore(n,c);
    }();
`}
        </script>
      </Helmet>
      <Grid container className="page-content">
        <audio src={activeConfig.configData.alertSound} preload="auto"></audio>
        <div className="content-section">
          <div className="video-stream-outer-container">
            <Can
              task={Permissions.Watch}
              permissions={userData?.permissions}
              yes={() => <VideoStream />}
              no={() => <div></div>}
            />
          </div>
          {/* Bottom View */}
          <Hidden smUp>
            <div style={{ margin: "20px" }}>
              <Can
                task={Permissions.Vote}
                permissions={userData?.permissions}
                yes={() => (
                  <VoteButton
                    voteQueueData={voteQueueData}
                    ShowFunction={() => setShowVoting(true)}
                  />
                )}
                no={() => <div></div>}
              />
              <Can
                task={Permissions.Speak}
                permissions={userData?.permissions}
                yes={() => (
                  <ResolutionButton
                    resolutionData={resolutionData}
                    ShowFunction={() => setShowRTS(true)}
                  />
                )}
                no={() => <div></div>}
              />
              <Can
                task={Permissions.Support}
                permissions={userData?.permissions}
                yes={() => (
                  <Button
                    fullWidth={true}
                    onClick={(e) => {
                      e.preventDefault();
                      chatlioSupportStart();
                    }}
                    className={classnames(classes.button, classes.darkGrey)}
                    id="technical-support-1"
                  >
                    {
                      activeConfig.configData.uiConfigs
                        .button_technical_support_txt
                    }
                  </Button>
                )}
                no={() => <div></div>}
              />
              <hr className="divider" />
              {activeConfig.configData.uiConfigs.button_additional_content_show === 'true' &&
                activeConfig.configData.uiConfigs.additional_content_url && (
                  <Button
                    fullWidth={true}
                    onClick={(e) => {
                      e.preventDefault();
                      handleAdditionalContentOpen();
                    }}
                    className={classnames(
                      classes.button,
                      classes.documentLink,
                      classes.darkGrey
                    )}
                    id="additional-content-1"
                  >
                    {activeConfig.configData.uiConfigs.button_additional_content_txt}
                  </Button>
                )}
              <Button
                fullWidth={true}
                onClick={(e) => {
                  e.preventDefault();
                  handleDocumentOpen();
                }}
                className={classnames(
                  classes.button,
                  classes.documentLink,
                  classes.darkGrey
                )}
                id="document-link-1"
              >
                <span>
                  <FilterNoneOutlinedIcon style={{ fontSize: 15 }} />
                </span>
                &nbsp;
                {activeConfig.configData.uiConfigs.button_document_links_txt}
              </Button>
            </div>
          </Hidden>

          {/* <div className="inner-container">
            <Documents />
          </div> */}
        </div>
        <div className="action-section">
          {/* DESKTOP VIEW */}
          <Hidden only={["md", "sm", "xs"]}>
            <div>
              <Can
                task={Permissions.Vote}
                permissions={userData?.permissions}
                yes={() => (
                  <>
                    <VoteSelection
                      UserData={userData}
                      VoteQueue={voteQueueData}
                      CurrentVote={voteSelection}
                      CastVote={castVoteCallback}
                      ShowVoteModal={voteModalEnabled}
                      SetShowVoteModal={setVoteShowModalCallback}
                      IsAbstainVisible={voteModalEnabled}
                      setActionError={setActionError}
                    />
                    <hr className="divider" />
                  </>
                )}
                no={() => <div></div>}
              />

              <Can
                task={Permissions.Speak}
                permissions={userData?.permissions}
                yes={() => (
                  <div className="content">
                    <ul className="listing">
                      <li>
                        <Typography variant="body2">
                          <span style={{ paddingRight: "4px" }}>
                            <QuestionAnswerIcon />
                          </span>
                          Request To Speak
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body2" id="resolution-online">
                          <span
                            className={resolutionData[0] && !resolutionData[0].pauseAndClear ? "online" : "offline"}
                          ></span>{" "}
                          {resolutionData[0] && !resolutionData[0].pauseAndClear ? "Open" : "Closed"}
                        </Typography>
                      </li>
                    </ul>
                    <div className="notify-text">
                      {resolutionData.length !== 0 ? (
                        <Typography
                          className={classes.headers}
                          id="resolution-name"
                        >
                          {resolutionData[0].resolutionNumber}
                        </Typography>
                      ) : (
                        <Typography
                          className={classes.headers}
                          id="resolution-name"
                        >
                          No debate is currently active
                        </Typography>
                      )}

                      {reqState.current && (
                        <Typography
                          variant="h6"
                          className={classes.headers}
                          style={{
                            backgroundColor: "rgba(0,0,0,0.12",
                            paddingTop: "5px",
                            fontSize: ".7rem",
                          }}
                          id="resolution-status"
                        >
                          "Request submitted, you are in the queue.
                        </Typography>
                      )}
                    </div>
                    {resolutionData.length > -1 ? (
                      <FormControl fullWidth={true} id="resolution-active">
                        {buttonsState.current
                          .filter((x) => x.buttonName !== "Point of Order" && x.buttonName !== "Point of Information")
                          .map((item, index) => (
                            <Button
                              variant="contained"
                              key={index}
                              style={{
                                color: (item.selected && resolutionData.length > 0 && !(resolutionData[0].pauseAndClear && !amILock)) ? "#fff" : "",
                              }}
                              className={classnames(
                                classes.button,
                                classes.blue,
                                (item.selected && resolutionData.length > 0 && !(resolutionData[0].pauseAndClear && !amILock)) ? "selected" : ""
                              )}
                              disabled={
                                resolutionData.length === 0 ||
                                (resolutionData.length > 0 && resolutionData[0].pauseAndClear) ||
                                item.disabled
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                joinQueue(item.buttonName);
                                reqState.current = true;
                              }}
                              id={`resolution-button-${item.buttonName}`}
                            >
                              {
                                activeConfig.configData.uiConfigs[
                                "button_" + item.buttonCode + "_txt"
                                ]
                              }
                            </Button>
                          ))}
                      </FormControl>
                    ) : (
                      <Typography
                        variant="h6"
                        className={classes.headers}
                        id="resolution-inactive"
                      >
                        Please wait for next resolution
                      </Typography>
                    )}
                    {buttonsState.current
                      .filter((x) => x.buttonName === "Point of Order")
                      .map((item, index) => (
                        <Can
                          task={Permissions.PointOfOrder}
                          permissions={userData?.permissions}
                          yes={() => (
                            <Button
                              fullWidth={true}
                              variant="contained"
                              key={3}
                              style={{
                                color: item.selected ? "#fff" : "",
                              }}
                              className={classnames(
                                classes.button,
                                classes.orange,
                                item.selected ? "selected" : ""
                              )}
                              disabled={item.disabled}
                              onClick={(e) => {
                                e.preventDefault();
                                joinQueue(item.buttonName);
                                reqState.current = true;
                              }}
                              id="resolution-button-point-of-order"
                            >
                              {
                                activeConfig.configData.uiConfigs[
                                "button_" + item.buttonCode + "_txt"
                                ]
                              }
                            </Button>
                          )}
                          no={() => <div></div>}
                        />
                      ))}
                    {buttonsState.current
                      .filter((x) => x.buttonName === "Point of Information")
                      .map((item, index) => (
                        <Can
                          task={Permissions.PointOfInformation}
                          permissions={userData?.permissions}
                          yes={() => (
                            <Button
                              fullWidth={true}
                              variant="contained"
                              key={4}
                              style={{
                                color: item.selected ? "#fff" : "",
                              }}
                              className={classnames(
                                classes.button,
                                classes.lightGreen,
                                item.selected ? "selected" : ""
                              )}
                              disabled={item.disabled}
                              onClick={(e) => {
                                e.preventDefault();
                                joinQueue(item.buttonName);
                                reqState.current = true;
                              }}
                              id="resolution-button-point-of-information"
                            >
                              {
                                activeConfig.configData.uiConfigs[
                                "button_" + item.buttonCode + "_txt"
                                ]
                              }
                            </Button>
                          )}
                          no={() => <div></div>}
                        />
                      ))}
                    <Button
                      fullWidth={true}
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        leaveQueueConfirmation(
                          buttonsState.current.find((x) => x.selected) ? buttonsState.current.find((x) => x.selected)
                            .buttonName : ''
                        );
                      }}
                      disabled={
                        //!buttonsState.current.some((x) => x.disabled === false)
                        (!resolutionSelection || // if no selection
                          resolutionSelection.action !== "Join" || // if I am not joined
                          (resolutionData && resolutionData.length > 0 && resolutionData[0].pauseAndClear && resolutionSelection && resolutionSelection.action === "Join"
                            && resolutionSelection.queue !== "Point of Order"
                            && resolutionSelection.queue !== "Point of Information" && !amILock) || // if the queue got clear and I am not locked, then pretend I have not joined
                          // !(resolutionData && resolutionData.length > 0 && !resolutionData[0].pauseAndClear && resolutionSelection && resolutionSelection.action === "Join"
                          //   && resolutionSelection.queue !== "Point of Order"
                          //   && resolutionSelection.queue !== "Point of Information") ||
                          // (resolutionData.length === 0 && resolutionSelection && resolutionSelection.action === "Join" && resolutionSelection.queue !== "Point of Order"
                          //   && resolutionSelection.queue !== "Point of Information") ||
                          leaveQueueDisabled)
                      }
                      className={classnames(classes.button, classes.blue)}
                      id="resolution-button-leave-queue"
                    >
                      {activeConfig.configData.uiConfigs.button_leave_queue_txt}
                    </Button>
                    <br></br>
                  </div>
                )}
                no={() => <div></div>}
              />
              <div className="content">
                <Can
                  task={Permissions.Support}
                  permissions={userData?.permissions}
                  yes={() => (
                    <Button
                      fullWidth={true}
                      onClick={(e) => {
                        e.preventDefault();
                        chatlioSupportStart();
                      }}
                      className={classnames(classes.button, classes.darkGrey)}
                      id="technical-support-2"
                    >
                      {
                        activeConfig.configData.uiConfigs
                          .button_technical_support_txt
                      }
                    </Button>
                  )}
                  no={() => <div></div>}
                />
                <hr className="divider" />
                {activeConfig.configData.uiConfigs.button_additional_content_show === 'true' &&
                  activeConfig.configData.uiConfigs.additional_content_url && (
                    <Button
                      fullWidth={true}
                      onClick={(e) => {
                        e.preventDefault();
                        handleAdditionalContentOpen();
                      }}
                      className={classnames(
                        classes.button,
                        classes.documentLink,
                        classes.darkGrey
                      )}
                      id="additional-content-2"
                    >
                      {activeConfig.configData.uiConfigs.button_additional_content_txt}
                    </Button>
                  )}
                <Button
                  fullWidth={true}
                  onClick={(e) => {
                    e.preventDefault();
                    handleDocumentOpen();
                  }}
                  className={classnames(
                    classes.button,
                    classes.documentLink,
                    classes.darkGrey
                  )}
                  id="document-link-2"
                >
                  <span>
                    <FilterNoneOutlinedIcon style={{ fontSize: 15 }} />
                  </span>
                  &nbsp;
                  {activeConfig.configData.uiConfigs.button_document_links_txt}
                </Button>
              </div>
            </div>
          </Hidden>

          {/* Side View */}
          <Hidden only={["xs", "lg", "xl"]}>
            <div style={{ marginTop: "20px", padding: "8px" }}>
              <Grid container>
                <Can
                  task={Permissions.Vote}
                  permissions={userData?.permissions}
                  yes={() => (
                    <VoteButton
                      voteQueueData={voteQueueData}
                      ShowFunction={() => setShowVoting(true)}
                    />
                  )}
                  no={() => <div></div>}
                />
                <Can
                  task={Permissions.Speak}
                  permissions={userData?.permissions}
                  yes={() => (
                    <ResolutionButton
                      resolutionData={resolutionData}
                      ShowFunction={() => setShowRTS(true)}
                    />
                  )}
                  no={() => <div></div>}
                />
              </Grid>
              <div>
                <Can
                  task={Permissions.Support}
                  permissions={userData?.permissions}
                  yes={() => (
                    <Button
                      fullWidth={true}
                      onClick={(e) => {
                        e.preventDefault();
                        chatlioSupportStart();
                      }}
                      className={classnames(classes.button, classes.darkGrey)}
                      id="technical-support-3"
                    >
                      {
                        activeConfig.configData.uiConfigs
                          .button_technical_support_txt
                      }
                    </Button>
                  )}
                  no={() => <div></div>}
                />
              </div>
              <hr className="divider" />
              {activeConfig.configData.uiConfigs.button_additional_content_show === 'true' &&
                activeConfig.configData.uiConfigs.additional_content_url && (
                  <Button
                    fullWidth={true}
                    onClick={(e) => {
                      e.preventDefault();
                      handleAdditionalContentOpen();
                    }}
                    className={classnames(
                      classes.button,
                      classes.documentLink,
                      classes.darkGrey
                    )}
                    id="additional-content-3"
                  >
                    {activeConfig.configData.uiConfigs.button_additional_content_txt}
                  </Button>
                )}
              <Button
                fullWidth={true}
                onClick={(e) => {
                  e.preventDefault();
                  handleDocumentOpen();
                }}
                className={classnames(
                  classes.button,
                  classes.documentLink,
                  classes.darkGrey
                )}
                id="document-link-3"
              >
                <span>
                  <FilterNoneOutlinedIcon style={{ fontSize: 15 }} />
                </span>
                &nbsp;
                {activeConfig.configData.uiConfigs.button_document_links_txt}
              </Button>
            </div>
          </Hidden>
        </div>
      </Grid>

      {/* VOTE MODAL */}
      <Hidden only={["lg", "xl"]}>
        <Can
          task={Permissions.Vote}
          permissions={userData?.permissions}
          yes={() => (
            <Modal
              open={showVoting || voteModalEnabled}
              onClose={() => setShowVoting(false)}
              onBackdropClick={() => setShowVoting(false)}
            >
              <div className="modal-content-wrapper">
                <div className="modal-content">
                  <div
                    className="modal-content-close-wrapper"
                    onClick={() => setShowVoting(false)}
                  >
                    <CloseIcon />
                  </div>
                  <VoteSelection
                    UserData={userData}
                    VoteQueue={voteQueueData}
                    CurrentVote={voteSelection}
                    CastVote={castVoteCallback}
                    ShowVoteModal={false}
                    SetShowVoteModal={setVoteShowModalCallback}
                    IsAbstainVisible={voteModalEnabled}
                    setActionError={setActionError}
                  />
                </div>
              </div>
            </Modal>
          )}
          no={() => <div></div>}
        />

      </Hidden>

      {/* RESOLUTION MODAL */}
      <Modal
        open={showRTS}
        onClose={() => setShowRTS(false)}
        onBackdropClick={closeModal}
      >
        <div className="modal-content-wrapper">
          <div className="modal-content">
            <div
              className="modal-content-close-wrapper"
              onClick={() => setShowRTS(false)}
            >
              <CloseIcon />
            </div>
            <ul className="listing">
              <li>
                <Typography variant="body2">
                  <span style={{ paddingRight: "4px" }}>
                    <QuestionAnswerIcon />
                  </span>
                  Request To Speak
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  <span
                    className={resolutionData[0] ? "online" : "offline"}
                  ></span>{" "}
                  {resolutionData[0] && !resolutionData[0].pauseAndClear ? "Open" : "Closed"}
                </Typography>
              </li>
            </ul>
            <div className="notify-text">
              {resolutionData.length !== 0 ? (
                <Typography className={classes.headers}>
                  {resolutionData[0].resolutionNumber}
                </Typography>
              ) : (
                <Typography className={classes.headers}>
                  No debate is currently active
                </Typography>
              )}
              {reqState.current && (
                <Typography
                  variant="h6"
                  className={classes.headers}
                  style={{
                    height: "25px",
                    backgroundColor: "rgba(0,0,0,0.12",
                    paddingTop: "5px",
                    fontSize: ".7rem",
                  }}
                >
                  Request submitted, you are in the queue.
                </Typography>
              )}
            </div>
            {resolutionData.length > -1 ? (
              <FormControl fullWidth={true}>
                {buttonsState.current
                  .filter((x) => x.buttonName !== "Point of Order" && x.buttonName !== "Point of Information")
                  .map((item, index) => (
                    <Button
                      variant="contained"
                      key={index}
                      style={{
                        color: item.selected ? "#fff" : "",
                      }}
                      className={classnames(classes.button, classes.blue,
                        item.selected && (resolutionData.length > 0 && !(resolutionData[0].pauseAndClear && !amILock)) ? "selected" : "")}
                      disabled={
                        resolutionData.length === 0 ||
                        (resolutionData.length > 0 && resolutionData[0].pauseAndClear && resolutionSelection && resolutionSelection.action !== "Join") ||
                        (resolutionData.length > 0 && resolutionData[0].pauseAndClear && !amILock) || (item.disabled && !item.selected)
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        joinQueue(item.buttonName);
                        reqState.current = true;
                      }}
                    >
                      {item.buttonName}
                    </Button>
                  ))}
              </FormControl>
            ) : (
              <Typography variant="h6" className={classes.headers}>
                Please wait for next resolution
              </Typography>
            )}
            {buttonsState.current
              .filter((x) => x.buttonName === "Point of Order")
              .map((item) => (
                <Can
                  task={Permissions.PointOfOrder}
                  permissions={userData?.permissions}
                  key={item.buttonName}
                  yes={() => (
                    <Button
                      fullWidth={true}
                      variant="contained"
                      key={3}
                      style={{
                        color: item.selected ? "#fff" : "",
                      }}
                      className={classnames(classes.button, classes.orange,
                        item.selected ? "selected" : "")}
                      disabled={item.disabled && !item.selected}
                      onClick={(e) => {
                        e.preventDefault();
                        joinQueue(item.buttonName);
                        reqState.current = true;
                      }}
                    >
                      {item.buttonName}
                    </Button>
                  )}
                  no={() => <div></div>}
                />
              ))}
            {buttonsState.current
              .filter((x) => x.buttonName === "Point of Information")
              .map((item) => (
                <Can
                  task={Permissions.PointOfInformation}
                  permissions={userData?.permissions}
                  key={item.buttonName}
                  yes={() => (
                    <Button
                      fullWidth={true}
                      variant="contained"
                      key={3}
                      style={{
                        color: item.selected ? "#fff" : "",
                      }}
                      className={classnames(classes.button, classes.lightGreen,
                        item.selected ? "selected" : "")}
                      disabled={item.disabled && !item.selected}
                      onClick={(e) => {
                        e.preventDefault();
                        joinQueue(item.buttonName);
                        reqState.current = true;
                      }}
                    >
                      {
                        activeConfig.configData.uiConfigs[
                        "button_" + item.buttonCode + "_txt"
                        ]
                      }
                    </Button>
                  )}
                  no={() => <div></div>}
                />
              ))}
            <Button
              fullWidth={true}
              variant="contained"
              onClick={(e) => {
                e.preventDefault();
                leaveQueueConfirmation(
                  buttonsState.current.find((x) => x.selected) ? buttonsState.current.find((x) => x.selected)
                    .buttonName : ''
                );
              }}
              disabled={
                (!resolutionSelection || // if no selection
                resolutionSelection.action !== "Join" || // if I am not joined
                (resolutionData && resolutionData.length > 0 && resolutionData[0].pauseAndClear && resolutionSelection && resolutionSelection.action === "Join"
                  && resolutionSelection.queue !== "Point of Order"
                  && resolutionSelection.queue !== "Point of Information" && !amILock) || // if the queue got clear and I am not locked, then pretend I have not joined
                // !(resolutionData && resolutionData.length > 0 && !resolutionData[0].pauseAndClear && resolutionSelection && resolutionSelection.action === "Join"
                //   && resolutionSelection.queue !== "Point of Order"
                //   && resolutionSelection.queue !== "Point of Information") ||
                // (resolutionData.length === 0 && resolutionSelection && resolutionSelection.action === "Join" && resolutionSelection.queue !== "Point of Order"
                //   && resolutionSelection.queue !== "Point of Information") ||
                leaveQueueDisabled)
              }
              className={classnames(classes.button, classes.blue)}
            >
              {activeConfig.configData.uiConfigs.button_leave_queue_txt}
            </Button>
            <br></br>
            <Hidden smDown>
              <Can
                task={Permissions.Support}
                permissions={userData?.permissions}
                yes={() => (
                  <Button
                    fullWidth={true}
                    onClick={(e) => {
                      e.preventDefault();
                      chatlioSupportStart();
                    }}
                    className={classnames(classes.button, classes.darkGrey)}
                    id="technical-support-4"
                  >
                    {
                      activeConfig.configData.uiConfigs
                        .button_technical_support_txt
                    }
                  </Button>
                )}
                no={() => <div></div>}
              />
            </Hidden>
          </div>
        </div>
      </Modal>

      {/* DOCUMENT MODAL */}
      <Dialog
        open={showDocument}
        onClose={handleDocumentClose}
        onBackdropClick={handleDocumentClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <Typography variant="h5">
            <span>
              <FilterNoneOutlinedIcon />
            </span>{" "}
            {activeConfig.configData.uiConfigs.button_document_links_txt}
          </Typography>
          <IconButton onClick={handleDocumentClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Documents showHeader={false} />
        </DialogContent>
      </Dialog>
      {/* ADDITIONAL CONTENT MODAL */}
      {showAdditionalContent && (
        <Dialog
          open={showAdditionalContent}
          fullWidth={true}
          maxWidth="xl"
          onClose={handleAdditionalContentClose}
          onBackdropClick={handleAdditionalContentClose}
          aria-describedby="Additional Content"
        >
          <DialogTitle disableTypography className={classes.dialogTitle}>
            <Typography variant="h5">
              {activeConfig.configData.uiConfigs.button_additional_content_txt}
            </Typography>
            <IconButton onClick={handleAdditionalContentClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <iframe style={{ height: "80vh", width: "100%" }} frameBorder="0" src={activeConfig.configData.uiConfigs.additional_content_url}></iframe>
          </DialogContent>
        </Dialog>
      )}
      {showLeaveConfimation && (
        <Modal
          open={showLeaveConfimation}
          onClose={() => setLeaveConfirmation(false)}
          onBackdropClick={() => setLeaveConfirmation(false)}
        >
          <div className="modal-content-wrapper">
            <div className="modal-content">
              <div
                className="modal-content-close-wrapper"
                onClick={() => setShowVoting(false)}
              >
                <CloseIcon />
              </div>
              <div>
                Leaving the queue removes your place in line.
              </div>
              <div>
                Do you want to proceed?
              </div>
              <Button
                id="yes-leave"
                variant="contained"
                className={classnames(classes.button, classes.blue)}
                onClick={() => {
                  confirmLeaveQueue(true);
                }}
              >
                Yes
              </Button>
              <Button
                id="no-leave"
                variant="contained"
                className={classnames(classes.button, classes.blue)}
                onClick={() => {
                  confirmLeaveQueue(false);
                }}
              >
                No
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {/* <Modal
        open={showDocument}
        onClose={handleDocumentClose}
        onBackdropClick={handleDocumentClose}
      >
        <div className="modal-content-wrapper">
          <div className="modal-content">
            <div
              className="modal-content-close-wrapper"
              onClick={handleDocumentClose}
            >
              <CloseIcon />
            </div>
            <Documents />
          </div>
        </div>
      </Modal> */}
      <Snackbar
        open={actionError.hasError}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={handleActionErrorClose}>
        <Alert onClose={handleActionErrorClose} severity="error">
          {actionError.errorInfo}
        </Alert>
      </Snackbar>


    </div>
  );
};
export default Resolution;
