import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React, { useContext } from "react";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { Route, Switch } from "react-router-dom";

import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import Auth0Redirect from "./components/Auth0Redirect";
import BroadcastListener from "./components/BroadcastView/BroadcastListener";
import { ConfigurationContext } from "./infrastructure/ConfigurationContext";
import { Helmet } from "react-helmet";
import Home from "./components/Home";
import InitiateVote from "./components/Voting/Moderator/InitiateVote/InitiateVote.jsx";
import ModeratorListener from "./components/ModeratorQueue/ModeratorListener";
import Nav from "./infrastructure/Nav";
import PhoneOperator from "./components/PhoneOperator";
import PageNotFound from "./infrastructure/PageNotFound";
import PresidentListener from "./components/PresidentsView/PresidentListener";
import PrivateRoute from "./infrastructure/private-route";
import SessionConfigurationProvider from "./infrastructure/SessionConfigurationProvider"
import StartParticipantVote from "./components/Voting/Participant/StartParticipantVote/StartParticipantVote";
import UnionCheck from "./components/UnionCheck";
import UserImport from "./components/UserImport";
import VoteBroadcastListener from "./components/VoteBroadcast/VoteBroadcastListener";
import WaitingRoomListener from "./components/Resolution/WaitingRoomListener";
import { createBrowserHistory } from "history";
import getConstants from "./infrastructure/Constants";
import TextOperator from "./components/TextOperator";

const browserHistory = createBrowserHistory({ basename: "" });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: getConstants().AppInsightsInstrumentationKey,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    }
    // ,isCookieUseDisabled: true
  },
});
appInsights.loadAppInsights();

const App = () => {
  const activeConfig = useContext(ConfigurationContext);
  const route = activeConfig.configData.route;
  const headerContent = activeConfig.configData.pageTitle ?? "";
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{headerContent} - Unity</title>

        <meta
          name="description"
          content={headerContent + " - OnlineEventPlus"}
        />
      </Helmet>
      <Nav />
      <div className="route-container">
        <Switch>
          <PrivateRoute path="/" exact component={Home} />
          <Route path="/" exact component={Auth0Redirect} />
          <Route
            key={`route-/${route}/credentials`}
            path={`/${route}/credentials`}
            exact
            component={UnionCheck}
          />
          <PrivateRoute
            key={`route-/${route}/`}
            path={`/${route}/`}
            exact
            component={Home}
          />
          <PrivateRoute
            key={`route-/${route}/resolution`}
            path={`/${route}/resolution`}
            component={WaitingRoomListener}
          />
          <PrivateRoute
            key={`route-/${route}/moderator`}
            path={`/${route}/moderator`}
            component={ModeratorListener}
          />
          <PrivateRoute
            key={`route-/${route}/initiatevote`}
            path={`/${route}/initiatevote`}
            component={InitiateVote}
          />
          <PrivateRoute
            key={`route-/${route}/startparticipantvote`}
            path={`/${route}/startparticipantvote`}
            component={StartParticipantVote}
          />
          <PrivateRoute
            key={`route-/${route}/broadcast`}
            path={`/${route}/broadcast`}
            component={BroadcastListener}
          />
          <PrivateRoute
            key={`route-/${route}/president`}
            path={`/${route}/president`}
            component={PresidentListener}
          />
          <PrivateRoute
            key={`route-/${route}/vote-broadcast`}
            path={`/${route}/vote-broadcast`}
            component={VoteBroadcastListener}
          />
          <PrivateRoute
            key={`route-/${route}/operator-phone/:slot`}
            path={`/${route}/operator-phone/:slot`}
            component={PhoneOperator}
          />
          <PrivateRoute
            key={`route-/${route}/operator-text/:slot`}
            path={`/${route}/operator-text/:slot`}
            component={TextOperator}
          />
          <PrivateRoute
            exact
            path={`/${route}/csea/csv`}
            component={UserImport}
          />
          <PrivateRoute
            key={`route-/${route}/session`}
            path={`/${route}/session/:sessionId`}
          >
            <SessionConfigurationProvider>
              <PrivateRoute
                key={`route-session`}
                path={`/${route}/session/:sessionId`}
                exact
                component={Home}
              />
              <PrivateRoute
                key={`route-session-resolution`}
                path={`/${route}/session/:sessionId/resolution`}
                exact
                component={WaitingRoomListener}
              />
              <PrivateRoute
                key={`route-session-moderator`}
                path={`/${route}/session/:sessionId/moderator`}
                exact
                component={ModeratorListener}
              />
              <PrivateRoute
                key={`route-session-initiatevote`}
                path={`/${route}/session/:sessionId/initiatevote`}
                exact
                component={InitiateVote}
              />
              <PrivateRoute
                key={`route-session-startparticipantvote`}
                path={`/${route}/session/:sessionId/startparticipantvote`}
                exact
                component={StartParticipantVote}
              />
              <PrivateRoute
                key={`route-session-broadcast`}
                path={`/${route}/session/:sessionId/broadcast`}
                exact
                component={BroadcastListener}
              />
              <PrivateRoute
                key={`route-session-president`}
                path={`/${route}/session/:sessionId/president`}
                exact
                component={PresidentListener}
              />
              <PrivateRoute
                key={`route-session-vote-broadcast`}
                path={`/${route}/session/:sessionId/vote-broadcast`}
                exact
                component={VoteBroadcastListener}
              />
              <PrivateRoute
                key={`route-session-operator/:slot`}
                path={`/${route}/session/:sessionId/operator-phone/:slot`}
                exact
                component={PhoneOperator}
              />
              <PrivateRoute
                key={`route-session-operator-text/:slot`}
                path={`/${route}/session/:sessionId/operator-text/:slot`}
                exact
                component={TextOperator}
              />
            </SessionConfigurationProvider>
          </PrivateRoute>
          <Route path="*" component={PageNotFound} />
        </Switch>
      </div>
    </React.Fragment>
  );
};

export default withAITracking(reactPlugin, App);
