import React, { useContext } from "react";

import { ConfigurationContext } from "../../infrastructure/ConfigurationContext";
import FilterNoneOutlinedIcon from "@material-ui/icons/FilterNoneOutlined";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

const Documents = ({ showHeader = true }) => {
  const activeConfig = useContext(ConfigurationContext);
  const links = activeConfig.configData.links;
  return (
    <Grid container>
      <hr />
      <Grid style={{ paddingLeft: "5px", textAlign: "left" }}>
        {showHeader && (
          <Typography variant="h5">
            <span>
              <FilterNoneOutlinedIcon />
            </span>{" "}
            Documents & Links
          </Typography>
        )}

        <ul className="docs-links">
          {links.map((link, index) => {
            return (
              <li key={index}>
                <a href={link.url} target="_blank">
                  {link.name}
                </a>
              </li>
            );
          })}
        </ul>
      </Grid>
    </Grid>
  );
};

export default Documents;
