import React from "react";
import { GetPubNubClient } from "./GetPubNubClient";
import { PubNubProvider } from "pubnub-react";

const PubNubProviderFunction = ({ children }) => {
    const pubNub = GetPubNubClient();
  return <PubNubProvider client={pubNub}>{children}</PubNubProvider>;
};

export default PubNubProviderFunction;
