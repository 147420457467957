import "../voting.css";

import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import React, { useContext } from "react";

import { SessionConfigurationContext } from "../../../../infrastructure/SessionConfigurationContext";
import { makeStyles } from "@material-ui/core/styles";

const ResultsView = ({ VoteDescription, VoteId, enabledVoteWeight, VoteResults, downloadReport }) => {
  const sessionConfig = useContext(SessionConfigurationContext);
  const location = useLocation();
  const basePath = location.pathname.split("/")[1];
  const useStyles = makeStyles({
    tableContainer: {
      display: "flex",
      flexDirection: "column",
      margin: "200px auto",
      width: "1000px",
      alignItems: "center",
    },
    table: {
      maxWidth: "900px",
    },
  });
  const classes = useStyles();

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Link to={`/${basePath}${sessionConfig && sessionConfig.configData ? ('/session/' + sessionConfig.configData.id) : ''}/initiatevote`}>
          <Button
            variant="contained"
            color="primary"
            id="create-new-vote-button"
          >
            Create New Vote
          </Button>
        </Link>
        <Typography variant="h6" id="tableTitle" component="h6">
          Results for Vote #: {VoteId}
        </Typography>
        <Typography variant="h6" id="tableTitle" component="h6">
          {VoteDescription}
        </Typography>
        <Typography variant="h6" id="tableTitle" component="h6">
          Vote weight enabled: {enabledVoteWeight ? 'Yes' : 'No'}
        </Typography>
        <Table
          aria-label="simple table"
          className={classes.table}
          id="vote-results-table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="right">Yes</TableCell>
              <TableCell align="right">Yes Percent</TableCell>
              <TableCell align="right">No </TableCell>
              <TableCell align="right">No Percent</TableCell>
              <TableCell align="right">Total</TableCell>
              <TableCell align="right">Poll Id</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell id="yesTally" align="right">{VoteResults.yesTally}</TableCell>
              <TableCell id="yesTallyPercentage" align="right">{VoteResults.yesPercent}%</TableCell>
              <TableCell id="noTally" align="right">{VoteResults.noTally} </TableCell>
              <TableCell id="noTallyPercentage" align="right">{VoteResults.noPercent}%</TableCell>
              <TableCell id="totalVotes" align="right">{VoteResults.totalVotes}</TableCell>
              <TableCell id="pollId" align="right">{VoteResults.pollId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right">
                (Irregular) {VoteResults.irregularYesTally}{" "}
              </TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right">
                (Irregular) {VoteResults.irregularNoTally}{" "}
              </TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => downloadReport(VoteResults.pollId)}
                  id="download-vote-queue-button"
                >
                  Download Vote
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>

        </Table>
      </TableContainer>
    </>
  );
};

export default ResultsView;
