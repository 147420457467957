import "./styles.css";

import * as Yup from "yup";

import React, { useContext, useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import { Card } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { ConfigurationContext } from "../../infrastructure/ConfigurationContext";
import { FcInfo } from "react-icons/fc";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Post } from "../../infrastructure/HttpClient";
import ReactScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";
import RegistrationConfirmation from "./RegistrationConfirmation";
import logo from "../../images/UnionCheck.png";
import { useFormik } from "formik";

const ValidationSchema = (props) => {
  const {
    email,
    firstName,
    lastName,
    addressLine1,
    addressLine2,
    phoneNumber,
    city,
    state,
    zip,
    chapter,
    membershipId,
  } = props.results;

  const isUserRegistered = props.isUserRegistered;
  const [isScrolling, setScrolling] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [cbState, setCbState] = useState(true);

  const activeConfig = useContext(ConfigurationContext);

  const [show, setShow] = useState(false);
  const [successfulRegistration, setSuccessfulRegistration] = useState(false);
  const [values, setValues] = useState({});

  function toggleLanguageSelector() {
    if (!cbState) {
      setCbState(true);
      formik.values.preferredLanguage = "English";
    } else {
      setCbState(false);
      formik.values.preferredLanguage = "";
    }
  }
  useEffect(() => {
    setScrolling(false);
  });
  const autoScroll = () => setScrolling(true);
  const handleClose = () => {
    values.passwordConfirm = values.password;
    setShow(false);
  };
  const handleSubmit = () => {
    if (!isProcessing) {
      setIsProcessing(true);
      Post(
        `/UserAccount/CreateUser?eventId=${activeConfig.configData.eventId}`,
        values
      )
        .then((result) => {
          setShow(false);
          setSuccessfulRegistration(true);
          setTimeout(() => {
            setIsProcessing(false);
            window.location.replace(activeConfig.configData.micrositeUrl);
          }, 5000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  function createYupSchema(uiConfigs) {
    const yupSchemaShape = {
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email").required("Required"),
    }
    if(isUserRegistered === false){
       yupSchemaShape.password = Yup.string()
        .required("Required")
        .matches(
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          "Password must contain lowercase, uppercase, 1 number, and 1 special character"
        )
    }
    if(isUserRegistered === false){
      yupSchemaShape.passwordConfirm = Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords don't match")
        .required("Password confirm is required")
    }
    if (uiConfigs.field_address1_show === "true") {
      yupSchemaShape.addressLine1 = Yup.string().required("Required");
    }
    if (uiConfigs.field_city_show === "true") {
      yupSchemaShape.city = Yup.string().required("Required");
    }
    if (uiConfigs.field_state_show === "true") {
      yupSchemaShape.state = Yup.string().required("Required");
    }
    if (uiConfigs.field_zip_show === "true") {
      yupSchemaShape.zip = Yup.string()
        .required("Required")
        .matches(
          /(^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$)|(^\d{5}(-\d{4})?$)/,
          "Must be a valid US or Canadian zip code"
        );
    }
    if (uiConfigs.field_phone_show === "true") {
      yupSchemaShape.phoneNumber = Yup.string()
        .required("Required")
        .matches(
          /^\d{10}$/,
          "Not a valid phone number, do not enter any special characters, punctuation, or country code."
        );
    }
    if (uiConfigs.field_union_membership_id_show === "true") {
      yupSchemaShape.membershipId = Yup.string().required("Required");
    }
    if (uiConfigs.field_local_union_show === "true") {
      yupSchemaShape.chapter = Yup.string();
    }
    if (uiConfigs.field_language_show === "true") {
      yupSchemaShape.preferredLanguage = Yup.string().required("Required");
    }
    return Yup.object().shape(yupSchemaShape);
  }
  const formik = useFormik({
    initialValues: {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: "",
      passwordConfirm: "",
      phoneNumber: phoneNumber,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      city: city,
      state: state,
      zip: zip,
      membershipId: membershipId,
      chapter: chapter,
      preferredLanguage: "English",
    },
    validationSchema: createYupSchema(activeConfig.configData.uiConfigs),
    onSubmit: (values) => {
      delete values.passwordConfirm;

      setValues(values);
      setShow(true);
    },
  });

  const { errors, touched } = formik;
  return (
    <>
      {successfulRegistration ? (
        <RegistrationConfirmation />
      ) : (
        <div className="form-wrapper reg-card-wrapper">
          <Card
            className="h-100 w-100 shadow-sm bg-white rounded reg-card"
            style={{ margin: 0 }}
          >
            <Card.Body className="d-flex flex-column">
              <div className="d-flex mb-2 justify-content-between">
                <Card.Title className="mb-0 font-weight-bold">
                  <img
                    src={logo}
                    width="50"
                    height="50"
                    alt="picture of logo"
                  />
                  Signup
                </Card.Title>
              </div>
              <Card.Text className="text-secondary">
                Please enter the following information.
              </Card.Text>
              {show && (
                <Modal show={show} onHide={handleClose} keyboard={false}>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {" "}
                      <FcInfo />
                      Please verify your information
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div> First Name: {values.firstName} </div>
                    <div>Last Name: {values.lastName}</div>
                    {""}
                    {activeConfig.configData.uiConfigs.field_address1_show ===
                      "true" && (
                        <div>Address: {values.addressLine1} </div>
                      )}
                    {activeConfig.configData.uiConfigs.field_address2_show ===
                      "true" && (
                        <div>Address 2:{values.addressLine2} </div>
                      )}
                    {activeConfig.configData.uiConfigs.field_city_show ===
                      "true" && (
                        <div>City: {values.city} </div>
                      )}
                    {activeConfig.configData.uiConfigs.field_state_show ===
                      "true" && (
                        <div>State: {values.state} </div>
                      )}
                    {activeConfig.configData.uiConfigs.field_zip_show ===
                      "true" && (
                        <div>Zip: {values.zip}</div>
                      )}
                    {activeConfig.configData.uiConfigs.field_phone_show ===
                      "true" && (
                        <React.Fragment>
                          <div>
                            <strong>
                              Confirm this is the phone number you will use to speak during the convention; if attending In-Person this phone number must accept text/SMS messages.
                            </strong>
                          </div>
                          <div>
                            <strong>Phone Number: {values.phoneNumber}</strong>
                          </div>
                        </React.Fragment>
                      )}

                    {activeConfig.configData.uiConfigs.field_language_show ===
                      "true" && activeConfig.configData.languages &&
                      activeConfig.configData.languages.length > 0 && (
                        <div>Language: {values.preferredLanguage}</div>
                      )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => handleClose()}
                      id="edit-button"
                    >
                      Edit
                    </Button>
                    <Button
                      variant="primary"
                      disabled={isProcessing}
                      onClick={() => handleSubmit()}
                      id="submit-button"
                      style={{
                        position: "absolute",
                        right: "48px",
                      }}
                    >
                      Submit
                    </Button>
                  </Modal.Footer>
                </Modal>
              )}
              <ReactScrollIntoViewIfNeeded active={isScrolling} />
              <form onSubmit={formik.handleSubmit}>
                <Form.Row>
                  <Form.Group as={Col} xs={6}>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      name="firstName"
                      placeholder="First Name"
                      id="first-name-field"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                      isInvalid={touched.firstName && !!errors.firstName}
                    />
                    <Form.Control.Feedback className="errorMsg" type="invalid">
                      {errors.firstName}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs={6}>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      name="lastName"
                      placeholder="Last Name"
                      id="last-name-field"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                      isInvalid={touched.lastName && !!errors.lastName}
                    />
                    <Form.Control.Feedback className="errorMsg" type="invalid">
                      {errors.lastName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} xs={12}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      disabled={true}
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      isInvalid={touched.email && !!errors.email}
                    />
                    <Form.Control.Feedback className="errorMsg" type="invalid">
                      {formik.errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} xs={12} hidden={isUserRegistered}>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      name="password"
                      type="password"
                      placeholder="Password"
                      id="password-field"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      isInvalid={touched.password && errors.password}
                    />
                    <Form.Control.Feedback className="errorMsg" type="invalid">
                      {formik.errors.password}
                    </Form.Control.Feedback>
                    {(!touched.password || !errors.password) && (
                      <Form.Text>
                        Password must contain lowercase, uppercase, 1 number,
                        and 1 special character
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group as={Col} xs={12} hidden={isUserRegistered}>
                    <Form.Label>Password Confirm</Form.Label>
                    <Form.Control
                      name="passwordConfirm"
                      placeholder="Confirm Password"
                      type="password"
                      id="password-confirmation-field"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.passwordConfirm}
                      isInvalid={
                        touched.passwordConfirm && !!errors.passwordConfirm
                      }
                    />
                    <Form.Control.Feedback className="errorMsg" type="invalid">
                      {formik.errors.passwordConfirm}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                {activeConfig.configData.uiConfigs.field_address1_show ===
                  "true" && (
                    <Form.Group>
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        name="addressLine1"
                        placeholder="Address Line 1"
                        id="address-line-1-field"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.addressLine1}
                        isInvalid={touched.addressLine1 && !!errors.addressLine1}
                      />
                      <Form.Control.Feedback className="errorMsg" type="invalid">
                        {errors.addressLine1}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                {activeConfig.configData.uiConfigs.field_address2_show ===
                  "true" && (
                    <Form.Group>
                      <Form.Label>Address 2</Form.Label>
                      <Form.Control
                        name="addressLine2"
                        placeholder="Address Line 2"
                        id="address-line-2-field"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.addressLine2}
                      />
                    </Form.Group>
                  )}

                <Form.Row>
                  {activeConfig.configData.uiConfigs.field_city_show ===
                    "true" && (
                      <Form.Group as={Col} xs={12}>
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          name="city"
                          placeholder="City"
                          id="city-field"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.city}
                          isInvalid={touched.city && !!errors.city}
                        />
                        <Form.Control.Feedback
                          className="errorMsg"
                          type="invalid"
                        >
                          {errors.city}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                  {activeConfig.configData.uiConfigs.field_state_show ===
                    "true" && (
                      <Form.Group as={Col} xs={12}>
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          name="state"
                          placeholder="State"
                          id="state-field"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.state}
                          isInvalid={touched.state && !!errors.state}
                        />
                        <Form.Control.Feedback
                          className="errorMsg"
                          type="invalid"
                        >
                          {errors.State}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                  {activeConfig.configData.uiConfigs.field_zip_show ===
                    "true" && (
                      <Form.Group as={Col} xs={12}>
                        <Form.Label>Zip</Form.Label>
                        <Form.Control
                          name="zip"
                          placeholder="Zip Code"
                          id="zip-field"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.zip}
                          isInvalid={touched.zip && !!errors.zip}
                        />
                        <Form.Control.Feedback
                          className="errorMsg"
                          type="invalid"
                        >
                          {errors.zip}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                </Form.Row>
                <Form.Row>
                  {activeConfig.configData.uiConfigs.field_phone_show ===
                    "true" && (
                      <Form.Group as={Col} xs={12}>
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          name="phoneNumber"
                          placeholder="Phone Number"
                          id="phone-number-field"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phoneNumber}
                          isInvalid={touched.phoneNumber && !!errors.phoneNumber}
                        />
                        <Form.Control.Feedback
                          className="errorMsg"
                          type="invalid"
                        >
                          {errors.phoneNumber}
                        </Form.Control.Feedback>
                        <Form.Text>
                          if attending In-Person, phone must be able to send & receive text/SMS messages
                        </Form.Text>
                      </Form.Group>
                    )}
                  {activeConfig.configData.uiConfigs
                    .field_union_membership_id_show === "true" && (
                      <Form.Group as={Col} xs={12}>
                        <Form.Label>
                          {activeConfig.configData.uiConfigs.label_union_id_txt}
                        </Form.Label>
                        <Form.Control
                          disabled
                          name="unionMembershipId"
                          placeholder={
                            activeConfig.configData.uiConfigs.label_union_id_txt
                          }
                          id="union-membership-id-field"
                          onBlur={formik.handleBlur}
                          value={formik.values.membershipId}
                          isInvalid={
                            touched.membershipId && !!errors.membershipId
                          }
                        />
                        <Form.Control.Feedback
                          className="errorMsg"
                          type="invalid"
                        >
                          {errors.membershipId}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                  {activeConfig.configData.uiConfigs.field_local_union_show ===
                    "true" && (
                      <Form.Group as={Col} xs={12}>
                        <Form.Label>
                          {
                            activeConfig.configData.uiConfigs
                              .label_local_union_txt
                          }
                        </Form.Label>
                        <Form.Control
                          disabled={true}
                          name="unionChapter"
                          placeholder={
                            activeConfig.configData.uiConfigs
                              .label_local_union_txt
                          }
                          id="union-chapter-field"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.chapter}
                          isInvalid={touched.chapter && !!errors.chapter}
                        />
                        <Form.Control.Feedback
                          className="errorMsg"
                          type="invalid"
                        >
                          {errors.chapter}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                </Form.Row>
                {activeConfig.configData.uiConfigs.field_language_show ===
                  "true" &&
                  activeConfig.configData.languages &&
                  activeConfig.configData.languages.length > 0 && (
                    <Form.Row>
                      <Form.Group>
                        <Form.Check
                          name="needTranslator"
                          id="translator-field"
                          label="Do you require a translator to speak for you in the convention?"
                          type="checkbox"
                          onChange={toggleLanguageSelector}
                        />
                        <Form.Control
                          name="preferredLanguage"
                          as="select"
                          id="language-field"
                          disabled={cbState}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.preferredLanguage}
                          isInvalid={
                            !cbState && !formik.values.preferredLanguage
                          }
                        >
                          {activeConfig.configData.languages.map(
                            (languages, index) => {
                              return (
                                <option key={index} value={languages}>
                                  {languages}
                                </option>
                              );
                            }
                          )}
                        </Form.Control>
                        <Form.Control.Feedback
                          className="errorMsg"
                          type="invalid"
                        >
                          Please select a language
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                  )}
                <Button variant="success" type="submit" onClick={autoScroll}>
                  Submit
                </Button>
              </form>
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  );
};

export default ValidationSchema;
