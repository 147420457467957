import "./styles.css";
import "../voting.css";

import { Button, Paper, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";

import { MessageActions } from "../../../../infrastructure/MessageActions";
import Modal from "react-bootstrap/Modal";
import { PostWithAuth } from "../../../../infrastructure/HttpClient";
import { PubNubChannels } from "../../../../infrastructure/PubNubChannelEnum";
import PubNubHelper from "../../../../infrastructure/PubNubHelper";
import ResultsView from "../ResultsView/ResultsView.jsx";
import { UserContext } from "../../../../infrastructure/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import { usePubNub } from "pubnub-react";

const EndVote = ({ voteId, enabledVoteWeight, voteDescription, uniquePollId, Messages, downloadReport, reloadVotes, disableReloadVotesButton }) => {
  const { getAccessTokenSilently } = useAuth0();
  const userData = useContext(UserContext);
  const pubNub = usePubNub();
  const [yesTally, setYesTally] = useState(0);
  const [noTally, setNoTally] = useState(0);
  const [voteEnded, setVoteEnded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [votes, setVotes] = useState([]);
  const [voteResults, setVoteResults] = useState({});
  const [ui, render] = useState({});
  const [disableEndVoteButton, setDisableEndVoteButton] = useState(false);

  useEffect(() => {
    updateVotes(Messages);
    setVotes(
      Messages.map((x) => {
        return {
          auth0Id: x.auth0Id,
          voteChoice: x.voteBool,
          voteWeight: x.voteWeight,
        };
      })
    );
    return () => { };
  }, [Messages]);

  const handleModalClose = () => setShowModal(false);
  const handleEndVoteSubmit = () => setShowModal(true);

  const updateVotes = (newArr) => {
    let yes = 0;
    let no = 0;
    // let yesWeighted = 0;
    // let noWeighted = 0;
    newArr.forEach((x) => {
      if (x.voteSelection === "Yes") {
        // yes++;
        yes += x.voteWeight || 1;
      } else {
        // no++
        no += x.voteWeight || 1;
      }
    });
    setYesTally(yes);
    setNoTally(no);
  };

  // check if voting has been end externally show the results.
  useEffect(() => {
    const pnListnerVote = {
      message: (messageEvent) => {
        if (messageEvent) {
          let message = messageEvent.message;
          if (messageEvent.id && messageEvent.action) message = messageEvent;
          if (message) {
            if (message.action === MessageActions.EndVoteResults && !voteEnded) {
              handleModalClose();
              setVoteEnded(true);
              setVoteResults(message.voteResults)
              render({});
            }
          }
        }
      }
    };
    PubNubHelper.DelegateVoteListenerSubscribe(pubNub, userData, pnListnerVote);
    return () => {
      PubNubHelper.DelegateVoteListenerUnsubscribe(
        pubNub,
        userData,
        pnListnerVote
      );
    };
  }, [voteEnded])


  const processEndVote = () => {
    let votesToUpdate = votes;
    return pubNub
      .hereNow({
        channels: [`${PubNubChannels.VoteAdmin}-${userData.eventId}${PubNubHelper.appendSessionIdFromURL()}`],
        includeUUIDs: true,
        includeState: true,
      })
      .then((response) => {
        if (response && response.totalOccupancy) {
          let occupancy = response.channels[
            `${PubNubChannels.VoteAdmin}-${userData.eventId}${PubNubHelper.appendSessionIdFromURL()}`
          ].occupants.map((x) => {
            return {
              auth0Id: x.uuid,
              voteChoice: null,
              voteWeight: 0
            };
          });
          //Get people who subscribed, but didn't vote
          occupancy = occupancy.filter(
            (x) => !votes.some((a) => a.auth0Id === x.auth0Id)
          );
          votesToUpdate.push(...occupancy);
          setVotes(votesToUpdate);
        }
        const voteResults = {
          voteGuid: uniquePollId,
          description: voteDescription,
          enabledVoteWeight: enabledVoteWeight,
          resolutionNumber: voteId,
          eventId: userData.eventId,
          userId: userData.userId,
          voteDecisions: votesToUpdate,
          sessionId: PubNubHelper.getSessionIdFromURL()
        };
        return getAccessTokenSilently()
          .then((accessToken) => {
            return PostWithAuth(
              `/Voting/ValidateVoteResult`,
              voteResults,
              accessToken
            ).then((response) => {
              setVoteResults(response.data);
              handleModalClose();
              setVoteEnded(true);
              PubNubHelper.EndVote(pubNub, userData, voteId, voteDescription).then(() => {
                // broadcast vote result after ending vote, for proper message sequence
                PubNubHelper.BroadcastVoteResults(pubNub, userData, {
                  action: "EndVoteResults",
                  voteResults: response.data,
                });
              });
            });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const endVote = () => {
    setDisableEndVoteButton(true);
    reloadVotes().then(() => {
      processEndVote()
        .then(() => setDisableEndVoteButton(false))
        .catch(() => setDisableEndVoteButton(false));
    });
  };

  return (
    <>
      {showModal && (
        <Modal show={showModal} onHide={handleModalClose} keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>End Vote</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              Are you sure you wish to end the vote? This will disallow any
              further voting and will display vote results. This action cannot
              be undone.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleModalClose()}
              id="do-not-end-vote-button"
            >
              Do Not End Vote
            </Button>
            <Button
              className="end-vote-button"
              variant="contained"
              color="primary"
              disabled={disableEndVoteButton}
              onClick={() => endVote()}
              id="end-vote-button-modal"
            >
              End Vote
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {voteEnded ? (
        <ResultsView
          VoteId={voteId}
          VoteDescription={voteDescription}
          Votes={Messages}
          UniquePollId={uniquePollId}
          VoteResults={voteResults}
          enabledVoteWeight={enabledVoteWeight}
          downloadReport={downloadReport}
        />
      ) : (
        <div className="vote-wrapper">
          <Paper>
            <div className="vote-content" id="vote-results-container">
              <Typography variant="h3" component="h3">
                Results for Vote #: {voteId}
              </Typography>
              <div className="space" />
              <Typography variant="h3" component="h3">
                {voteDescription}
              </Typography>
              <div className="space" />
              <Typography variant="h4" component="h3">
                Vote weight enabled: {enabledVoteWeight ? 'Yes' : 'No'}
              </Typography>
              <div className="space" />
              <Typography variant="h4" component="h4">
                Yes: {yesTally}
              </Typography>
              <div className="space" />
              <Typography variant="h4" component="h4">
                No: {noTally}
              </Typography>

              <div className="space" />
              <Button
                id="end-vote-button"
                variant="contained"
                color="primary"
                onClick={() => {
                  handleEndVoteSubmit();
                }}
              >
                End Vote
              </Button>
              <Button
                id="reload-votes-button"
                variant="contained"
                color="primary"
                disabled={disableReloadVotesButton}
                onClick={() => {
                  reloadVotes();
                }}
              >
                Reload Votes
              </Button>
            </div>
          </Paper>
        </div>
      )}
    </>
  );
};

export default EndVote;
