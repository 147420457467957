import React from "react";
import StartParticipantVote from "../StartParticipantVote/StartParticipantVote";

const BroadcastedResults = ({
  YeaPercentage,
  NayPercentage,
  isBroadcastOn,
}) => {
  return (
    <>
      {!isBroadcastOn ? (
        <StartParticipantVote />
      ) : (
        <div>
          <h1>
            {NayPercentage <= YeaPercentage
              ? "Vote has Passed."
              : "Vote did not Pass."}
          </h1>
          <h3>Yes</h3>
          <h2>{Math.round(YeaPercentage)}%</h2>
          <p></p>
          <h3>No</h3>
          <h2>{Math.round(NayPercentage)}%</h2>
          <p></p>
          <p></p>
        </div>
      )}
    </>
  );
};

export default BroadcastedResults;
