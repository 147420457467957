import PubNubMessageBase from "./PubNubMessageBase";

class TextMessage extends PubNubMessageBase {
  constructor(user, message, messageType, slot, fromPhone, toPhone, delegateUserId, action, podiumNumber) {
    super(user, messageType, podiumNumber);
    this.name = user.name;
    this.locale = user.locale;
    this.chapter = user.chapter;
    this.state = user.state;
    this.slot = slot;
    this.message = message;
    this.fromPhone = fromPhone;
    this.toPhone = toPhone;
    this.delegateUserId = delegateUserId;
    this.action = action;
    this.timestamp = new Date();
  }
}

export default TextMessage;
