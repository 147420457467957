import "./styles.css";

import React, { useContext, useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import { Card } from "react-bootstrap";
import { ConfigurationContext } from "../../infrastructure/ConfigurationContext";
import { FcHighPriority } from "react-icons/fc";
import Form from "react-bootstrap/Form";
import { Get } from "../../infrastructure/HttpClient";
import Grid from "@material-ui/core/Grid";
import LockCredentials from "./LockCredentials";
import Modal from "react-bootstrap/Modal";
import PageNotFound from "../../infrastructure/PageNotFound";
import ValidationSchema from "../Formik";
import logo from "../../images/UnionCheck.png";

const UnionCheck = () => {
  const activeConfig = useContext(ConfigurationContext);
  const config = activeConfig.configData;
  const [email, setEmail] = useState("");
  const [unionId, setUnionId] = useState("");

  const [isVerified, setIsVerified] = useState(false);
  const [results, setResults] = useState([]);
  const [checkFailed, setCheckFailed] = useState(false);
  const isCredentialsLock = !config.credentialingEnabled;
  const [isRegistered, setIsRegistered] = useState(false);
  const [isUserRegistered, setIsUserRegistered] = useState(false);
  const [isPageNotFound, setIsPageNotFound] = useState(false);
  const [helpDesk, setHelpDesk] = useState(
    "Please contact our help desk at xxx-xxx-xxxx"
  );

  const submitCredentials = async () => {
      try {
      const result = await Get(
          `/UserAccount/CheckForValidUser?membershipId=${encodeURIComponent(unionId)}&email=${encodeURIComponent(
          email
        )}&eventId=${config.eventId}`
      );

      if (result.status === 200) {
        setResults(result.data);

        if (result.data.auth0Id) {
          setIsRegistered(true);
        } else {
          setIsVerified(true);
        }
      }
    } catch (err) {
      setCheckFailed(true);
    }
  };

  useEffect(() => {
    if (config) {
      setHelpDesk(
        `Please contact ${config.pageTitle}'s Convention Helpdesk at ${config.helpDeskEmail} or ${config.helpDeskPhone}.`
      );
    } else {
      setIsPageNotFound(true);
    }
  }, []);
  
  return (
    <div>
      {isPageNotFound && <PageNotFound />}
      {isCredentialsLock && !isPageNotFound && <LockCredentials />}
      {!isCredentialsLock && !isPageNotFound && (
        <Grid container style={{ justifyContent: "center", marginTop: "30px" }}>
          <Grid item sm={12} xs={12} md={7} lg={6}>
            {!isVerified && (
              <div className="reg-card-wrapper">
                <Card
                  className="h-90 shadow-sm bg-white rounded  "
                  style={{ width: "300px", margin: "auto" }}
                >
                  <Card.Body className="d-flex flex-column">
                    <div className="d-flex mb-2 justify-content-between">
                      <Card.Title className="mb-0 font-weight-bold">
                        <img src={logo} width="50" height="50" />
                        Union Check
                      </Card.Title>
                    </div>
                    <div id="union-id-container">
                      <Card.Text className="text-secondary">
                        {`Please enter your email and ${config.uiConfigs.label_union_id_txt}.`}
                      </Card.Text>
                      <Form>
                        <Form.Group>
                          <Form.Label>Email address</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter email"
                            onChange={(e) => setEmail(e.target.value)}
                            id="email-field"
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>
                            {config.uiConfigs.label_union_id_txt}
                          </Form.Label>
                          <Form.Control
                            placeholder={config.uiConfigs.label_union_id_txt}
                            onChange={(e) => setUnionId(e.target.value)}
                            id="union-membership-id-field"
                          />
                        </Form.Group>
                        <Button
                          variant="success"
                          onClick={submitCredentials}
                          id="go-button"
                        >
                          Submit
                        </Button>
                      </Form>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            )}
            {isRegistered && (
              <Modal
                show={isRegistered}
                onHide={() => {
                  setIsRegistered(false);
                }}
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <FcHighPriority />
                    You have already registered.
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Would you like to update your credentials?
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setIsRegistered(false);
                      setIsVerified(true);
                      setIsUserRegistered(true);
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setIsRegistered(false);
                    }}
                  >
                    No
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
            {checkFailed && (
              <Modal
                show={checkFailed}
                onHide={() => {
                  setCheckFailed(false);
                }}
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <FcHighPriority />
                    The information you entered does not match our records,
                    please try again.
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>{helpDesk}</Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setCheckFailed(false);
                    }}
                  >
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
            {isVerified && (
              <ValidationSchema
                results={results}
                isUserRegistered={isUserRegistered}
                connectionPath={config.auth0Connection}
              />
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default UnionCheck;
