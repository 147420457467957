import React, {useState, useContext} from "react";
import Redirect from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {ConfigurationContext} from "../../infrastructure/ConfigurationContext";

const styles = {
    paperContainer: {
        backgroundColor: "lightgrey",
    },
};

const RegistrationConfirmation = (props) => {
    const activeConfig = useContext(ConfigurationContext);
    const [eventTitle, setEventTitle] = useState(
        activeConfig.configData.confirmationMessage
    );

    return (
        <Grid container alignItems="center" justify="center">
            <Grid style={styles.paperContainer}>
                <div className="inner-container">
                    <Card>
                        <CardContent>
                            <Typography
                                variant="h4"
                                align="center"
                                justifyContent="center"
                                gutterBottom
                            >
                                You have successfully been registered for the {eventTitle}.
                            </Typography>
                            <Typography
                                variant="h6"
                                align="center"
                                justifyContent="center"
                                color="textSecondary"
                            >
                                You will receive email instructions on how to access the event
                                page before the event.
                            </Typography>
                            <Typography
                                variant="h6"
                                align="center"
                                justifyContent="center"
                                color="textSecondary"
                            />
                            <Typography
                                variant="h6"
                                align="center"
                                justifyContent="center">
                                You will be directed to the home page in 5 seconds...
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
            </Grid>
        </Grid>
    );
};

export default RegistrationConfirmation;
