import React, { useEffect, useState} from 'react';
import { PostUserImport } from '../../infrastructure/HttpClient';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText
} from "@material-ui/core";
import "./styles.css";
import {useAuth0} from "@auth0/auth0-react";


const UserImport = () => {
    const url = window.location;
    const eventGuid = new URLSearchParams(url.search).get('event');
    const [csvData, setCsvData] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [open, setOpen] = useState(false);
    const { getAccessTokenSilently } = useAuth0();
    const [token, setToken] = useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const submitFile = async () => {
        try {
            await PostUserImport(eventGuid, csvData, token,)
            document.getElementById('csvFile').value = null;
        } catch (error) {
            if(error.response.data.Message){
            setErrorMessage(error.response.data.Message);
            } else if(error.response.data.Error){
                setErrorMessage(error.response.data.Error)
                console.log(error.response.data)
            } else{
                setErrorMessage("The request failed, please check the file")
                console.log(error.response.data)
            }
        }
        handleClickOpen();
    }
    useEffect(() => {
        getAccessTokenSilently().then((res) => {
            setToken(res);
        });

        if (!token) return;
    }, [token, getAccessTokenSilently]);

        return (
            <div className="div">
                <input className="csvStyle" id="csvFile" type="file" name="file"
                       onChange={(e) => setCsvData(e.target.files[0])}/>
                <Button className="button" variant="contained" color="primary" onClick={submitFile}>Submit
                    File
                </Button>
                {(errorMessage && errorMessage !== "") ? (<Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Errors</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {errorMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>) : (<Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Successful</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You have successfully submitted your CSV file!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>)}
            </div>
        );
}
export default UserImport;
