import { useParams } from "react-router-dom";
import React, { useState, useEffect, useRef, useContext } from "react";
import GetDevice from "../../infrastructure/TwilioDeviceHook";
import { usePubNub } from "pubnub-react";
import { MessageActions } from "../../infrastructure/MessageActions";
import "./styles.css";
import { GetWithAuth, PostWithAuth } from "../../infrastructure/HttpClient";
import { useAuth0 } from "@auth0/auth0-react";
import getConstants from "../../infrastructure/Constants";
import PubNubHelper from "../../infrastructure/PubNubHelper";
import { Button, FormControl, Select, InputLabel } from "@material-ui/core";
import { UserContext } from "../../infrastructure/UserContext";
import { ConfigurationContext } from "../../infrastructure/ConfigurationContext";

function PhoneOperator() {
  const activeConfig = useContext(ConfigurationContext);
  const userData = useContext(UserContext);
  const params = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const device = GetDevice();
  const pubNub = usePubNub();
  const [callState, setCallState] = useState("Disconnected");
  const callStateRef = useRef(callState);
  callStateRef.current = callState;
  const [deviceConfigured, configureDevice] = useState(false);
  const incomingMessage = useRef({});
  const [audioInputs, setAudio] = useState([]);
  const [audioOutputs, setOutputs] = useState([]);
  const [selectedInput, setSelectedInput] = useState("default");
  const [selectedOutput, setSelectedOutput] = useState("default");

  const getSessionIdForAgentName = (sessionId) => {
    return sessionId ? ('-' + sessionId) : '';
  }

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then(() => {
      navigator.mediaDevices.enumerateDevices().then(function (devices) {
        let outputs = [];
        let inputs = [];
        devices.forEach(function (device, key) {
          let obj = { label: device.label, id: device.deviceId };
          if (device.kind === "audioinput") {
            inputs.push(obj);
            setAudio(inputs);
          } else if (device.kind === "audiooutput") {
            outputs.push(obj);
            setOutputs(outputs);
          }
        });
      });
    });
  }, []);

  useEffect(() => {
    if (!userData) return;
    let timer = null;
    let isMounted = true;
    if (params.slot > 9 || params.slot < 1) throw new Error("Invalid Slot!!!");

    const pnListener = {
      message: (messageEvent) => {
        let message = messageEvent.message;
        // if Queue is clear then Disconnect and return
        if (incomingMessage.current &&
          incomingMessage.current.slot &&
          callStateRef.current !== "Disconnected" &&
          message.messageType === "ResolutionQueue" &&
          message.action === "Clear") {
          device.disconnectAll();
          // Disconnected PnMessage
          PubNubHelper.SetCallState(
            pubNub,
            incomingMessage.current,
            MessageActions.UpdateCallStatus,
            "Disconnected",
            `PhoneStage${parseInt(params.slot) - 1}`
          );
          setCallState("Disconnected");
          callStateRef.current = "Disconnected";
          return;
        }

        if (incomingMessage.current &&
          incomingMessage.current.slot &&
          callStateRef.current !== "Disconnected" &&
          message.messageType === "ResolutionQueue" &&
          message.action === "PauseAndClear" &&  (!message.lockedUserIds ||  !message.lockedUserIds.includes(incomingMessage.current.userId))) {
          device.disconnectAll();
          // Disconnected PnMessage
          PubNubHelper.SetCallState(
            pubNub,
            incomingMessage.current,
            MessageActions.UpdateCallStatus,
            "Disconnected",
            `PhoneStage${parseInt(params.slot) - 1}`
          );
          setCallState("Disconnected");
          callStateRef.current = "Disconnected";
          return;
        }
        // if message is not for current operator slot then do not process message
        if (`PhoneStage${parseInt(params.slot) - 1}` !== message.slot) return;
        incomingMessage.current = message;
        if (isMounted) {
          // noinspection JSUnreachableSwitchBranches
          switch (message.messageType) {
            case "Connected":
            case MessageActions.BeginCall: {
              if (`PhoneStage${parseInt(params.slot) - 1}` !== message.slot) break;
              // Don't call people if we have someone on the line or are already calling
              if (
                callStateRef.current === "Connected" ||
                callStateRef.current === "Dialing"
              )
                break;
              setCallState("Dialing");
              callStateRef.current = "Dialing";
              const httpParams = {
                to: "+1" + message.phoneNumber,
                agent: `PhoneStage${parseInt(params.slot) - 1}${getConstants().Stage}-${userData.eventId}${getSessionIdForAgentName(params.sessionId)}`,
              };
              getAccessTokenSilently()
                .then((accessToken) => {
                  PostWithAuth(
                    `/Voip/CallWithAMD/${activeConfig.configData.eventId}`,
                    httpParams,
                    accessToken
                  ).then((response) => {
                    // Dialing PnMessage
                    PubNubHelper.SetCallState(
                      pubNub,
                      message,
                      MessageActions.UpdateCallStatus,
                      "Dialing",
                      `PhoneStage${parseInt(params.slot) - 1}`
                    );
                    setCallState("Dialing");
                    callStateRef.current = "Dialing";
                    timer = setTimeout(() => {
                      if (
                        // eslint-disable-next-line no-constant-condition
                        callStateRef.current === "Connected" ||
                        "Disconnected" ||
                        "Ready"
                      )
                        return;
                      // Failed PnMessage
                      PubNubHelper.SetCallState(
                        pubNub,
                        message,
                        MessageActions.UpdateCallStatus,
                        "Failed",
                        `PhoneStage${parseInt(params.slot) - 1}`
                      );
                      setCallState("Failed");
                      callStateRef.current = "Failed";
                    }, 15000);
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
              break;
            }
            case MessageActions.EndCall: {
              console.log("END CALL" + JSON.stringify(message));
              if (`PhoneStage${parseInt(params.slot) - 1}` !== message.slot) break;
              device.disconnectAll();
              // Disconnected PnMessage
              PubNubHelper.SetCallState(
                pubNub,
                message,
                MessageActions.UpdateCallStatus,
                "Disconnected",
                `PhoneStage${parseInt(params.slot) - 1}`
              );
              setCallState("Disconnected");
              callStateRef.current = "Disconnected";
              break;
            }
          }
        }
      },
    };

    PubNubHelper.OperatorListenerSubscribe(pubNub, userData, pnListener);

    if (isMounted && device && !deviceConfigured) {
      getAccessTokenSilently()
        .then((accessToken) => {
          GetWithAuth(
            `/Voip/PhoneStage${parseInt(params.slot) - 1}${getConstants().Stage}-${userData.eventId}${getSessionIdForAgentName(params.sessionId)}`,
            accessToken
          ).then((response) => {
            device.destroy();
            device.setup(response.data);
            device.on("ready", function () {
              configureDevice(true);
              device.audio.setInputDevice(selectedInput);
              device.audio.speakerDevices.set(selectedOutput);
              device.on("incoming", function (connection) {
                console.log("connection", connection);
                connection.accept();
                console.log("timer", timer);
                clearTimeout(timer);
                // Dialing PnMessage
                PubNubHelper.SetCallState(
                  pubNub,
                  incomingMessage.current,
                  MessageActions.UpdateCallStatus,
                  "Connected",
                  `PhoneStage${parseInt(params.slot) - 1}`
                );
                setCallState("Connected");
                callStateRef.current = "Connected";
              });
              // On call disconnected handler
              device.on("disconnect", function (connection) {
                clearTimeout(timer);
                // Disconnected PnMessage
                PubNubHelper.SetCallState(
                  pubNub,
                  incomingMessage.current,
                  MessageActions.UpdateCallStatus,
                  "Disconnected",
                  `PhoneStage${parseInt(params.slot) - 1}`
                );
                setCallState("Disconnected");
                callStateRef.current = "Disconnected";
              });
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return () => {
      PubNubHelper.OperatorListenerUnsubscribe(pubNub, userData, pnListener);
      isMounted = false;
      clearTimeout(timer);
    };
  }, [
    selectedInput,
    selectedOutput,
    audioOutputs,
    audioInputs,
    deviceConfigured,
  ]);

  const setReady = (status) => {
    PubNubHelper.SetCallState(
      pubNub,
      incomingMessage.current,
      MessageActions.UpdateCallStatus,
      status,
      `PhoneStage${parseInt(params.slot) - 1}`
    );
    setCallState(status);
  };

  const setInput = (e) => {
    setSelectedInput(e.target.value);
    configureDevice(false);
  };
  const setOutput = (e) => {
    setSelectedOutput(e.target.value);
    configureDevice(false);
  };
  return (
    <>
      <div className="opUI">Phone Staging Slot: {parseInt(params.slot)}</div>
      <div className="opUI">Call Status: {callState}</div>
      <div className="op-wrapper">
        <div className="field-wrapper">
          <Button
            variant="contained"
            color="primary"
            onClick={() => setReady("Ready")}
            disabled={callState === "Ready"}
          >
            Set as ready
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setReady("Failed")}
            disabled={callState === "Failed"}
          >
            Not Ready
          </Button>
        </div>

        <FormControl>
          <InputLabel>Audio Input</InputLabel>
          <Select value={selectedInput} onChange={(e) => setInput(e)}>
            {audioInputs.map((input) => (
              <option value={input.id}>{input.label}</option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel>Audio Output</InputLabel>
          <Select value={selectedOutput} onChange={(e) => setOutput(e)}>
            {audioOutputs.map((output) => (
              <option value={output.id}>{output.label}</option>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
}

export default PhoneOperator;
