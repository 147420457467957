import React, { useContext, useEffect, useRef, useState } from "react";

import { ConfigurationContext } from "../../infrastructure/ConfigurationContext";
import { MessageActions } from "../../infrastructure/MessageActions";
import { Permissions } from "../../infrastructure/RbacEnum";
import PubNubHelper from "../../infrastructure/PubNubHelper";
import { PutWithAuth } from "../../infrastructure/HttpClient";
import { SessionConfigurationContext } from "../../infrastructure/SessionConfigurationContext";
import { UserContext } from "../../infrastructure/UserContext";
import WaitingRoom from "./WaitingRoom";
import { useAuth0 } from "@auth0/auth0-react";
import { usePubNub } from "pubnub-react";

const WaitingRoomListener = () => {
  const pubNub = usePubNub();
  const activeConfig = useContext(ConfigurationContext);
  const sessionConfig = useContext(SessionConfigurationContext);
  const userData = useContext(UserContext);
  const [ui, render] = useState({});
  const { getAccessTokenSilently } = useAuth0();

  // History Hooks
  const waitingRoomEnabled = useRef(
    sessionConfig
      ? sessionConfig.configData.isWaitingRoomEnabled
      : activeConfig.configData.waitingRoomEnabled
  );
  const waitingRoomContent = useRef(
    sessionConfig
      ? sessionConfig.configData.waitingRoomInfo
      : activeConfig.configData.waitingRoomContent
  );

  // 245646 Resource management on enabling waiting room - Note - This attendance call is not required, as we are recording attendance every 10 min
  // useEffect(() => {
  //   // 240694 Do not record attendance for users with moderator permissions
  //   if (userData && userData.permissions.indexOf(Permissions.Moderate) < 0) {
  //     getAccessTokenSilently()
  //       .then((accessToken) => {
  //         PutWithAuth(`UserAccount/RecordAttendance`,
  //           {
  //             userId: userData.id,
  //             eventId: activeConfig.configData.eventId,
  //             sessionId: sessionConfig ? sessionConfig.configData.id : 0
  //           }, accessToken);
  //       });
  //   }
  // }, [userData]);

  useEffect(() => {
    if (!userData) return;
    const pnListenerWaitingRoom = {
      message: (messageEvent) => {
        if (messageEvent) {
          let message = messageEvent.message;
          if (message) {
            switch (message.action) {
              case MessageActions.WaitingRoom: {
                waitingRoomEnabled.current = message.waitingRoomEnabled;
                waitingRoomContent.current = message.waitingRoomContent;
                render({});
                break;
              }
              case MessageActions.RefreshPage: {
                window.location.reload(true);
                break;
              }
            }
            render({});
          }
        }
      },
    };
    // WaitingRoomSubscribe subscribe for current event/session
    PubNubHelper.WaitingRoomSubscribe(
      pubNub,
      userData,
      pnListenerWaitingRoom
    );

    return () => {
      PubNubHelper.WaitingRoomUnsubscribe(
        pubNub,
        userData,
        pnListenerWaitingRoom
      );
    };
  }, [userData]);

  return (
    <WaitingRoom
      waitingRoomEnabled={waitingRoomEnabled.current}
      waitingRoomContent={waitingRoomContent.current}
    />
  );
};

export default WaitingRoomListener;
