import React, { useContext, useState, useEffect } from "react";
import ActionWrapper from "../ActionWrapper";
import "./styles.css";
import { DragDropContext } from "react-beautiful-dnd";
import StageView from "../ActionWrapper/StageView";
import TextStageView from "../ActionWrapper/TextStageView";
import { UserContext } from "../../infrastructure/UserContext";
import { Button, Typography, TextField, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Can from "../Can";
import { Permissions } from "../../infrastructure/RbacEnum";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function ModeratorQueue({
  messages,
  input,
  resolution,
  clearQueues,
  clearPointOfOrder,
  clearPointOfInformation,
  setInput,
  submitResolution,
  onDragEnd,
  isLoaded,
  stageStatuses,
  stagePodiumNumbers,
  isPubnubDown,
  pausePubnubMessages,
  disablePausePubnubMessages,
  resumePubnubMessages,
  disableResumePubnubMessages,
  reloadPubnubMessages,
  disableReloadPubnubMessages,
  onCardColorChange,
  colorCode
}) {
  const [showPubnubDisconnected, setShowPubnubDisconnected] = useState(false);
  useEffect(() => {
    if (isPubnubDown) {
      setShowPubnubDisconnected(true);
    } else {
      setShowPubnubDisconnected(false);
    }
  }, [isPubnubDown]);
  const userData = useContext(UserContext);

  const handleClose = () => {
    setShowPubnubDisconnected(false);
  };
  return (
    <div className="queue-container">
      <Snackbar
        open={showPubnubDisconnected}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={handleClose}
      >
        <Alert severity="warning" onClose={handleClose}>
          PubNub Disconnected
        </Alert>
      </Snackbar>
      <Can
        permissions={userData?.permissions}
        task={Permissions.Moderate}
        yes={() => (
          <>
            <div className="input-wrapper">
              <Button
                id="clear-queue-button"
                variant="contained"
                color="secondary"
                onClick={clearQueues}
              >
                Clear Queue
              </Button>
              <Button
                id="pause-queue-button"
                variant="contained"
                color="secondary"
                disabled={disablePausePubnubMessages}
                onClick={pausePubnubMessages}
              >
                Pause Queues
              </Button>
              <Button
                id="resume-queue-button"
                variant="contained"
                color="primary"
                disabled={disableResumePubnubMessages}
                onClick={resumePubnubMessages}
              >
                Resume Resolution Queues
              </Button>
              <Button
                id="reload-queue-button"
                variant="contained"
                color="primary"
                disabled={disableReloadPubnubMessages}
                onClick={reloadPubnubMessages}
              >
              Reload Queues
              </Button>
              <div className="header">
                <Typography variant="h5">{resolution}</Typography>
                <TextField
                  id="resolution-text"
                  variant="outlined"
                  size="small"
                  onChange={(e) => setInput(e.target.value)}
                  value={input}
                  inputProps={{ maxLength: 50 }}
                />
                <Button
                  id="submit-button"
                  variant="contained"
                  color="primary"
                  onClick={submitResolution}
                >
                  Submit Resolution
                </Button>
              </div>
            </div>

            <DragDropContext onDragEnd={onDragEnd}>
              <div className="wrapper">
                <div className="action-wrapper">
                  <ActionWrapper
                    actionType="Support"
                    colorCode={colorCode}
                    onCardColorChange={onCardColorChange}
                    messages={messages.filter((x) => x.queue === "Support")}
                  />
                  <ActionWrapper
                    actionType="Oppose"
                    colorCode={colorCode}
                    onCardColorChange={onCardColorChange}
                    messages={messages.filter((x) => x.queue === "Oppose")}
                  />


                  <div className="production-wrapper">
                    <ActionWrapper
                      actionType="Point of Order"
                      colorCode={colorCode}
                      onCardColorChange={onCardColorChange}
                      messages={messages.filter(
                      (x) => x.queue === "Point of Order"
                      )}
                      clearPointOfOrder={clearPointOfOrder}
                    />
                    <ActionWrapper
                      actionType="Point of Information"
                      colorCode={colorCode}
                      onCardColorChange={onCardColorChange}
                      messages={messages.filter(
                      (x) => x.queue === "Point of Information"
                      )}
                      clearPointOfInformation={clearPointOfInformation}
                    />
                  </div>
                  <ActionWrapper
                    actionType="Production"
                    colorCode={colorCode}
                    onCardColorChange={onCardColorChange}
                    messages={messages.filter((x) => x.queue === "Production")}
                  />
                </div>
                <div className="production-wrapper">
                  <StageView
                    actionType="PhoneStage"
                    colorCode={colorCode}
                    onCardColorChange={onCardColorChange}
                    messages={messages.filter((x) => x.queue.includes("Phone"))}
                    isLoaded={isLoaded}
                    stageStatuses={stageStatuses}
                    stagePodiumNumbers={stagePodiumNumbers}
                  />
                  <TextStageView
                    actionType="TextStage"
                    colorCode={colorCode}
                    onCardColorChange={onCardColorChange}
                    messages={messages.filter((x) => x.queue.includes("Text"))}
                    isLoaded={isLoaded}
                    stageStatuses={stageStatuses}
                    stagePodiumNumbers={stagePodiumNumbers}
                  />
                </div>
              </div>
            </DragDropContext>
          </>
        )}
      />
    </div>
  );
}

export default ModeratorQueue;
