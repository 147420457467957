import React, { useContext } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { ConfigurationContext } from "./ConfigurationContext";
import { useHistory } from "react-router-dom";
import getConstants from "./Constants";

const Auth0ProviderWithHistory = ({ children }) => {
  const activeConfig = useContext(ConfigurationContext);
  let route = "demo";
  if (
    activeConfig &&
    activeConfig.configData &&
    activeConfig.configData.route
  ) {
    route = activeConfig.configData.route;
  }
  const redirectUri = `${window.location.origin}/${route}`;
  const history = useHistory();

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={getConstants().Auth0Url}
      clientId={activeConfig.configData.auth0ClientId}
      customLogo={activeConfig.configData.auth0CustomLogo}
      customTitle={activeConfig.configData.auth0CustomTitle}
      customConnection={activeConfig.configData.auth0Connection}
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}
      audience={`${getConstants().ApiUrl}/`}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
