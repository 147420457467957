import React from "react";
import { Route, useLocation } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";

const PrivateRoute = ({ component, ...args }) => {
  let location = useLocation();
  const redirectUri = `${window.location.origin}${location.pathname}`;

  return (
    <Route
      component={withAuthenticationRequired(component, {
        //TODO: we can add a loading token here to let the user know something is happening
        //   onRedirecting: () => <Loading />,
      })}
      {...args}
    />
  );
};

export default PrivateRoute;
