import React from "react";
import "./styles.css";

const BroadcastView = ({ currentSpeaker }) => {
  return (
    <div className="overall-wrapper">
      <div className="broadcast-wrapper">
        <div id="currentSpeaker" >{currentSpeaker?.name}</div>
        <div className="location-wrapper">
          <div id="currentSpeakerChapter">{currentSpeaker?.chapter}</div>
          <div> | </div>
          <div id="currentSpeakerLocale">{currentSpeaker?.locale}</div>
          <div> | </div>
          <div id="currentSpeakerState">{currentSpeaker?.state}</div>
        </div>
      </div>
    </div>
  );
};

export default BroadcastView;
