import React, { useEffect, useState, useContext } from "react";
import VoteSelection from "../VoteSelection/VoteSelection.jsx";
import BroadcastedResults from "../BroadcastedResults/BroadcastedResults.jsx";
import "./StartParticipantVote.css";
import { usePubNub } from "pubnub-react";
import { UserContext } from "../../../../infrastructure/UserContext";
import { Typography } from "@material-ui/core";
import { MessageActions } from "../../../../infrastructure/MessageActions";
import PubNubHelper from "../../../../infrastructure/PubNubHelper";

const StartParticipantVote = () => {
  const pubNub = usePubNub();
  const userData = useContext(UserContext);
  const [NayPercent, setNayPercent] = useState(0);
  const [YeaPercent, setYeaPercent] = useState(0);
  const [isBroadcastOn, setIsBroadcastOn] = useState(false);
  const [active, setActive] = useState(false);
  const [voteQueueData, setVoteQueueData] = useState({});

  useEffect(() => {
    if (!userData) return;
    let isMounted = true;
    const pnListener = {
      message: (messageEvent) => {
        if (
          isMounted &&
          messageEvent.message.isBroadcastOn !== null &&
          messageEvent.message.isBroadcastOn !== undefined
        ) {
          setIsBroadcastOn(messageEvent.message.isBroadcastOn);
          setYeaPercent(messageEvent.message.YeaPercent);
          setNayPercent(messageEvent.message.NayPercent);
        } else {
          if (
            messageEvent.message.messageType === MessageActions.InitiateVote &&
            isMounted
          ) {
            setVoteQueueData(messageEvent.message);
            setActive(true);
          } else if (
            messageEvent.message.messageType === MessageActions.EndVote &&
            isMounted
          ) {
            setVoteQueueData({});
            setActive(false);
          }
        }
      },
    };

    PubNubHelper.StartVoteListenerSubscribe(pubNub, userData, pnListener);

    //TODO: Enable after refactoring of voting
    // if(test !== null && test !== undefined && isMounted && !active){
    //   let historyVotes = test.filter(x => ( x.messageType !== null && x.messageType !== undefined && x.messageType == "CastVoteMessage"));
    //   let currentVote = historyVotes.find(x => (x.))

    //   setVoteId(messageEvent.message.resolutionNumber);
    //   setVoteDescription(messageEvent.message.description);
    //   setActive(true);
    // }

    return () => {
      PubNubHelper.StartVoteListenerUnsubscribe(pubNub, userData, pnListener);
      isMounted = false;
    };
  });

  if (active) {
    return <VoteSelection UserData={userData} VoteQueue={voteQueueData} />;
  } else if (isBroadcastOn) {
    return (
      <BroadcastedResults
        isBroadcastOn={isBroadcastOn}
        YeaPercentage={YeaPercent}
        NayPercentage={NayPercent}
      />
    );
  } else {
    return (
      <div
        style={{
          height: "200px",
          width: "230px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6">No vote currently active</Typography>
      </div>
    );
  }
};

export default StartParticipantVote;
