import { useParams } from "react-router-dom";
import React, { useState, useEffect, useRef, useContext } from "react";
import GetDevice from "../../infrastructure/TwilioDeviceHook";
import { usePubNub } from "pubnub-react";
import { MessageActions } from "../../infrastructure/MessageActions";
import "./styles.css";
import { GetWithAuth, PostWithAuth, PostUrlFormEncodedWithAuth } from "../../infrastructure/HttpClient";
import { useAuth0 } from "@auth0/auth0-react";
import getConstants from "../../infrastructure/Constants";
import PubNubHelper from "../../infrastructure/PubNubHelper";
import { Button, FormControl, Select, InputLabel, TextField, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { UserContext } from "../../infrastructure/UserContext";
import { ConfigurationContext } from "../../infrastructure/ConfigurationContext";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { getToPhoneLocal } from "../../infrastructure/GetToPhoneLocal";
import Pubnub from "pubnub";

const useStyles = makeStyles({
  shadow: {
    mozBoxShadow: "3px 3px 5px 6px #ccc",
    webkitBoxShadow: "3px 3px 5px 6px #ccc",
    boxShadow: "3px 3px 5px 6px #ccc",
  },
  properCase: {
    textTransform: "capitalize",
  },
  whiteText: {
    color: "#FFFFFF",
  },
  backingCard: {
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 50,
    margin: 10,
  },
  backdropMessage: {
    borderRadius: 25,
    padding: 10,
  },
  button: {
    margin: 5,
    color: "#fff",
    "&:disabled": { color: "rgb(0 0 0 / 0.26)" },
    width: 166,
  },
  documentLink: {
    marginTop: 10,
  },
  headers: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#666",
    // height: "35px",
  },
  white: {
    color: "#000000",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
  grey: {
    color: "rgba(0,0,0,0.23)",
    backgroundColor: "rgba(0,0,0,0.12)",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.12)",
    },
  },
  darkGrey: {
    color: "#fff",
    backgroundColor: "#808080",
    "&:hover": {
      backgroundColor: "#808080",
    },
  },
  blue: {
    backgroundColor: "#3f51b5",
    "&:hover": {
      backgroundColor: "#3f51b5",
    },
    "&:focus": {
      backgroundColor: "#3f51b5",
    },
    "&:active": {
      backgroundColor: "#3f51b5",
    },
    "&.selected": {
      backgroundColor: "#3f51b5 !important",
    }
  },
  teal: {
    backgroundColor: "#3fb595",
    "&:hover": {
      backgroundColor: "#3fb595",
    },
    "&:focus": {
      backgroundColor: "#3fb595",
    },
    "&:active": {
      backgroundColor: "#3fb595",
    },
    "&.selected": {
      backgroundColor: "#3fb595 !important",
    }
  },
  orange: {
    color: "#fff",
    backgroundColor: "#f56749",
    "&:hover": {
      backgroundColor: "#FF9933"
    },
    "&:focus": {
      backgroundColor: "#f56749",
    },
    "&:active": {
      backgroundColor: "#f56749",
    },
    "&.selected": {
      backgroundColor: "#f56749 !important",
    }
  },
  lightblue: {
    color: "#fff",
    backgroundColor: "#8ecef1",
    "&:hover": {
      backgroundColor: "#8ecef1",
    },
  },
  lightGreen: {
    color: "#fff",
    backgroundColor: "#9FCC00",
    "&:hover": {
      backgroundColor: "#baef00",
    },
  },
  pushers: {
    fontSize: "0.9rem",
    padding: "8px",
  },
  dialogTitle: {
    display: "flex",
    "justify-content": "space-between",
    "align-items": "center",
  },
});


const TextOperator = (props) => {
  const classes = useStyles();
  const activeConfig = useContext(ConfigurationContext);
  const userData = useContext(UserContext);
  const params = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const device = GetDevice();
  const pubNub = usePubNub();
  const [callState, setCallState] = useState("Disconnected");
  const callStateRef = useRef(callState);
  callStateRef.current = callState;
  const [deviceConfigured, configureDevice] = useState(false);
  const incomingMessage = useRef({});
  const [audioInputs, setAudio] = useState([]);
  const [audioOutputs, setOutputs] = useState([]);
  const [selectedInput, setSelectedInput] = useState("default");
  const [selectedOutput, setSelectedOutput] = useState("default");
  const [podiumNumber, setPodiumNumber] = useState("");
  const [toPhone, setToPhone] = useState("default");
  const [fromPhone, setFromPhone] = useState("default");
  const [body, setBody] = useState("default");
  const [messagesSentToDelegate, setMessagesSentToDelegate] = useState([]);
  const [currentMessageNumber, setCurrentMessageNumber] = useState(0);
  const [serverPhoneNumber, setServerPhoneNumber] = useState(activeConfig.configData.twilioPhoneNumber);

  const [delegateUserId, _setDelegateUserId] = React.useState(0);
  const delegateUserIdRef = React.useRef(delegateUserId);
  const setDelegateUserId = data => {
    delegateUserIdRef.current = data;
    _setDelegateUserId(data);
  }

  const [delegatePhoneNumber, _setDelegatePhoneNumber] = React.useState('default');
  const delegatePhoneNumberRef = React.useRef(delegatePhoneNumber);
  const setDelegatePhoneNumber = data => {
    delegatePhoneNumberRef.current = data;
    _setDelegatePhoneNumber(data);
  };

  const [messagesReceivedFromDelegate, setMessagesReceivedFromDelegate] = useState([]);
  const [smsConnected, setSmsConnected] = useState(false);

  const getSessionIdForAgentName = (sessionId) => {
    return sessionId ? ('-' + sessionId) : '';
  }

  const textToDelegate = (body) => {
    // console.log(currentMessageNumber);
    setBody(body);
    setToPhone(delegatePhoneNumberRef.current);
    setFromPhone(serverPhoneNumber);
    setCurrentMessageNumber(currentMessageNumber + 1);
  };

  useEffect(() => {
    if (currentMessageNumber === 0) return;

    let toPhoneLocal = getToPhoneLocal(toPhone);
    let fromPhoneLocal = getToPhoneLocal(serverPhoneNumber);

    let details = {
      operatorLineNumber: params.slot,
      fromPhone: fromPhoneLocal,
      toPhone: toPhoneLocal,
      delegateId: delegateUserIdRef.current /*userData.id*/,
      messageBody: body,
      eventId: userData.eventId,
      sessionId: params.sessionId ? params.sessionId : ''
    };
    getAccessTokenSilently()
      .then((accessToken) => {
        PostUrlFormEncodedWithAuth('/Text/SendMessage', details, accessToken)
          .then((response) => {
            if (response != null && response != undefined) {
              if (response.data && response.data.isSuccess) {
                details['messageSid'] = response.data.sid;
                details['timestamp'] = new Date();
                let newArray = messagesSentToDelegate.slice(0);
                newArray.push(details);
                setMessagesSentToDelegate(newArray);
                console.log(`Success: Data returned by caller ${toPhoneLocal} is ${response['data']}`);
                console.log(messagesSentToDelegate);
              } else if (response.data && response.data.error) {
                window.alert("Error occured while sending the message, Please check the delegate phone number or Event twilio phone number.\nInternal Error:" + response.data.error);
              }
            }
          })
          .catch((e) => {
            details['messageSid'] = currentMessageNumber;
            details['messageBody'] = 'Failure: ' + details['messageBody'];
            details['timestamp'] = new Date();
            let newArray = messagesSentToDelegate.slice(0);
            newArray.push(details);
            setMessagesSentToDelegate(newArray);

            console.error(`Failure: ${e}`);
            console.log(messagesSentToDelegate);
          });
      });

  }, [currentMessageNumber]);

  useEffect(() => {
    if (!userData) return;
    let timer = null;
    let isMounted = true;
    if (params.slot > 18 || params.slot < 10) throw new Error("Invalid Slot!!!");

    const pnListener = {
      message: (messageEvent) => {
        let message = messageEvent.message;
        // if Queue is clear then Disconnect and return
        if (incomingMessage.current &&
          incomingMessage.current.slot &&
          callStateRef.current !== "Disconnected" &&
          message.messageType === "ResolutionQueue" &&
          message.action === "Clear") {
          // device.disconnectAll();
          // Disconnected PnMessage
          PubNubHelper.SetCallState(
            pubNub,
            incomingMessage.current,
            MessageActions.UpdateCallStatus,
            "Disconnected",
            `TextStage${parseInt(params.slot) - 1}`
          );
          setCallState("Disconnected");
          callStateRef.current = "Disconnected";
          hangUp();
          return;
        }
        if (incomingMessage.current &&
          incomingMessage.current.slot &&
          callStateRef.current !== "Disconnected" &&
          message.messageType === "ResolutionQueue" &&
          message.action === "PauseAndClear" && (!message.lockedUserIds || !message.lockedUserIds.includes(incomingMessage.current.userId))) {
          // device.disconnectAll();
          // Disconnected PnMessage
          PubNubHelper.SetCallState(
            pubNub,
            incomingMessage.current,
            MessageActions.UpdateCallStatus,
            "Disconnected",
            `TextStage${parseInt(params.slot) - 1}`
          );
          setCallState("Disconnected");
          callStateRef.current = "Disconnected";
          hangUp();
          return;
        }
        // if message is not for current operator slot then do not process message
        if (`TextStage${parseInt(params.slot) - 1}` !== message.slot) return;
        //incomingMessage.current = message;
        if (isMounted) {
          // noinspection JSUnreachableSwitchBranches
          switch (message.messageType) {
            // case MessageActions.BeginCall: {
            //   if (`TextStage${parseInt(params.slot) - 1}` !== message.slot) break;
            //   // Don't call people if we have someone on the line or are already calling
            //   if (
            //     callStateRef.current === "Connected"
            //   )
            //     break;
            //   if (delegatePhoneNumberRef.current != null && delegatePhoneNumberRef.current.length > 0) {
            //     setCallState("Connected");
            //   }
            //   const httpParams = {
            //     to: "+1" + message.phoneNumber,
            //     agent: `TextStage${parseInt(params.slot) - 1}${getConstants().Stage}-${userData.eventId}${getSessionIdForAgentName(params.sessionId)}`,
            //   };
            //   getAccessTokenSilently()
            //     .then((accessToken) => {
            //       PostWithAuth(
            //         `/Voip/CallWithAMD/${activeConfig.configData.eventId}`,
            //         httpParams,
            //         accessToken
            //       ).then((response) => {
            //         // Dialing PnMessage
            //         PubNubHelper.SetCallState(
            //           pubNub,
            //           message,
            //           MessageActions.UpdateCallStatus,
            //           "Dialing",
            //           `TextStage${parseInt(params.slot) - 1}`
            //         );
            //         //setCallState("Dialing");
            //         callStateRef.current = "Dialing";
            //         timer = setTimeout(() => {
            //           if (
            //             // eslint-disable-next-line no-constant-condition
            //             callStateRef.current === "Connected" ||
            //             "Disconnected" ||
            //             "Ready"
            //           )
            //             return;
            //           // Disconnected PnMessage
            //           PubNubHelper.SetCallState(
            //             pubNub,
            //             message,
            //             MessageActions.UpdateCallStatus,
            //             "Disconnected",
            //             `TextStage${parseInt(params.slot) - 1}`
            //           );
            //           setCallState("Disconnected");
            //           callStateRef.current = "Disconnected";
            //         }, 15000);
            //       });
            //     })
            //     .catch((err) => {
            //       console.log(err);
            //     });
            //   break;
            // }
            // case MessageActions.EndCall: {
            //   console.log("END CALL" + JSON.stringify(message));
            //   if (`TextStage${parseInt(params.slot) - 1}` !== message.slot) break;
            //   device.disconnectAll();
            //   // Disconnected PnMessage
            //   PubNubHelper.SetCallState(
            //     pubNub,
            //     message,
            //     MessageActions.UpdateCallStatus,
            //     "Disconnected",
            //     `TextStage${parseInt(params.slot) - 1}`
            //   );
            //   setCallState("Disconnected");
            //   callStateRef.current = "Disconnected";
            //   break;
            // }
          }
        }
      },
    };

    PubNubHelper.OperatorListenerSubscribe(pubNub, userData, pnListener);

    const pnListenerText = {
      message: (messageEvent) => {
        console.log(messageEvent, 'messageEvent')
        let message = messageEvent.message;

        // if message is not for current operator slot then do not process message
        if (`${getConstants().TextStage}${parseInt(params.slot) - 1}` !== message.slot) return;

        if (message.messageType === MessageActions.EndCall) {
          hangUp();
          return;
        }
        let alreadyConnected = false;
        if (
          incomingMessage.current && incomingMessage.current.messageType === MessageActions.Connected
        ) {
          alreadyConnected = true;
        }
        if (message.messageType === MessageActions.Connected) {
          setCallState(MessageActions.Connected);
          setSmsConnected(true);
          incomingMessage.current = message;
        }

        setDelegateUserId(message.userId);
        setDelegatePhoneNumber(message.phoneNumber);
        setToPhone(message.phoneNumber);
        if (message.messageType === MessageActions.Connected && !alreadyConnected) {
          let fromPhoneLocal = getToPhoneLocal(serverPhoneNumber);
          let toPhoneLocal = getToPhoneLocal(message.phoneNumber);

          let details = {
            operatorLineNumber: params.slot,
            fromPhone: fromPhoneLocal,
            toPhone: toPhoneLocal,
            delegateId: message.userId /*userData.id*/,
            messageBody: activeConfig.configData.welcomeTextMessage,
            eventId: userData.eventId,
            sessionId: params.sessionId ? params.sessionId : ''
          };
          getAccessTokenSilently()
            .then((accessToken) => {
              PostUrlFormEncodedWithAuth('/Text/SendMessage', details, accessToken)
                .then((response) => {
                  if (response != null && response != undefined) {
                    if (response.data && response.data.isSuccess) {
                      details['messageSid'] = response.data.sid;
                      details['timestamp'] = new Date();
                      let newArray = messagesSentToDelegate.slice(0);
                      newArray.push(details);
                      setMessagesSentToDelegate(newArray);
                      PubNubHelper.SetTextState(
                        pubNub,
                        incomingMessage.current, //userData,
                        incomingMessage.current,
                        MessageActions.UpdateCallStatus,
                        MessageActions.Connected,
                        `TextStage${parseInt(params.slot) - 1}`,
                        fromPhoneLocal,
                        toPhoneLocal,
                        message.userId,
                        message.podiumNumber
                      );
                    } else if (response.data && response.data.error) {
                      window.alert("Error occured while sending the message, Please check the delegate phone number or Event twilio phone number.\nInternal Error:" + response.data.error);
                    }
                  }
                })
                .catch((e) => {
                  details['messageSid'] = currentMessageNumber;
                  details['messageBody'] = 'Failure: ' + details['messageBody'];
                  details['timestamp'] = new Date();
                  let newArray = messagesSentToDelegate.slice(0);
                  newArray.push(details);
                  setMessagesSentToDelegate(newArray);
                  console.error(`Failure: ${e}`);
                });
            });
        }
      },
    };

    PubNubHelper.TextOperatorListenerSubscribe(pubNub, params.slot, getSessionIdForAgentName(params.sessionId), userData, pnListenerText);

    const pnListenerTextResponse = {
      message: (messageEvent) => {
        let message = messageEvent.message;

        if (params.slot !== message.slot) return;

        let details = {
          operatorLineNumber: params.slot,
          fromPhone: message.fromPhone, //delegatePhoneNumber, 
          toPhone: message.toPhone, // serverFromPhone, 
          delegateId: message.userId,
          messageBody: message.messageText,
          eventId: userData.eventId,
          sessionId: params.sessionId ? params.sessionId : '',
          timestamp: new Date(message.timestamp),
        };

        setMessagesReceivedFromDelegate([details]);
      }
    }

    PubNubHelper.TextOperatorResponseListenerSubscribe(pubNub, params.slot, getSessionIdForAgentName(params.sessionId), userData, pnListenerTextResponse);

    if (isMounted && device && !deviceConfigured) {
    }

    return () => {
      PubNubHelper.OperatorListenerUnsubscribe(pubNub, userData, pnListener);
      PubNubHelper.TextOperatorListenerUnsubscribe(pubNub, params.slot, getSessionIdForAgentName(params.sessionId), userData, pnListenerText);
      PubNubHelper.TextOperatorListenerResponseUnsubscribe(pubNub, params.slot, getSessionIdForAgentName(params.sessionId), userData, pnListenerTextResponse)
      isMounted = false;
      clearTimeout(timer);
    };
  }, [
    selectedInput,
    selectedOutput,
    audioOutputs,
    audioInputs,
    deviceConfigured,
  ]);

  useEffect(() => {
    if (smsConnected && currentMessageNumber === 0) {
      let toPhoneLocal = getToPhoneLocal(delegatePhoneNumberRef.current);
      let fromPhoneLocal = getToPhoneLocal(serverPhoneNumber);

      let details = {
        topNMany: 1,
        operatorLineNumber: params.slot,
        fromPhone: fromPhoneLocal,
        toPhone: toPhoneLocal,
        delegateId: delegateUserIdRef.current /*userData.id */,
        eventId: userData.eventId,
        sessionId: params.sessionId ? params.sessionId : ''
      };
      getAccessTokenSilently()
        .then((accessToken) => {
          PostUrlFormEncodedWithAuth('/Text/GetLastFewMessages', details, accessToken)
            .then((response) => {
              if (response != null && response != undefined) {
                if (response?.data != null && response.data.length > 0) {
                  const mappedRows = response.data.map((row) => {
                    return {
                      messageSid: row['messageSID'],
                      messageBody: row['messageBody'],
                      timestamp: new Date(Date.parse(row['updatedAt'])),
                      toPhone: row['toPhone'],
                    }
                  });
                  setMessagesSentToDelegate(mappedRows);
                }

              }
            })
            .catch((e) => {
              details['messageSid'] = currentMessageNumber;
              details['messageBody'] = 'Failure: ' + details['messageBody'];
              details['timestamp'] = new Date();
              let newArray = messagesSentToDelegate.slice(0);
              newArray.push(details);
              setMessagesSentToDelegate(newArray);

            });
        });
    }
  }, [
    delegatePhoneNumber,
    params.sessionId,
    smsConnected
  ]);

  const setReady = (status) => {
    //let newContext = userData;

    PubNubHelper.SetCallState(
      pubNub,
      incomingMessage.current, //userData,
      MessageActions.UpdateCallStatus,
      status,
      `TextStage${parseInt(params.slot) - 1}`,
      getToPhoneLocal(toPhone),
      (status === "Ready") ? podiumNumber : ""
    );
    PubNubHelper.SetTextState(
      pubNub,
      incomingMessage.current, //userData,
      incomingMessage.current,
      MessageActions.UpdateCallStatus,
      status,
      `TextStage${parseInt(params.slot) - 1}`,
      getToPhoneLocal(fromPhone),
      getToPhoneLocal(toPhone),
      delegateUserId,
      (status === "Ready") ? podiumNumber : ""
    );
    setCallState(status);
  };

  const hangUp = () => {
    setSmsConnected(false);
    setReady("Disconnected");
    setDelegateUserId(0);
    setDelegatePhoneNumber('default');
    setToPhone("default");
    setFromPhone("default");
    setCurrentMessageNumber(0);
    setMessagesReceivedFromDelegate([]);
    setMessagesSentToDelegate([]);
    setPodiumNumber("");
    incomingMessage.current = {};
  }


  return (
    <>
      <div className="opUI">Text Staging Slot: {parseInt(params.slot)}</div>
      <div className="opUI">SMS Status: {callState}</div>
      <div className="op-wrapper">
        <div className="field-wrapper">
          <Button
            variant="contained"
            color="primary"
            onClick={() => setReady("Ready")}
            disabled={!smsConnected || (callState === "Ready" || podiumNumber.trim() == "")}
          >
            Set as ready
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => delegateUserId === 0 ? setCallState("Disconnected") : setReady("NotReady")}
            disabled={!smsConnected || (callState === "Disconnected" && delegateUserId === 0 || callState === "NotReady" && delegateUserId !== 0)}
          >
            Not Ready
          </Button>
        </div>

        <div class="field-wrapper mt-4">
          <FormControl>
            {/* <InputLabel>Podium Number</InputLabel> */}
            <TextField
              variant="outlined"
              label="Podium Number"
              name="podiumNumber"
              value={podiumNumber}
              disabled={!smsConnected}
              onChange={(e) => { setPodiumNumber(e.target.value) }}
              inputProps={{ maxLength: 10 }}
            ></TextField>
          </FormControl>
        </div>

        <div class="field-wrapper mb-0 mt-1">

          {(activeConfig.configData.welcomeLabel && activeConfig.configData.welcomeTextMessage) ?
            (
              <Button
                variant="contained"
                className={classnames(classes.button, classes.properCase, classes.orange)}
                onClick={(e) => { textToDelegate(activeConfig.configData.welcomeTextMessage) }}
                disabled={!smsConnected}
              >
                {activeConfig.configData.welcomeLabel}
              </Button>
            ) : ''}

          {(activeConfig.configData.operatorButton1Label && activeConfig.configData.operatorButton1Text) ?
            (
              <Button
                variant="contained"
                className={classnames(classes.button, classes.properCase, classes.orange)}
                onClick={(e) => { textToDelegate(activeConfig.configData.operatorButton1Text) }}
                disabled={!smsConnected}
              >
                {activeConfig.configData.operatorButton1Label}
              </Button>
            ) : ''}

          {(activeConfig.configData.operatorButton2Label && activeConfig.configData.operatorButton2Text) ?
            (
              <Button
                variant="contained"
                className={classnames(classes.button, classes.properCase, classes.orange)}
                onClick={(e) => { textToDelegate(activeConfig.configData.operatorButton2Text) }}
                disabled={!smsConnected}
              >
                {activeConfig.configData.operatorButton2Label}
              </Button>
            ) : ''}
        </div>
        <div class="field-wrapper mt-0 mb-1">
          {(activeConfig.configData.operatorButton3Label && activeConfig.configData.operatorButton3Text) ?
            (
              <Button
                variant="contained"
                className={classnames(classes.button, classes.properCase, classes.orange)}
                onClick={(e) => { textToDelegate(activeConfig.configData.operatorButton3Text) }}
                disabled={!smsConnected}
              >
                {activeConfig.configData.operatorButton3Label}
              </Button>
            ) : ''}
          {(activeConfig.configData.operatorButton4Label && activeConfig.configData.operatorButton4Text) ?
            (
              <Button
                variant="contained"
                className={classnames(classes.button, classes.properCase, classes.orange)}
                onClick={(e) => { textToDelegate(activeConfig.configData.operatorButton4Text) }}
                disabled={!smsConnected}
              >
                {activeConfig.configData.operatorButton4Label}
              </Button>
            ) : ''}
        </div>

        <List>
          <ListItem className={classnames(classes.backingCard, classes.shadow, classes.white)}>
            <div>
              <ListItemText key={messagesSentToDelegate.length > 0 ? messagesSentToDelegate[messagesSentToDelegate.length - 1].messageSid : 'default'}
                primary={'Last Message Sent: ' + ((messagesSentToDelegate.length > 0) ?
                  (messagesSentToDelegate[messagesSentToDelegate.length - 1].messageBody + '... displayed below' + (messagesSentToDelegate.length > 0 ? ' (' +
                    new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(messagesSentToDelegate[messagesSentToDelegate.length - 1].timestamp)
                    + ')' : ''))
                  : ('None'))}
                secondary={
                  messagesSentToDelegate.slice(0).reverse().slice(0, 1).map((message) => {
                    return (
                      <React.Fragment>
                        <div className={classnames(classes.backdropMessage, classes.whiteText, classes.blue)}>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            <div>{message.toPhone}</div>
                          </Typography>
                          <div>{message.messageBody}</div>
                        </div>
                      </React.Fragment>
                    )
                  }
                  )}
              />
            </div>
          </ListItem>
          <ListItem className={classnames(classes.backingCard, classes.shadow, classes.white)}>
            <div>
              <ListItemText key={messagesReceivedFromDelegate.length > 0 ? messagesReceivedFromDelegate[messagesReceivedFromDelegate.length - 1].messageSid : 'default'}
                primary={"Delegate's Last Response / Answer" + (messagesReceivedFromDelegate.length > 0 ? ' (' +
                  new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(messagesReceivedFromDelegate[messagesReceivedFromDelegate.length - 1].timestamp)
                  + ')' : '')}
                secondary={
                  messagesReceivedFromDelegate.slice(0).reverse().slice(0, 1).map((message) => {
                    return (
                      <React.Fragment>
                        <div className={classnames(classes.backdropMessage, classes.whiteText, classes.teal)}>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            <div>{message.fromPhone}</div>
                          </Typography>
                          <div>{message.messageBody}</div>
                        </div>
                      </React.Fragment>
                    )
                  })
                }
              />
            </div>
          </ListItem>
        </List>
      </div>
    </>
  );
}

export default TextOperator;
