import PubNubMessageBase from "./PubNubMessageBase";

class QueueReorderMessage extends PubNubMessageBase {
  constructor(
    user,
    messageType,
    resolutionId,
    queue,
    slot,
    auth0IdSequence,
    podiumNumber
  ) {
    super(user, messageType, podiumNumber);
    this.resolutionId = resolutionId;
    this.queue = queue;
    this.slot = slot;
    this.auth0IdSequence = auth0IdSequence;
  }
}

export default QueueReorderMessage;
