import "./ErrorToaster.css";

import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import React from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

class GlobalErrorBoundary extends React.Component {

    constructor() {
        super();
        this.state = { errors: [] };
    }
    componentDidMount() {
        this.responseInterceptor = axios.interceptors.response.use(
            res => res,
            error => {
                this.setState(prevState => ({
                    errors: [...prevState.errors, { id: uuidv4(), errorInfo: error.toString(), axiosError: true, clearAt: Math.floor(Date.now() / 1000) + 2 }]
                }), () => {
                    this.updateStateInterval();
                });
            }
        );
        this.windowError = window.addEventListener('error', (error) => {
            const message = error.message ? error.message : 'Error occurred';
            const substring = 'script error';
            if (message.toLowerCase().indexOf(substring) > -1) {
                message = 'Script Error: See Browser Console for Detail';
            }
            this.setState(prevState => ({
                errors: [...prevState.errors, { id: uuidv4(), errorInfo: message, axiosError: false, clearAt: Math.floor(Date.now() / 1000) + 2 }]
            }), () => {
                this.updateStateInterval();
            });
        });
    }
    updateStateInterval() {
        if (this.state.errors.length > 0 && !this.stateInterval) {
            this.stateInterval = setInterval(() => {
                const currentTime = Math.floor(Date.now() / 1000);
                this.setState(prevState => ({
                    errors: [...prevState.errors.filter(a => a.clearAt > currentTime)]
                }), () => {
                    this.updateStateInterval();
                });
            }, 1000);
        }
        if (this.state.errors.length === 0 && this.stateInterval) {
            clearInterval(this.stateInterval);
            this.stateInterval = null;
        }
    }
    componentWillUnmount() {
        axios.interceptors.response.eject(this.responseInterceptor);
        window.removeEventListener('error', this.windowError);
        if (this.stateInterval) {
            clearInterval(this.stateInterval);
            this.stateInterval = null;
        }
    }
    handleClose = (event) => {
        if (event) {
            this.setState(prevState => ({
                errors: [...prevState.errors.filter(a => a.id !== event.id)]
            }), () => {
                this.updateStateInterval();
            });
        }
    };
    reloadPage = () => {
        window.location.reload();
    }
    render() {
        return (
            <React.Fragment>
                {this.props.children}
                {this.state.errors.length > 0 && (
                    <div className="toaster">
                        {this.state.errors.map((a) => (
                            <Alert severity="error" action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        this.handleClose(a);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }>
                                {a.errorInfo}
                            </Alert>
                        ))}
                    </div>
                )
                }
            </React.Fragment>
        )

    }
}
export default GlobalErrorBoundary;