import React, { useEffect, useState } from "react";
import { TwitterPicker } from "react-color";
import { usePubNub } from "pubnub-react";
import "./styles.css";
import IconButton from "@material-ui/core/IconButton";
import ColorizeIcon from "@material-ui/icons/Colorize";
import Tooltip from "@material-ui/core/Tooltip";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Lock from "@material-ui/icons/Lock";
import LockOpen from "@material-ui/icons/LockOpen";
import StageCard from "./StageCard";
import PubNubHelper from "../../infrastructure/PubNubHelper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  button: {
    padding: "1px",
  },
});

const Card = ({
  message,
  messageIndex,
  isLoaded,
  stageStatuses,
  stagePodiumNumbers,
  colorCode,
  onCardColorChange
}) => {
  const pubNub = usePubNub();
  const [background, setBackground] = useState(message.color);
  const [isLock, setIsLock] = useState(!!message.isLock);
  const [showPicker, setShowPicker] = useState(false);
  const classes = useStyles();
  const deleteMessage = (message) => {
    PubNubHelper.EvictUser(pubNub, message);
  };

  const handleChangeComplete = (color) => {
    onCardColorChange(color.hex);

    setBackground(color.hex);
    setShowPicker(!showPicker);
    PubNubHelper.ChangeColor(pubNub, message, color, message.podiumNumber);
  };
  const handleLockCard = (isLock) => {
    setIsLock(isLock);
    PubNubHelper.ChangeLock(pubNub, message, isLock, message.podiumNumber);
  };
  return (
    <>
      {message.id !== undefined ? (
        <div className="content-wrapper" id={`card-content-${messageIndex}`}>
          <div
            style={{
              backgroundColor: message.roleColor,
              minWidth: "10px",
              height: "100%",
            }}
          />
          <IconButton
            onClick={() => {
              setShowPicker(!showPicker);
            }}
            size="small"
          >
            <ColorizeIcon />
          </IconButton>

          {showPicker && (
            <div className="color-picker">
              <TwitterPicker
                color={background}
                onChangeComplete={handleChangeComplete}
                width="150px"
                triangle="hide"
                colors={colorCode}
              />
            </div>
          )}
          <div>{message.name}</div>
          <div className="pipe">|</div>
          <Tooltip title={message.chapter}>
            <div className="mw">{message.chapter}</div>
          </Tooltip>

          <div className="pipe">|</div>
          <div>{message.locale}</div>
          {(message.language !== "English" ||
            message.queue === "Stage" ||
            message.queue === "PhoneStage" ||
            message.queue === "TextStage" ||
            message.queue === "Production") && <div className="pipe">|</div>}
          {(message.queue.includes("Stage") ||
            message.queue === "Production") && (
              <div>{message.participationType}</div>
            )}
          {/*message.podiumNumber*/}
          {(message.queue.includes("Stage") ||
            message.queue === "Production") &&
            message.language !== "English" && <div className="pipe">|</div>}
          <strong>
            {message.language !== "English" ? (
              <div>{message.language}</div>
            ) : (
              <div style={{ marginLeft: "65px" }} />
            )}
          </strong>
          {message.queue.includes("Stage") && (
            <StageCard
              message={message}
              isLoaded={isLoaded}
              stageStatuses={stageStatuses}
              stagePodiumNumbers={stagePodiumNumbers}
            />
          )}
          <IconButton
            onClick={() => handleLockCard(!message.isLock)}
            id={`lock-icon-${messageIndex}`}
            classes={{ root: classes.button }}
          >
            {message.isLock ? (<Lock />) : <LockOpen />}
          </IconButton>
          {!message.queue.includes("Stage") && (
            <>

              <IconButton
                onClick={() => deleteMessage(message)}
                id={`delete-icon-${messageIndex}`}
                classes={{ root: classes.button }}
              >
                <HighlightOffIcon />
              </IconButton></>
          )}
        </div>
      ) : null}
    </>
  );
};

export default Card;
