import React, { useState, useContext, useEffect, useRef } from "react";
import Resolution from "./index";
import { usePubNub } from "pubnub-react";
import { UserContext } from "../../infrastructure/UserContext";
import { PubNubChannels } from "../../infrastructure/PubNubChannelEnum";
import { MessageActions } from "../../infrastructure/MessageActions";
import PubNubHelper from "../../infrastructure/PubNubHelper";

const ResolutionListener = () => {
  const pubNub = usePubNub();
  const userData = useContext(UserContext);
  const [ui, render] = useState({});

  // History Hooks
  const voteLoaded = useRef(false);
  const voteQueueData = useRef({});
  const queueLoaded = useRef(false);
  const resolutionData = useRef([]);
  // lastResolutionId is used for Point of Order
  const lastResolutionId = useRef(undefined);
  const amILock = useRef(false);
  const resolutionSelection = useRef({});
  const leaveQueueDisabled = useRef(false);
  const voteModalEnabled = useRef(false);
  // Data for Resolution component
  const voteSelection = useRef(null);
  const [playSound, setPlaySound] = useState(false);
  useEffect(() => {
    if (!userData) return;
    const pnListnerVote = {
      message: (messageEvent) => {
        if (messageEvent) {
          let message = messageEvent.message;
          // Check to see if this message was provided by History
          if (messageEvent.id && messageEvent.action) message = messageEvent;
          if (message) {
            switch (message.action) {
              case MessageActions.InitiateVote: {
                voteQueueData.current = message;
                voteSelection.current = "";
                voteModalEnabled.current = true;
                setPlaySound(true);
                break;
              }
              case MessageActions.EndVote: {
                setPlaySound(false);
                voteQueueData.current = {};
                voteSelection.current = "";
                voteModalEnabled.current = false;
                break;
              }
            }
            render({});
          }
        }
      },
    };

    const pnListnerResolution = {
      message: (messageEvent) => {
        if (messageEvent) {
          let message = messageEvent.message;
          // Check to see if this message was provided by History
          if (messageEvent.id && messageEvent.action) message = messageEvent;
          if (message) {
            switch (message.messageType) {
              case MessageActions.BeginCall: {
                if (userData.id === message.userId) {
                  leaveQueueDisabled.current = true;
                  render({});
                }
                return;
                break;
              }
              case MessageActions.EndCall: {
                if (userData.id === message.userId) {
                  leaveQueueDisabled.current = false;
                  render({});
                }
                return;
                break;
              }
              case MessageActions.Connected: {
                if (userData.id === message.userId) {
                  leaveQueueDisabled.current = true;
                  render({});
                }
                return;
                break;
              }
              default: {
                switch (message.action) {
                  case MessageActions.Clear: {
                    resolutionData.current = [];
                    lastResolutionId.current = message.closingResolutionId;
                    if (
                      resolutionSelection.current &&
                      resolutionSelection.current.queue !== "Point of Order" &&
                      resolutionSelection.current.queue !== "Point of Information"
                    ) {
                      resolutionSelection.current = undefined;
                      leaveQueueDisabled.current = false;
                      render({});
                    }
                    break;
                  }
                  case MessageActions.PauseAndClear: {
                    amILock.current = message.lockedUserIds && message.lockedUserIds.some(a => a === userData.id);
                    resolutionData.current = [message];
                    lastResolutionId.current = undefined;
                    if (
                      resolutionSelection.current &&
                      resolutionSelection.current.queue !== "Point of Order" &&
                      resolutionSelection.current.queue !== "Point of Information"
                      && !amILock
                    ) {
                      resolutionSelection.current = undefined;
                      leaveQueueDisabled.current = false;
                      render({});
                    }
                    render({});
                    break;
                  }
                  case MessageActions.Evicted: {
                    if (userData.id === message.userId) {
                      resolutionSelection.current = undefined;
                      leaveQueueDisabled.current = false;
                      render({});
                    }
                    break;
                  }
                  case MessageActions.Disconnected:
                  case MessageActions.Evicted: {
                    if (userData.id === message.userId) {
                      if (leaveQueueDisabled.current !== false) {
                        leaveQueueDisabled.current = false;
                        render({});
                      }
                    }
                    break;
                  }

                  case MessageActions.Connected:
                  case MessageActions.Dialing: {
                    if (userData.id === message.userId) {
                      leaveQueueDisabled.current = true;
                      render({});
                    }
                    break;
                  }
                  case MessageActions.Create: {
                    if (
                      messageEvent.channel ===
                      `${PubNubChannels.NewResolution}-${userData.eventId}` ||
                      message.action === MessageActions.Create
                    ) {
                      amILock.current = message.lockedUserIds && message.lockedUserIds.some(a => a === userData.id);
                      resolutionData.current = [message];
                      lastResolutionId.current = undefined;
                      if (
                        resolutionSelection.current &&
                        resolutionSelection.current.queue !== "Point of Order" &&
                        resolutionSelection.current.queue !== "Point of Information"
                      ) {
                        resolutionSelection.current = undefined;
                        leaveQueueDisabled.current = false;
                      }
                      render({});
                    }
                    break;
                  }
                }
              }
            }
            render({});
          }
        }
      },
    };


    if (userData && userData.id) {

      // Get Current Vote
      if (!voteLoaded.current) {
        PubNubHelper.GetCurrentVote(pubNub, userData).then((response) => {
          if (!response) return;
          voteLoaded.current = true;
          // voteModalEnabled.current = true;
          pnListnerVote.message(response);
          render({});
          // Get My Vote
          PubNubHelper.DidIVote(
            pubNub,
            userData,
            voteQueueData.current.id
          ).then((response) => {
            if (!response) return;
            voteSelection.current = response.voteSelection;
            voteModalEnabled.current = false;
            render({});
            // Need to render twice here to update the buttons
            render({});
          });
        });
      }
      // Get Current Resolution
      if (!queueLoaded.current) {
        PubNubHelper.GetCurrentResolution(pubNub, userData).then((resolutionResponse) => {
          // if (!resolutionResponse) return;
          queueLoaded.current = true;
          if (resolutionResponse) {
            pnListnerResolution.message(resolutionResponse);
          }
          render({});
          // Get my queue position
          PubNubHelper.IsMyHandRaised(pubNub, userData).then((handRaised) => {
            if (!handRaised) return;
            if (handRaised.message.action === "Leave") return;
            PubNubHelper.WasIEvicted(pubNub, userData).then((evicted) => {
              // Was I evicted after I joined the most recent queue?
              let endTimeToken = undefined;
              let resolutionId = 'no-resolution';
              if (resolutionResponse) {
                resolutionId = resolutionResponse.id;
              }
              if (resolutionResponse && resolutionResponse.action === 'Clear') {
                endTimeToken = resolutionResponse.timetoken;
                resolutionId = resolutionResponse.closingResolutionId;
              }
              if (
                (!evicted || evicted < handRaised.timeToken) &&
                (
                  handRaised.message.resolutionId === resolutionId ||
                  handRaised.message.resolutionId === 'no-resolution'
                )
              ) {
                resolutionSelection.current = handRaised.message;
                PubNubHelper.GetUserCallStatus(pubNub, userData.auth0Id, userData.eventId).then(message => {
                  // user can leave queue only if not call happen or last call status is disconnected or end call.
                  if (!message || (message && (
                    (message.messageType === MessageActions.UpdateCallStatus && message.action === MessageActions.Disconnected) ||
                    message.messageType === MessageActions.EndCall))) {
                    leaveQueueDisabled.current = false;
                  } else {
                    leaveQueueDisabled.current = true;
                  }
                  render({});
                });
                render({});
              }
            });
            //resolutionSelection.current = response;
            render({});
            // Need to render twice here to update the buttons
            render({});
          });
        });
      }
      // if((!voteSelection.current) && (voteQueueData.current && Object.getOwnPropertyNames(voteQueueData.current).length !== 0)){
      //   setVoteShowModal(true);
      //   render({});
      //   render({});
      // }
    }

    PubNubHelper.DelegateResolutionListenerSubscribe(
      pubNub,
      userData,
      pnListnerResolution
    );
    PubNubHelper.DelegateVoteListenerSubscribe(pubNub, userData, pnListnerVote);

    return () => {
      PubNubHelper.DelegateResolutionListenerUnsubscribe(
        pubNub,
        userData,
        pnListnerResolution
      );
      PubNubHelper.DelegateVoteListenerUnsubscribe(
        pubNub,
        userData,
        pnListnerVote
      );
    };
  }, [userData]);

  const castVote = (vote) => {
    voteSelection.current = vote;
    render({});
  };

  const setVoteShowModal = (isEnabled) => {
    voteModalEnabled.current = isEnabled;
    render({});
  };

  const joinQueue = (message) => {
    resolutionSelection.current = message;
    leaveQueueDisabled.current = false; // Always enable the leave queue button on initial join.
    render({});
  };

  const leaveQueue = (message) => {
    resolutionSelection.current = message;
    render({});
  };

  return (
    <Resolution
      voteQueueData={voteQueueData.current}
      voteSelection={voteSelection.current}
      resolutionData={resolutionData.current}
      amILock={amILock.current}
      lastResolutionId={lastResolutionId.current}
      resolutionSelection={resolutionSelection.current}
      castVoteCallback={castVote}
      joinQueueCallback={joinQueue}
      leaveQueueCallback={leaveQueue}
      leaveQueueDisabled={leaveQueueDisabled.current}
      disablePlaySound={() => setPlaySound(false)}
      playSound={playSound}
      setVoteShowModalCallback={setVoteShowModal}
      voteModalEnabled={voteModalEnabled.current}
    />
  );
};

export default ResolutionListener;
