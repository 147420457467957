import "./styles.css";

import React, { useContext } from "react";

import { ConfigurationContext } from "../../infrastructure/ConfigurationContext";

const VoteBroadcast = ({ yesTally, noTally }) => {
  const activeConfig = useContext(ConfigurationContext);
  let total = yesTally + noTally;
  const yesPercent = (yesTally / total) * 100;
  const noPercent = (noTally / total) * 100;
  return (
    <div className="broadcast-container">
      <div className="tally-wrapper">
        <h2>{activeConfig.configData.uiConfigs.button_yes_txt}</h2>
        <div id="yesTallyPercentage" className="percent bold">
          {Math.round(yesPercent) || 0}%
        </div>
        <div className="vote-tally">
          <div id="yesTally" className="bold">
            {yesTally || 0}
          </div>
          <div className="votes">Votes</div>
        </div>
      </div>
      <div className="tally-wrapper">
        <h2>{activeConfig.configData.uiConfigs.button_no_txt}</h2>
        <div id="noTallyPercentage" className="percent bold">
          {Math.round(noPercent) || 0}%
        </div>
        <div className="vote-tally">
          <div id="noTally" className="bold">
            {noTally || 0}
          </div>
          <div className="votes">Votes</div>
        </div>
      </div>
    </div>
  );
};
export default VoteBroadcast;
