import React, { useContext, useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import "./styles.css";
import { ConfigurationContext } from "../../infrastructure/ConfigurationContext";

const styles = {
  paperContainer: {
    backgroundColor: "lightgrey",
  },
};

function LockCredentials() {
  const activeConfig = useContext(ConfigurationContext);
  const config = activeConfig.configData;
  const [year, setYear] = useState(new Date().getFullYear());

  const calculateTimeLeft = () => {
    let startDate = new Date(config.eventBegin);
    let now = new Date();
    let difference = startDate - now;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const updateTime = () => {
    let timeLeft = calculateTimeLeft();
    setYear(new Date().getFullYear());
    let timerElements = [];
    Object.keys(timeLeft).forEach((interval) => {
      if (!timeLeft[interval]) {
        return;
      }
      timerElements.push(
        <span>
          {timeLeft[interval]} {interval}{" "}
        </span>
      );
    });
    setTimerComponents(timerElements);
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [timerComponents, setTimerComponents] = useState([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      updateTime();
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <Grid container alignItems="center" justify="center">
      <Grid style={styles.paperContainer}>
        <div className="inner-container">
          <Card>
            <CardContent>
              <Typography
                variant="h3"
                align="center"
                justifyContent="center"
                gutterBottom
              >
                {config.pageTitle} {year}
              </Typography>
              <Typography variant="h5" align="center" justifyContent="center">
                Credentialing has closed. Please contact {config.pageTitle}’s
                Convention Helpdesk at {config.helpDeskEmail} or{" "}
                {config.helpDeskPhone} if you need assistance.
              </Typography>
            </CardContent>
          </Card>
        </div>
      </Grid>
    </Grid>
  );
}

export default LockCredentials;
