export enum Tasks {
  Vote = "vote",
  Moderate = "moderate",
  RequestToSpeak = "requestToSpeak",
}

export enum Permissions {
  Watch,
  Vote,
  Speak,
  Support,
  PointOfOrder,
  Moderate,
  WaitingRoomSkip,
  PointOfInformation
}

export enum Roles {
  Guest = "Guest",
  Delegate = "Delegate",
  Moderator = "Moderator",
  Developer = "Developer",
  Freeman = "Freeman",
}
