import { v4 as uuidv4 } from "uuid";

class PubNubMessageBase {
  constructor(user, messageType, podiumNumber, timeToken) {
    // Since we have a tendancy to pass a message in as user, this will map the fields correctly
    if (user.userId) {
      // Message passed as user
      this.userId = user.userId;
    } else {
      // User passed as user
      this.userId = user.id;
    }
    if (podiumNumber) {
      this.podiumNumber = podiumNumber;
    } else {
      this.podiumNumber = '';
    }
    if (timeToken) {
      this.timeToken = timeToken;
    } else {
      this.timeToken = + new Date();
    }

    this.id = uuidv4();
    this.messageType = messageType;
    this.eventId = user.eventId;
    this.auth0Id = user.auth0Id;
  }
}

export default PubNubMessageBase;
