import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
const Spinner = () => {
  const useStyles = makeStyles({
    spinner: {
      position: "fixed",
      top: "50%",
      left: "50%",
      marginTop: "-50px",
      marginLeft: "-50px",
      color: "#8ecef1",
    },
  });

  const classes = useStyles();
  return <CircularProgress size={100} classes={{ root: classes.spinner }} />;
};

export default Spinner;
