import React, { useContext, useEffect, useRef, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import CardContent from "./CardContent";
import "./styles.css";
import DraggableContainer from "./DraggableContainer";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, CardHeader, Card } from "@material-ui/core";
import getConstants from "../../infrastructure/Constants";
import { ConfigurationContext } from "../../infrastructure/ConfigurationContext";

const TextStageView = ({
  actionType,
  messages,
  isLoaded,
  stageStatuses,
  colorCode,
  onCardColorChange }) => {
  const actionString = `${actionType}`;
  const activeConfig = useContext(ConfigurationContext);

  let rows = [{}, {}, {}, {}, {}, {}, {}, {}, {}];

  messages.map((message) => {
    let index = parseInt(message.queue.replace(getConstants().TextStage, '')) - 9;
    rows.splice(index, 1, message);
  });

  const getContainerStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#c2e3fc" : "white",
  });

  const useStyles = makeStyles({
    root: {
      background: "#0066B3",
      textAlign: "center",
      color: "#f5f5f5",
      height: "40px",
    },
    container: {
      height: "50px",
      verticalAlign: "middle",
      display: "flex",
    },
  });
  const classes = useStyles();
  const getIndex = (actualIndex) => actualIndex + (getConstants().TextStageOffset - 1);
  return (
    <div className="column">
      <Paper elevation={3} id="text-stage-container">
        <h4 className="paperHeading" 
          style={{
            color:activeConfig.configData.uiConfigs["sms_title_text_color"],
            backgroundColor:activeConfig.configData.uiConfigs["sms_title_background_color"]
          }}>Text / In-Person</h4>
        {rows.map((message, index) => {
          return (
            <div key={index} id={`text-stage-slot-${index}`}>
              <Droppable
                droppableId={actionString + getIndex(index)}
                key={index}
                isDropDisabled={message.id !== undefined}
              >
                {(provided, snapshot) => (
                  <Card
                    ref={provided.innerRef}
                    key={actionString}
                    classes={{
                      root: classes.container,
                    }}
                    style={getContainerStyle(snapshot.isDraggingOver)}
                  >
                    {index + getConstants().TextStageOffset}
                    {message.id !== undefined && (
                      <DraggableContainer
                        message={message}
                        messageIndex={(index)}
                      >
                        <CardContent
                          message={message}
                          messageIndex={(index)}
                          isLoaded={isLoaded}
                          stageStatuses={stageStatuses}
                          colorCode={colorCode}
                          onCardColorChange={onCardColorChange}
                        />
                      </DraggableContainer>
                    )}
                  </Card>
                )}
              </Droppable>
            </div>
          );
        })}
      </Paper>
    </div>
  );
};

export default TextStageView;
