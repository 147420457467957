import React, { useEffect, useState } from "react";

import { ConfigurationContext } from "./ConfigurationContext";
import { Get } from "./HttpClient";
import PageNotFound from "../infrastructure/PageNotFound";
import Spinner from "../components/Spinner";
import { useLocation } from "react-router-dom";

const ConfigurationProvider = ({ children }) => {
  const [configData, setConfigData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  let location = useLocation();
  let pathArray = location.pathname.split("/");
  let path = "demo";

  if (pathArray.length > 1 && pathArray[1] !== "") {
    path = pathArray[1];
  }
  const regx = /\/(\w+)\/(?:session\/(\d+))?/;
  let matches = regx.exec(location.pathname)
  if (matches && matches[1] && matches[2]) {
    path = `${matches[1]}&sessionId=${matches[2]}`;
  }


  useEffect(() => {
    let isMounted = true;
    Get(`/Configuration/GetConfiguration?path=${path}`)
      .then((response) => {
        if (isMounted) {
          const config = response.data;
          setConfigData(config);
          // color can be configured by color property
          // setConfigData({ ...config, color: "#FF0000" });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setHasError(true);
        console.log(err);
      });
    return () => (isMounted = false);
  }, [hasError]);
  if (isLoading) {
    return <Spinner />;
  } else if (hasError) {
    return <PageNotFound />;
  } else {
    return (
      <ConfigurationContext.Provider value={{ configData }}>
        {children}
      </ConfigurationContext.Provider>
    );
  }
};

export default ConfigurationProvider;
