import React, { useEffect, useContext } from "react";
import ActionWrapper from "../ActionWrapper";
import { DragDropContext } from "react-beautiful-dnd";
import { UserContext } from "../../infrastructure/UserContext";
import "./styles.css";
import Can from "../Can";
import { Permissions } from "../../infrastructure/RbacEnum";
import { Button } from "@material-ui/core";

const PresidentsView = ({ messages, input, stageStatuses, stagePodiumNumbers, reloadResolutionData }) => {
  const userData = useContext(UserContext);
  useEffect(() => {
    return () => { };
  });
  return (
    <Can
      permissions={userData?.permissions}
      task={Permissions.Moderate}
      yes={() => (
        <div className="queue-container">
          <div className="input-wrapper">
            <h4>Current Resolution: {input}</h4>
          </div>
          <Button
            id="president-reload-queue-button"
            variant="contained"
            color="primary"
            onClick={reloadResolutionData}
          >
            Reload Data
              </Button>
          <DragDropContext>
            <div className="president-wrapper">
              <div className="queues-wrapper ready">
                <h1>Ready</h1>
                <ActionWrapper
                  messages={messages.filter(
                    (x) =>
                      x.participationType === "Support" &&
                      (x.callStatus === "Ready" ||
                        x.callStatus === "Speaking")
                  )}
                  isPresidentsView={true}
                  actionType={"Support"}
                  stageStatuses={stageStatuses}
                  stagePodiumNumbers={stagePodiumNumbers}
                />
                <ActionWrapper
                  messages={messages.filter(
                    (x) =>
                      x.participationType === "Oppose" &&
                      (x.callStatus === "Ready" ||
                        x.callStatus === "Speaking")
                  )}
                  isPresidentsView={true}
                  actionType={"Oppose"}
                  stageStatuses={stageStatuses}
                  stagePodiumNumbers={stagePodiumNumbers}
                />
                <ActionWrapper
                  messages={messages.filter(
                    (x) =>
                      x.participationType === "Point of Order" &&
                      (x.callStatus === "Ready" ||
                        x.callStatus === "Speaking")
                  )}
                  actionType={"Point of Order"}
                  isPresidentsView={true}
                  stageStatuses={stageStatuses}
                  stagePodiumNumbers={stagePodiumNumbers}
                />
                <ActionWrapper
                  messages={messages.filter(
                    (x) =>
                      x.participationType === "Point of Information" &&
                      (x.callStatus === "Ready" ||
                        x.callStatus === "Speaking"
                        )
                  )}
                  actionType={"Point of Information"}
                  isPresidentsView={true}
                  stageStatuses={stageStatuses}
                  stagePodiumNumbers={stagePodiumNumbers}
                />
              </div>

              <div className="queues-wrapper not-ready">
                <h1>Not ready</h1>

                <ActionWrapper
                  messages={messages.filter(
                    (x) =>
                      x.participationType === "Support" &&
                      x.callStatus !== "Ready" && x.callStatus !== "Speaking"
                  )}
                  actionType={"Support"}
                  isPresidentsView={true}
                  stageStatuses={stageStatuses}
                />

                <ActionWrapper
                  messages={messages.filter(
                    (x) =>
                      x.participationType === "Oppose" &&
                      x.callStatus !== "Ready" && x.callStatus !== "Speaking"
                  )}
                  actionType={"Oppose"}
                  isPresidentsView={true}
                  stageStatuses={stageStatuses}
                />

                <ActionWrapper
                  messages={messages.filter(
                    (x) =>
                      x.participationType === "Point of Order" &&
                      x.callStatus !== "Ready" && x.callStatus !== "Speaking"
                  )}
                  actionType={"Point of Order"}
                  isPresidentsView={true}
                  stageStatuses={stageStatuses}
                />

                <ActionWrapper
                  messages={messages.filter(
                    (x) =>
                      x.participationType === "Point of Information" &&
                      x.callStatus !== "Ready" && x.callStatus !== "Speaking"
                  )}
                  actionType={"Point of Information"}
                  isPresidentsView={true}
                  stageStatuses={stageStatuses}
                />
              </div>
            </div>
          </DragDropContext>
        </div>
      )}
    />
  );
};
export default PresidentsView;
