import React, { useState } from "react";
import "../styles.css";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Typography } from "@material-ui/core";
import classnames from "classnames";
import HowToVoteIcon from "@material-ui/icons/HowToVote";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";

const useStyles = makeStyles({
  button: {
    marginTop: 10,
    color: "#fff",
    "&:disabled": { color: "rgb(0 0 0 / 0.26)" },
  },
  headers: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#666",
    height: "20px",
    textAlign: "center",
  },
  blue: {
    backgroundColor: "#0066B3",
    "&:hover": {
      backgroundColor: "#8ecef1",
    },
  },
  grey: {
    color: "rgba(0,0,0,0.23)",
    backgroundColor: "rgba(0,0,0,0.12)",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.12)",
    },
  },
});

const VoteButton = ({ voteQueueData, ShowFunction }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <Button
          onClick={ShowFunction}
          variant="contained"
          disabled={!voteQueueData || !voteQueueData.id}
          className={classnames(classes.button, classes.blue)}
          fullWidth={true}
        >
          <div className="button-style" style={{ paddingTop: "5px" }}>
            <div className="button-wrapper">
              <HowToVoteIcon />
              {voteQueueData.id && <span className={voteQueueData && voteQueueData.id ? "online border" : "offline border"}/>}
            </div>
            <p style={{ margin: "0px" }}>Voting</p>
          </div>
        </Button>
      </Grid>
    </>
  );
};
export default VoteButton;
