import { Button, Snackbar, TextField, Typography } from "@material-ui/core";
import React, { useContext } from "react";

import { ConfigurationContext } from "../../infrastructure/ConfigurationContext";
import Documents from "../../components/Resolution/Documents";
import Grid from "@material-ui/core/Grid";
import ModeratorListener from "../../components/ModeratorQueue/ModeratorListener";
import PhoneOperator from "../../components/PhoneOperator";
import TextOperator from "../../components/TextOperator";
import { SessionConfigurationContext } from "../../infrastructure/SessionConfigurationContext";
import { UserContext } from "../../infrastructure/UserContext";
import VideoStream from "../../components/Resolution/VideoStream";
import WaitingRoomListener from "../Resolution/WaitingRoomListener";
import getConstants from "../../infrastructure/Constants";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";

const Home = () => {
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    const location = useLocation();
    const userData = useContext(UserContext);
    const sessionData = useContext(SessionConfigurationContext);
    const activeConfig = useContext(ConfigurationContext);

    let definedRole = "";
    let getRole = "";
    let sessionId = "";
    let isSession = false;
    let isUserAssignedThisSession = false;

    const get = function (obj, key) {
        return key.split(".").reduce(function (o, x) {
            return typeof o == "undefined" || o === null ? o : o[x];
        }, obj);
    };

    if (sessionData && sessionData.configData) {
        isSession = true;
        sessionId = sessionData.configData.id;
        isUserAssignedThisSession = userData.sessions.some(x => x.sessionId == sessionId);
        if (isUserAssignedThisSession) {
            getRole = userData.sessions[0].role;
        }
    } else {
        getRole = get(userData, "assignedRole");
    }
    

    if (getRole) {
        definedRole = getRole.toLowerCase();
    }

    if (!isAuthenticated) {
        return loginWithRedirect({
            redirectUri: getConstants().WebUrl,
            appState: location,
        });
    }

    if (isSession && isUserAssignedThisSession == false) {
        return (
            <div className="error-wrapper">
                <Typography color="error" align="center">
                    You are not permitted to attend this session, if this is an error, please contact the registration help desk at {activeConfig.configData.helpDeskEmail} or {activeConfig.configData.helpDeskPhone}.
                </Typography>{" "}
                <Button variant="contained" color="primary" href="/">
                    Return to homepage
                </Button>
            </div>);
    }
    else {
        return <WaitingRoomListener />;
    }
};


export default Home;
