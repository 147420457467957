import React, { useState, useContext, useEffect, useRef } from "react";
import { usePubNub } from "pubnub-react";
import { UserContext } from "../../infrastructure/UserContext";
import PubNubHelper from "../../infrastructure/PubNubHelper";
import BroadcastView from "./index";

const BroadcastListener = () => {
  const pubNub = usePubNub();
  const userData = useContext(UserContext);
  const [, render] = useState({});

  // History Hooks
  const queueLoaded = useRef(false);
  const currentSpeaker = useRef({});

  useEffect(() => {
    if (!userData) return;
    const pnListner = {
      message: (messageEvent) => {
        let message = messageEvent.message;
        // Check to see if this message was provided by History
        if (messageEvent.id) message = messageEvent;
        if (
          message &&
          message.messageType === "UpdateCallStatus" &&
          message.action === "Speaking"
        ) {
          currentSpeaker.current = message;
        }
        render({});
        render({});
      },
    };
    // Get Current Speaker
    if (!queueLoaded.current) {
      PubNubHelper.GetLastSpeaker(pubNub, userData).then((response) => {
        if (!response) return;
        pnListner.message(response);
        render({});
        render({});
      });
    }

    PubNubHelper.BroadcastListenerSubscribe(pubNub, userData, pnListner);

    return () => {
      PubNubHelper.BroadcastListenerUnsubscribe(pubNub, userData, pnListner);
    };
  }, [userData]);

  return <BroadcastView currentSpeaker={currentSpeaker.current} />;
};

export default BroadcastListener;
