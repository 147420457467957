import { useContext } from "react";
import { UserContext } from "./UserContext";
import PubNub from "pubnub";

export function GetPubNubClient() {
  const userData = useContext(UserContext);
  if (userData && userData.auth0Id) {
    return new PubNub({
      publishKey: userData.pubNubPublishKey,
      subscribeKey: userData.pubNubSubscribeKey,
      uuid: userData.auth0Id,
      restore: true,
      authKey: userData.pnAuthKey,
    });
  } else {
    return new PubNub({});
  }
}
